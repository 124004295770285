export const emeraldPools = [
  {
    id: 'valley-usdt-vs-pause',
    name: 'VS-evoUSDT LP',
    token: 'USDT-VS VSLP',
    tokenDescription: 'ValleySwap',
    tokenAddress: '0x9a91Bc3Ed7d0d6422C7599CcdD8C2200bfD4A8eA',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooValleyUSDT-VS',
    earnedTokenAddress: '0x92Ac3C4D9DACb5D0C1b580FaC5402a237A170f7b',
    earnContractAddress: '0x92Ac3C4D9DACb5D0C1b580FaC5402a237A170f7b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'valley-usdt-vs',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'ValleySwap',
    assets: ['VS', 'evoUSDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://dex.valleyswap.com/#/add/0x6Cb9750a92643382e020eA9a170AbB83Df05F30B/0xBC033203796CC2C8C543a5aAe93a9a643320433D',
    buyTokenUrl:
      'https://dex.valleyswap.com/#/swap?outputCurrency=0xBC033203796CC2C8C543a5aAe93a9a643320433D',
    createdAt: 1653356245,
  },
  {
    id: 'valley-usdt-usdc-pause',
    name: 'evoUSDC-evoUSDT LP',
    token: 'USDT-USDC VSLP',
    tokenDescription: 'ValleySwap',
    tokenAddress: '0x6755347199f6a2864936Cd467a1A2b03fBdeB9f9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooValleyUSDT-USDC',
    earnedTokenAddress: '0xa7D151e2bCd83750e2596e794248cc400F018a23',
    earnContractAddress: '0xa7D151e2bCd83750e2596e794248cc400F018a23',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'valley-usdt-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'ValleySwap',
    assets: ['evoUSDC', 'evoUSDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://dex.valleyswap.com/#/add/0x6Cb9750a92643382e020eA9a170AbB83Df05F30B/0x94fbfFe5698DB6f54d6Ca524DbE673a7729014Be',
    buyTokenUrl:
      'https://dex.valleyswap.com/#/swap?outputCurrency=0x94fbfFe5698DB6f54d6Ca524DbE673a7729014Be',
    createdAt: 1653001065,
  },
  {
    id: 'valley-btc-usdt-pause',
    name: 'evoBTC-evoUSDT LP',
    token: 'BTC-USDT VSLP',
    tokenDescription: 'ValleySwap',
    tokenAddress: '0x061b31c4af2E1338224CB46A15Fec86f509fcA00',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooValleyBTC-USDT',
    earnedTokenAddress: '0x74d6b1D419556d8A3E3038A9c8096DA0cA4beF24',
    earnContractAddress: '0x74d6b1D419556d8A3E3038A9c8096DA0cA4beF24',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'valley-btc-usdt',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'ValleySwap',
    assets: ['evoBTC', 'evoUSDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://dex.valleyswap.com/#/add/0x010CDf0Db2737f9407F8CFcb4dCaECA4dE54c815/0x6Cb9750a92643382e020eA9a170AbB83Df05F30B',
    buyTokenUrl:
      'https://dex.valleyswap.com/#/swap?outputCurrency=0x010CDf0Db2737f9407F8CFcb4dCaECA4dE54c815',
    createdAt: 1653071210,
  },
  {
    id: 'valley-usdt-eth-pause',
    name: 'evoETH-evoUSDT LP',
    token: 'USDT-ETH VSLP',
    tokenDescription: 'ValleySwap',
    tokenAddress: '0x9de37Ccf7d908D91d29931417374cC76bAc72e73',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooValleyUSDT-ETH',
    earnedTokenAddress: '0x3cf4fe3f469b0710E8a42e44B64c85DFc300f22A',
    earnContractAddress: '0x3cf4fe3f469b0710E8a42e44B64c85DFc300f22A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'valley-usdt-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'ValleySwap',
    assets: ['evoETH', 'evoUSDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://dex.valleyswap.com/#/add/0x6Cb9750a92643382e020eA9a170AbB83Df05F30B/0xE9b38eD157429483EbF87Cf6C002cecA5fd66783',
    buyTokenUrl:
      'https://dex.valleyswap.com/#/swap?outputCurrency=0xE9b38eD157429483EbF87Cf6C002cecA5fd66783',
    createdAt: 1653007121,
  },
  {
    id: 'yuzu-ceusdc-usdt',
    name: 'ceUSDC-USDT LP',
    token: 'ceUSDC-USDT LP',
    tokenDescription: 'YuzuSwap',
    tokenAddress: '0x49c56e261744BB7e39359D42E139812Cb5A259c3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooYuzuceUSDC-USDT',
    earnedTokenAddress: '0xb42441990ffb06f155Bb5b52577Fb137Bcb1eb5F',
    earnContractAddress: '0xb42441990ffb06f155Bb5b52577Fb137Bcb1eb5F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'yuzu-ceusdc-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'YuzuSwap',
    assets: ['ceUSDC', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.yuzu-swap.com/#/add/0x81ECac0D6Be0550A00FF064a4f9dd2400585FE9c/0xdC19A122e268128B5eE20366299fc7b5b199C8e3',
    buyTokenUrl: 'https://app.yuzu-swap.com/#/swap',
    createdAt: 1653320563,
  },
  {
    id: 'yuzu-ceusdc-yuzu',
    name: 'ceUSDC-YUZU LP',
    token: 'ceUSDC-YUZU LP',
    tokenDescription: 'YuzuSwap',
    tokenAddress: '0x63217aC1600Fb337a3B7A70533dc71870E6b68cc',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooYuzuceUSDC-YUZU',
    earnedTokenAddress: '0xD5824cED886cA03B8914589146161F2474B75a24',
    earnContractAddress: '0xD5824cED886cA03B8914589146161F2474B75a24',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'yuzu-ceusdc-yuzu',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'YuzuSwap',
    assets: ['ceUSDC', 'YUZU'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.yuzu-swap.com/#/add/0x81ECac0D6Be0550A00FF064a4f9dd2400585FE9c/0xf02b3e437304892105992512539F769423a515Cb',
    buyTokenUrl: 'https://app.yuzu-swap.com/#/swap',
    createdAt: 1652382925,
  },
  {
    id: 'yuzu-wrose-weth',
    name: 'WETH-ROSE LP',
    token: 'WETH-ROSE LP',
    tokenDescription: 'YuzuSwap',
    tokenAddress: '0x28c9D3e689B5d3629aFC2D69ef6a2799578574e0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooYuzuROSE-WETH',
    earnedTokenAddress: '0x80514CfB7cb438F1f997Ab62fFD1314c1a1c0f12',
    earnContractAddress: '0x80514CfB7cb438F1f997Ab62fFD1314c1a1c0f12',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'yuzu-wrose-weth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'YuzuSwap',
    assets: ['WETH', 'ROSE'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.yuzu-swap.com/#/add/0x3223f17957Ba502cbe71401D55A0DB26E5F7c68F/0x21C718C22D52d0F3a789b752D4c2fD5908a8A733',
    buyTokenUrl: 'https://app.yuzu-swap.com/#/swap',
    createdAt: 1652382924,
  },
  {
    id: 'yuzu-usdt-yuzu',
    name: 'USDT-YUZU LP',
    token: 'USDT-YUZU LP',
    tokenDescription: 'YuzuSwap',
    tokenAddress: '0xD2076c230FE52572344Dd7394fb50d8280c390D1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooYuzuUSDT-YUZU',
    earnedTokenAddress: '0x685aaba90132C70220765Ac8c729EE7D935d7810',
    earnContractAddress: '0x685aaba90132C70220765Ac8c729EE7D935d7810',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'yuzu-usdt-yuzu',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'YuzuSwap',
    assets: ['USDT', 'YUZU'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.yuzu-swap.com/#/add/0xdC19A122e268128B5eE20366299fc7b5b199C8e3/0xf02b3e437304892105992512539F769423a515Cb',
    buyTokenUrl: 'https://app.yuzu-swap.com/#/swap',
    createdAt: 1652382923,
  },
  {
    id: 'yuzu-wrose-yuzu',
    name: 'YUZU-ROSE LP',
    token: 'YUZU-ROSE LP',
    tokenDescription: 'YuzuSwap',
    tokenAddress: '0x941494A56164eA04d79f9867ddDB0Dd754A625cC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooYuzuYUZU-ROSE',
    earnedTokenAddress: '0xcD5B6833d4A30c43F1c30bDC4D8DC5c7aCB50972',
    earnContractAddress: '0xcD5B6833d4A30c43F1c30bDC4D8DC5c7aCB50972',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'yuzu-wrose-yuzu',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'YuzuSwap',
    assets: ['YUZU', 'ROSE'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.yuzu-swap.com/#/add/0x21C718C22D52d0F3a789b752D4c2fD5908a8A733/0xf02b3e437304892105992512539F769423a515Cb',
    buyTokenUrl: 'https://app.yuzu-swap.com/#/swap',
    createdAt: 1652382922,
  },
  {
    id: 'yuzu-wrose-usdt',
    name: 'USDT-ROSE LP',
    token: 'USDT-ROSE LP',
    tokenDescription: 'YuzuSwap',
    tokenAddress: '0xfb9BD52Abe613A5C4A20e9fC09462A2EC5F2d1B2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooYuzuUSDT-ROSE',
    earnedTokenAddress: '0x9712b6aff7d2dB96097565EB8b2183b75e839130',
    earnContractAddress: '0x9712b6aff7d2dB96097565EB8b2183b75e839130',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'yuzu-wrose-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'YuzuSwap',
    assets: ['USDT', 'ROSE'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.yuzu-swap.com/#/add/0x21C718C22D52d0F3a789b752D4c2fD5908a8A733/0xdC19A122e268128B5eE20366299fc7b5b199C8e3',
    buyTokenUrl: 'https://app.yuzu-swap.com/#/swap',
    createdAt: 1652382921,
  },
];
