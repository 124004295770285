export const avalanchePools = [
  {
    id: 'avax-bifi-maxi',
    logo: 'single-assets/BIFI.png',
    name: 'BIFI Maxi',
    token: 'BIFI',
    tokenDescription: 'Beefy',
    tokenAddress: '0xd6070ae98b8069de6B494332d1A1a81B6179D960',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAvalancheBIFI',
    earnedTokenAddress: '0xCeefB07Ad37ff165A0b03DC7C808fD2E2fC77683',
    earnContractAddress: '0xCeefB07Ad37ff165A0b03DC7C808fD2E2fC77683',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BIFI',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beefy',
    assets: ['BIFI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0.05%',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0xd6070ae98b8069de6b494332d1a1a81b6179d960',
    createdAt: 1629307105,
  },

  {
    id: 'joe-tus-wavax-eol',
    name: 'TUS-AVAX LP',
    token: 'TUS-AVAX JLP',
    tokenDescription: 'TraderJoe',
    tokenAddress: '0x565d20BD591b00EAD0C927e4b6D7DD8A33b0B319',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeTUS-AVAX',
    earnedTokenAddress: '0x60c48584CfAe8e7D5263f2433FFD994e4A8D7C28',
    earnContractAddress: '0x60c48584CfAe8e7D5263f2433FFD994e4A8D7C28',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-tus-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Trader Joe',
    assets: ['TUS', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/farm/0x565d20BD591b00EAD0C927e4b6D7DD8A33b0B319-0x188bED1968b795d5c9022F6a0bb5931Ac4c18F00#/',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?inputCurrency=AVAX&outputCurrency=0xf693248f96fe03422fea95ac0afbbbc4a8fdd172',
    createdAt: 1653320562,
  },

  {
    id: 'png-klo-wavax',
    name: 'KLO-AVAX LP',
    token: 'KLO-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x6745d7F9289d7d75B5121876B1b9D8DA775c9a3E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinKLO-AVAX',
    earnedTokenAddress: '0x9C9C14f28F07eDe4d796aED2D7038EF6F23494A8',
    earnContractAddress: '0x9C9C14f28F07eDe4d796aED2D7038EF6F23494A8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-klo-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['KLO', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xb27c8941a7Df8958A1778c0259f76D1F8B711C35',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?outputCurrency=0xb27c8941a7Df8958A1778c0259f76D1F8B711C35',
    createdAt: 1653320561,
  },

  {
    id: 'png-fitfi-wavax',
    name: 'FITFI-AVAX LP',
    token: 'FITFI-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x1bB665942381FB2E67c4dCCb04E6CfE7f130E93C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinFITFI-AVAX',
    earnedTokenAddress: '0x2b1b82D0D3F79D0Ff0300263F215Ed2dCE0734D2',
    earnContractAddress: '0x2b1b82D0D3F79D0Ff0300263F215Ed2dCE0734D2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-fitfi-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['FITFI', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0x714f020C54cc9D104B6F4f6998C63ce2a31D1888',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?outputCurrency=0x714f020C54cc9D104B6F4f6998C63ce2a31D1888',
    createdAt: 1651942440,
  },
  {
    id: 'mai-avax-mai-crv',
    logo: 'uncategorized/MAI-av3CRV.svg',
    name: 'MAI/DAI/USDC/USDT',
    token: 'MAI-av3CRV',
    tokenDescription: 'Curve (Mai)',
    tokenAddress: '0xb0D2EB3C2cA3c6916FAb8DCbf9d9c165649231AE',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMaiMAI-av3CRV',
    earnedTokenAddress: '0xFeA7976733f47557860f4483f2147a3e99C76b58',
    earnContractAddress: '0xFeA7976733f47557860f4483f2147a3e99C76b58',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-avax-mai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Other',
    assets: ['MAI', 'DAIe', 'USDCe', 'USDTe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratMultiLP',
    depositFee: '0.5%',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://avax.curve.fi/factory/7/deposit',
    createdAt: 1651836389,
  },
  {
    id: 'mai-qi-wavax',
    name: 'QI-AVAX LP',
    token: 'QI-WAVAX JLP',
    tokenDescription: 'Trader Joe (Mai)',
    tokenAddress: '0x05366F13d5f655FFB2d2ABb7b2ba005b12eB8bdE',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMaiQI-AVAX',
    earnedTokenAddress: '0x0D48E4E02eA44003b44360B9045F5A562890B1c6',
    earnContractAddress: '0x0D48E4E02eA44003b44360B9045F5A562890B1c6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mai-qi-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Other',
    depositFee: '0.5%',
    withdrawalFee: '0%',
    assets: ['QI', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/AVAX/0xA56F9A54880afBc30CF29bB66d2D9ADCdcaEaDD6',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0xA56F9A54880afBc30CF29bB66d2D9ADCdcaEaDD6',
    createdAt: 1651836388,
  },
  {
    id: 'aavev3-avax',
    logo: 'single-assets/AVAX.svg',
    name: 'AVAX',
    token: 'AVAX',
    tokenDescription: 'Aave',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveAVAX',
    earnedTokenAddress: '0x1B156C5c75E9dF4CAAb2a5cc5999aC58ff4F9090',
    earnContractAddress: '0x1B156C5c75E9dF4CAAb2a5cc5999aC58ff4F9090',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'AVAX',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Aave',
    assets: ['AVAX'],
    withdrawalFee: '0.01%',
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    createdAt: 1651836387,
  },
  {
    id: 'aavev3-dai.e',
    logo: 'single-assets/DAI.svg',
    name: 'DAI.e',
    token: 'DAI.e',
    tokenDescription: 'Aave',
    tokenAddress: '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveDAI.e',
    earnedTokenAddress: '0xAf9f33df60CA764307B17E62dde86e9F7090426c',
    earnContractAddress: '0xAf9f33df60CA764307B17E62dde86e9F7090426c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'DAI.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Aave',
    assets: ['DAIe'],
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
    createdAt: 1651836386,
  },
  {
    id: 'aavev3-usdc',
    logo: 'single-assets/USDC.svg',
    name: 'USDC',
    token: 'USDC',
    tokenDescription: 'Aave',
    tokenAddress: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
    tokenDecimals: 6,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveUSDC',
    earnedTokenAddress: '0x3C2131A02659343d68c211aAa686750bE37d88c4',
    earnContractAddress: '0x3C2131A02659343d68c211aAa686750bE37d88c4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDC',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Aave',
    assets: ['USDC'],
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
    createdAt: 1651836385,
  },
  {
    id: 'aavev3-usdt',
    logo: 'single-assets/USDT.svg',
    name: 'USDT',
    token: 'USDT',
    tokenDescription: 'Aave',
    tokenAddress: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
    tokenDecimals: 6,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveUSDT',
    earnedTokenAddress: '0x813aB5B3FDc59B143C42284665864b24B794C324',
    earnContractAddress: '0x813aB5B3FDc59B143C42284665864b24B794C324',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDT',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Aave',
    assets: ['USDT'],
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
    createdAt: 1651836384,
  },
  {
    id: 'aavev3-weth.e',
    logo: 'single-assets/ETH.svg',
    name: 'WETH.e',
    token: 'WETH.e',
    tokenDescription: 'Aave',
    tokenAddress: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveWETH.e',
    earnedTokenAddress: '0xDc5e537764F5Ad0f51Bf52CCF0767083bb4565EC',
    earnContractAddress: '0xDc5e537764F5Ad0f51Bf52CCF0767083bb4565EC',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WETH.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Aave',
    assets: ['WETHe'],
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
    createdAt: 1651836383,
  },
  {
    id: 'aavev3-wbtc.e',
    logo: 'single-assets/WBTC.svg',
    name: 'WBTC.e',
    token: 'WBTC.e',
    tokenDescription: 'Aave',
    tokenAddress: '0x50b7545627a5162F82A992c33b87aDc75187B218',
    tokenDecimals: 8,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveWBTC.e',
    earnedTokenAddress: '0xD0e4b0B711A5296a8D72EB8B2a9Cb0F3f7A4211d',
    earnContractAddress: '0xD0e4b0B711A5296a8D72EB8B2a9Cb0F3f7A4211d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WBTC.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Aave',
    assets: ['WBTCe'],
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0x50b7545627a5162F82A992c33b87aDc75187B218',
    createdAt: 1651836382,
  },
  {
    id: 'joe-dby-wavax-eol',
    name: 'DBY-AVAX LP',
    token: 'DBY-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x30556AcC64b7b8F37ae4CE8325dd279847F09926',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeAVAX-DBY',
    earnedTokenAddress: '0x3cD0bCffF160fF331a170832219E82355189528f',
    earnContractAddress: '0x3cD0bCffF160fF331a170832219E82355189528f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-dby-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Trader Joe',
    assets: ['DBY', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0x5085434227aB73151fAd2DE546210Cbc8663dF96/AVAX',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0x5085434227aB73151fAd2DE546210Cbc8663dF96',
    createdAt: 1651052876,
  },
  {
    id: 'joe-bpt-wavax-eol',
    name: 'BPT-AVAX LP',
    token: 'BPT-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x0b44F6F5a32De1b36CfCD5F68f181F6787fb3225',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeAVAX-BPT',
    earnedTokenAddress: '0x93AADd13324FD724afe13E9930f5A466A4590853',
    earnContractAddress: '0x93AADd13324FD724afe13E9930f5A466A4590853',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-bpt-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Trader Joe',
    assets: ['BPT', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0x1111111111182587795ef1098ac7da81a108c97a/AVAX',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0x1111111111182587795ef1098ac7da81a108c97a#/',
    createdAt: 1650788892,
  },
  {
    id: 'png-axlatom-wavax',
    name: 'ATOM-AVAX LP',
    token: 'ATOM-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x8a998BCd8D04e36cF5D28984F9979B6404471E51',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinAxlATOM-AVAX',
    earnedTokenAddress: '0x3Adf681a9E050AecE8e12ad825eB64273552B29d',
    earnContractAddress: '0x3Adf681a9E050AecE8e12ad825eB64273552B29d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-axlatom-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['axlATOM', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/0x80D18b1c9Ab0c9B5D6A6d5173575417457d00a12/0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?outputCurrency=0x80D18b1c9Ab0c9B5D6A6d5173575417457d00a12',
    createdAt: 1650124579,
  },
  {
    id: 'png-png-ustw',
    name: 'PNG-USTw LP',
    token: 'PNG-USTw LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x2a0f65C76008EcBC469b0850454E57310E770557',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MooPangolinPNG-USTw',
    earnedTokenAddress: '0xC43e5065c28Dcf85f9a5D31feAC658c6EB7F23D6',
    earnContractAddress: '0xC43e5065c28Dcf85f9a5D31feAC658c6EB7F23D6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-png-ustw',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['PNG', 'USTw'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
      'ALGO_STABLE',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/0x60781C2586D68229fde47564546784ab3fACA982/0xb599c3590F42f8F995ECfa0f85D2980B76862fc1',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0x60781C2586D68229fde47564546784ab3fACA982&outputCurrency=0xb599c3590F42f8F995ECfa0f85D2980B76862fc1',
    createdAt: 1650107726,
  },
  {
    id: 'joe-yeti-wavax',
    name: 'YETI-AVAX LP',
    token: 'YETI-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xbdc7EF37283BC67D50886c4afb64877E3e83f869',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeYETI-AVAX',
    earnedTokenAddress: '0xDb054F4978a485bC5D9ae144B487340B141E41db',
    earnContractAddress: '0xDb054F4978a485bC5D9ae144B487340B141E41db',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-yeti-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['YETI', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0x77777777777d4554c39223C354A05825b2E8Faa3/AVAX',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0x77777777777d4554c39223C354A05825b2E8Faa3#/',
    createdAt: 1650288303,
  },
  {
    id: 'joe-usdt-wavax',
    name: 'USDT-AVAX LP',
    token: 'USDT-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xbb4646a764358ee93c2a9c4a147d5aDEd527ab73',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeUSDt-AVAX',
    earnedTokenAddress: '0xe7f4AB519b20A0F4dF71B9ad9147b9cd4f183349',
    earnContractAddress: '0xe7f4AB519b20A0F4dF71B9ad9147b9cd4f183349',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-usdt-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['USDT', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7/AVAX',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7#/',
    createdAt: 1649966386,
  },
  {
    id: 'curve-avax-f-3pool',
    name: 'USTw/USDC/USDT',
    token: '3pool-f',
    tokenDescription: 'Curve',
    tokenAddress: '0xD79138c49c49200a1Afc935171D1bDAd084FDc95',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCurveF3pool',
    earnedTokenAddress: '0x7C5bBE72EF32D8eA8054D056Fb049482894bbEEc',
    earnContractAddress: '0x7C5bBE72EF32D8eA8054D056Fb049482894bbEEc',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-avax-f-3pool',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['USTw', 'USDT', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'ALGO_STABLE',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://avax.curve.fi/factory/55/deposit',
    createdAt: 1649925210,
  },

  {
    id: 'beefy-beJoe',
    logo: 'single-assets/beJOE.png',
    name: 'beJOE',
    token: 'beJOE',
    tokenDescription: 'Beefy',
    tokenAddress: '0x1F2A8034f444dc55F963fb5925A9b6eb744EeE2c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'moobeJOE',
    earnedTokenAddress: '0xb300d08c9420faeEB23102Ea82787a95F4eD869E',
    earnContractAddress: '0xb300d08c9420faeEB23102Ea82787a95F4eD869E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'beJOE',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beefy',
    assets: ['beJOE'],
    withdrawalFee: '0.1%',
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratSingle',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd',
    createdAt: 1649422919,
  },
  {
    id: 'joe-ustw-wavax-eol',
    name: 'USTw-AVAX LP',
    token: 'USTw-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xeCD6D33555183Bc82264dbC8bebd77A1f02e421E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeAVAX-UST',
    earnedTokenAddress: '0xE113C53D53f66A33cD1098263DE18bCe8Ed92DCE',
    earnContractAddress: '0xE113C53D53f66A33cD1098263DE18bCe8Ed92DCE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-ustw-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Trader Joe',
    assets: ['USTw', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
      'ALGO_STABLE',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0xb599c3590F42f8F995ECfa0f85D2980B76862fc1/AVAX',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0xb599c3590F42f8F995ECfa0f85D2980B76862fc1#/',
    createdAt: 1649323779,
  },
  {
    id: 'joe-lost-wavax',
    name: 'LOST-AVAX LP',
    token: 'LOST-AVAX LP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x9C396cF96319C8EF2b662Af57DEA6EE374b9959F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeAVAX-LOST',
    earnedTokenAddress: '0xB38cA58F325D293B143364591c00704c91DEcdaC',
    earnContractAddress: '0xB38cA58F325D293B143364591c00704c91DEcdaC',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-lost-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['LOST', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0x449674B82F05d498E126Dd6615a1057A9c088f2C/AVAX',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0x449674B82F05d498E126Dd6615a1057A9c088f2C#/',
    createdAt: 1649323990,
  },
  {
    id: 'png-wavax-lost',
    name: 'LOST-AVAX LP',
    token: 'LOST-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x8461681211B49c15e20B3Cfd4c63BE258878B7D9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinAVAX-LOST',
    earnedTokenAddress: '0x02BE142346cbCAf2A1d8A8F7B4a5F2d929259270',
    earnContractAddress: '0x02BE142346cbCAf2A1d8A8F7B4a5F2d929259270',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-wavax-lost',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['LOST', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0x449674B82F05d498E126Dd6615a1057A9c088f2C',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?outputCurrency=0x449674B82F05d498E126Dd6615a1057A9c088f2C',
    createdAt: 1651186444,
  },
  {
    id: 'curve-avax-f-avaxl',
    name: 'AVAXL/AVAX',
    token: 'AVAXL/AVAX',
    tokenDescription: 'Curve',
    tokenAddress: '0x445109BcF194c538B73EA60C71bb773849bc9b79',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCurveAVAXL',
    earnedTokenAddress: '0x9F4776F629E21A7A2EF12761846a7a98f64aFf61',
    earnContractAddress: '0x9F4776F629E21A7A2EF12761846a7a98f64aFf61',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-avax-f-avaxl',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['AVAXL', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://avax.curve.fi/factory/33/deposit',
    buyTokenUrl: 'https://app.topshelf.finance/#/mint',
    createdAt: 1649341331,
  },

  {
    id: 'joe-money-wavax',
    name: 'MONEY-AVAX LP',
    token: 'MONEY-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x66D12e1cb13EAbAB21f1Fb6628B1Ef33C6dED5a7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeMONEY-AVAX',
    earnedTokenAddress: '0xC040E6221B7FE844681c733aDCf1c19F9984272A',
    earnContractAddress: '0xC040E6221B7FE844681c733aDCf1c19F9984272A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-money-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['MONEY', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0x0f577433Bf59560Ef2a79c124E9Ff99fCa258948/AVAX#/',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0x0f577433Bf59560Ef2a79c124E9Ff99fCa258948',
    createdAt: 1648847501,
  },
  {
    id: 'png-ustw-usdc',
    name: 'USTw-USDC LP',
    token: 'USTw-USDC LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xE1f75E2E74BA938abD6C3BE18CCc5C7f71925C4B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinUSTw-USDC',
    earnedTokenAddress: '0x02A0c97BE6D8f4F144AFE5f41C51b5cef192480C',
    earnContractAddress: '0x02A0c97BE6D8f4F144AFE5f41C51b5cef192480C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-ustw-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['USTw', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
      'ALGO_STABLE',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/0xb599c3590F42f8F995ECfa0f85D2980B76862fc1/0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E&outputCurrency=0xb599c3590F42f8F995ECfa0f85D2980B76862fc1',
    createdAt: 1648788544,
  },
  {
    id: 'png-wavax-ustw',
    name: 'USTw-AVAX LP',
    token: 'USTw-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x9D563afF8B0017868DbA57eB3E04298C157d0aF5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinUSTw-AVAX',
    earnedTokenAddress: '0xe53a812EA0A03C2323428A04f5E8006e81083E5a',
    earnContractAddress: '0xe53a812EA0A03C2323428A04f5E8006e81083E5a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-wavax-ustw',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['USTw', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
      'ALGO_STABLE',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7/0xb599c3590F42f8F995ECfa0f85D2980B76862fc1',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?outputCurrency=0xb599c3590F42f8F995ECfa0f85D2980B76862fc1',
    createdAt: 1648788292,
  },

  {
    id: 'stargate-avax-usdc',
    logo: 'single-assets/USDC.svg',
    name: 'USDC LP',
    token: 'S*USDC',
    tokenDescription: 'Stargate',
    tokenAddress: '0x1205f31718499dBf1fCa446663B532Ef87481fe1',
    tokenDecimals: 6,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStargateUSDC',
    earnedTokenAddress: '0x79F26046D122d2F26B1D37237D5C056fB580447A',
    earnContractAddress: '0x79F26046D122d2F26B1D37237D5C056fB580447A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'saUSDC',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stargate',
    assets: ['saUSDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    addLiquidityUrl: 'https://stargate.finance/pool/USDC-AVAX/add',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E#/',
    createdAt: 1648729105,
  },
  {
    id: 'stargate-avax-usdt',
    logo: 'single-assets/USDT.svg',
    name: 'USDT LP',
    token: 'S*USDT',
    tokenDescription: 'Stargate',
    tokenAddress: '0x29e38769f23701A2e4A8Ef0492e19dA4604Be62c',
    tokenDecimals: 6,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStargateUSDT',
    earnedTokenAddress: '0xA27BF4d56870A37e37a687B8ab3740d8487Ee2C6',
    earnContractAddress: '0xA27BF4d56870A37e37a687B8ab3740d8487Ee2C6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'saUSDT',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stargate',
    assets: ['saUSDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    addLiquidityUrl: 'https://stargate.finance/pool/USDT-AVAX/add',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7#/',
    createdAt: 1648730342,
  },
  {
    id: 'png-wavax-ncash',
    name: 'NCASH-AVAX LP',
    token: 'nCASH-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xb8F66A8B3A1640492e3d45A26256C8ef4b934fe6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinNCASH-AVAX',
    earnedTokenAddress: '0x326d4521e4Cf78Fb2f8693b1ebc4D24ecE89de01',
    earnContractAddress: '0x326d4521e4Cf78Fb2f8693b1ebc4D24ecE89de01',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-wavax-ncash',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['NCASH', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xc69eba65e87889f0805db717af06797055a0ba07',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?outputCurrency=0xc69eba65e87889f0805db717af06797055a0ba07',
    createdAt: 1648734327,
  },
  {
    id: 'curve-avax-atricrypto',
    logo: 'uncategorized/ATRICRYPTO.png',
    name: 'aTriCrypto',
    token: 'crvUSDBTCETH',
    tokenDescription: 'Curve',
    tokenAddress: '0x1daB6560494B04473A0BE3E7D83CF3Fdf3a51828',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCurveTriCrypto',
    earnedTokenAddress: '0xe1a8EeA58D63Ea64d00365531D266C2AD1f62FC4',
    earnContractAddress: '0xe1a8EeA58D63Ea64d00365531D266C2AD1f62FC4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-avax-atricrypto',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['DAIe', 'USDCe', 'USDTe', 'WBTCe', 'WETHe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://avax.curve.fi/atricrypto/deposit',
    createdAt: 1652662923,
  },
  {
    id: 'joe-ape-wavax',
    name: 'APE-AVAX LP',
    token: 'APE-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x11bBfA2Fa3b995ceA99D20DFA618fd32e252d8F2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeAVAX-APE',
    earnedTokenAddress: '0x9C3D6E7956A7eF2ECF5987cF65ac5a5CEa87C889',
    earnContractAddress: '0x9C3D6E7956A7eF2ECF5987cF65ac5a5CEa87C889',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-ape-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['APE', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0x0802d66f029c46E042b74d543fC43B6705ccb4ba/AVAX',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0x0802d66f029c46E042b74d543fC43B6705ccb4ba',
    createdAt: 1648112467,
  },
  {
    id: 'joe-fly-wavax',
    name: 'FLY-AVAX LP',
    token: 'FLY-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x1371a6d8CBe2Ca66d0911f270e1cAA7C12A3045A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeAVAX-FLY',
    earnedTokenAddress: '0xba8284Dc76fbEDd311f14937340E3bc174b4864c',
    earnContractAddress: '0xba8284Dc76fbEDd311f14937340E3bc174b4864c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-fly-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['FLY', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0x78Ea3fef1c1f07348199Bf44f45b803b9B0Dbe28/AVAX',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0x78Ea3fef1c1f07348199Bf44f45b803b9B0Dbe28',
    createdAt: 1648112061,
  },
  {
    id: 'joe-deg-wavax',
    name: 'DEG-AVAX LP',
    token: 'DEG-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x465460F46969F2bf969432956491dEE95A6ba493',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeDEG-AVAX',
    earnedTokenAddress: '0xB1298261e5C5219B93181f8EA45dD5D50A8e2E56',
    earnContractAddress: '0xB1298261e5C5219B93181f8EA45dD5D50A8e2E56',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-deg-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['DEG', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0x9f285507Ea5B4F33822CA7aBb5EC8953ce37A645/AVAX#/',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0x9f285507Ea5B4F33822CA7aBb5EC8953ce37A645',
    createdAt: 1647256112,
  },
  {
    id: 'joe-joe',
    logo: 'single-assets/JOE.png',
    name: 'JOE',
    token: 'JOE',
    tokenDescription: 'Trader Joe (sJoe)',
    tokenAddress: '0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoe',
    earnedTokenAddress: '0x282B11E65f0B49363D4505F91c7A44fBEe6bCc0b',
    earnContractAddress: '0x282B11E65f0B49363D4505F91c7A44fBEe6bCc0b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'JOE',
    oraclePrice: 0,
    depositsPaused: false,
    withdrawalFee: '0.0%',
    status: 'active',
    platform: 'Trader Joe',
    assets: ['JOE'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    depositFee: '1%',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd',
    createdAt: 1631011023,
  },
  {
    id: 'joe-wavax-usdc',
    name: 'USDC-AVAX LP',
    token: 'USDC-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xf4003F4efBE8691B60249E6afbD307aBE7758adb',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeUSDC-AVAX',
    earnedTokenAddress: '0x7E5bC7088aB3Da3e7fa1Aa7ceF1dC73F5B00681c',
    earnContractAddress: '0x7E5bC7088aB3Da3e7fa1Aa7ceF1dC73F5B00681c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['USDC', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/AVAX/0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
    createdAt: 1646426309,
  },
  {
    id: 'joe-wavax-vtx',
    name: 'VTX-AVAX LP',
    token: 'VTX-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x9EF0C12b787F90F59cBBE0b611B82D30CAB92929',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeVTX-AVAX',
    earnedTokenAddress: '0x9710755f5E6Ea9bd149800ec1901982b93beEf25',
    earnContractAddress: '0x9710755f5E6Ea9bd149800ec1901982b93beEf25',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-vtx',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['VTX', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/AVAX/0x5817D4F0b62A59b17f75207DA1848C2cE75e7AF4',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0x5817D4F0b62A59b17f75207DA1848C2cE75e7AF4',
    createdAt: 1646470439,
  },
  {
    id: 'joe-wavax-ecd',
    name: 'ECD-AVAX LP',
    token: 'ECD-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x218e6A0AD170460F93eA784FbcC92B57DF13316E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeAVAX-ECD',
    earnedTokenAddress: '0x4C7cF6eE7AFa810028099C2D1d97c6AB33540412',
    earnContractAddress: '0x4C7cF6eE7AFa810028099C2D1d97c6AB33540412',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-ecd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['ECD', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/AVAX/0xeb8343D5284CaEc921F035207ca94DB6BAaaCBcd',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0xeb8343D5284CaEc921F035207ca94DB6BAaaCBcd',
    createdAt: 1646470439,
  },
  {
    id: 'joe-egg-wavax-eol',
    name: 'EGG-AVAX JLP',
    token: 'EGG-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x3052a75dfD7A9D9B0F81E510E01d3Fe80A9e7ec7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeEGG-AVAX',
    earnedTokenAddress: '0x491E09Eb736c0c4CD85DF694A22D1C0dec8c4C25',
    earnContractAddress: '0x491E09Eb736c0c4CD85DF694A22D1C0dec8c4C25',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-egg-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Trader Joe',
    assets: ['EGG', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0x7761E2338B35bCEB6BdA6ce477EF012bde7aE611/AVAX#/',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0x7761e2338b35bceb6bda6ce477ef012bde7ae611',
    createdAt: 1646787205,
  },
  {
    id: 'png-usdc.e-usdc',
    name: 'USDC-USDC.e LP',
    token: 'USDC-USDC.e LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x8a9c36BC3CEd5ECce703A4dA8032218Dfe72fE86',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinUSDC.e-USDC',
    earnedTokenAddress: '0x99C719c26C64A371be84bAF0821fA89a1FEd459a',
    earnContractAddress: '0x99C719c26C64A371be84bAF0821fA89a1FEd459a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-usdc.e-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['USDC', 'USDCe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664/0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664&outputCurrency=0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
    createdAt: 1646973680,
  },
  {
    id: 'png-wavax-spell',
    name: 'SPELL-AVAX LP',
    token: 'SPELL-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xD4CBC976E1a1A2bf6F4FeA86DEB3308d68638211',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinSPELL-WAVAX',
    earnedTokenAddress: '0x87a1525566A367F2a986ca15A09D8cA50f539C03',
    earnContractAddress: '0x87a1525566A367F2a986ca15A09D8cA50f539C03',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-wavax-spell',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['SPELL', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xCE1bFFBD5374Dac86a2893119683F4911a2F7814',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?outputCurrency=0xCE1bFFBD5374Dac86a2893119683F4911a2F7814',
    createdAt: 1646976330,
  },
  {
    id: 'png-wavax-usdc',
    name: 'USDC-AVAX LP',
    token: 'USDC-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x0e0100Ab771E9288e0Aa97e11557E6654C3a9665',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinUSDC-WAVAX',
    earnedTokenAddress: '0xf3340EdF16563D52C7E7C576F2fCC8f3D52464aB',
    earnContractAddress: '0xf3340EdF16563D52C7E7C576F2fCC8f3D52464aB',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-wavax-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['USDC', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?outputCurrency=0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
    createdAt: 1647003159,
  },
  {
    id: 'png-wavax-ime',
    name: 'IME-AVAX LP',
    token: 'IME-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xB6eE4A02F1fa523559B9Abb54C50dF3011911fe9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinIME-WAVAX',
    earnedTokenAddress: '0xcDD3542A7A741409d5c6F8aD487ebf39964992ED',
    earnContractAddress: '0xcDD3542A7A741409d5c6F8aD487ebf39964992ED',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-wavax-ime',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['IME', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xF891214fdcF9cDaa5fdC42369eE4F27F226AdaD6',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?outputCurrency=0xF891214fdcF9cDaa5fdC42369eE4F27F226AdaD6',
    createdAt: 1647002201,
  },
  {
    id: 'joe-joe-usdc',
    name: 'JOE-USDC LP',
    token: 'JOE-USDC LP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x3bc40d4307cD946157447CD55d70ee7495bA6140',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeJOE-USDC',
    earnedTokenAddress: '0x909C9EF8E634C2bc6719d960146Bb38F20f6a547',
    earnContractAddress: '0x909C9EF8E634C2bc6719d960146Bb38F20f6a547',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-joe-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['JOE', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd/0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?inputCurrency=0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e&outputCurrency=0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd',
    createdAt: 1646825243,
  },
  {
    id: 'joe-wavax-fief-eol',
    name: 'FIEF-AVAX LP',
    token: 'FIEF-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x939D6eD8a0f7FC90436BA6842D7372250a03fA7c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeAVAX-FIEF',
    earnedTokenAddress: '0x87267285Bd7990B05950703f7bA6b24dF88aa302',
    earnContractAddress: '0x87267285Bd7990B05950703f7bA6b24dF88aa302',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-fief',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Trader Joe',
    assets: ['FIEF', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0xeA068Fba19CE95f12d252aD8Cb2939225C4Ea02D/AVAX',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0xeA068Fba19CE95f12d252aD8Cb2939225C4Ea02D',
    createdAt: 1646148122,
  },
  {
    id: 'ripae-pavax-wavax',
    name: 'pAVAX-AVAX LP',
    token: 'pAVAX-AVAX LP',
    tokenDescription: 'TraderJoe (Ripae)',
    tokenAddress: '0x1179E6AF2794fA9d39316951e868772F96230375',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooRipaepAVAX-AVAX',
    earnedTokenAddress: '0xD78d5464690544F5D838dAEF0D7650fA03c64598',
    earnContractAddress: '0xD78d5464690544F5D838dAEF0D7650fA03c64598',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ripae-pavax-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Ripae',
    assets: ['pAVAX', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/AVAX/0x6ca558bd3eaB53DA1B25aB97916dd14bf6CFEe4E',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0x6ca558bd3eaB53DA1B25aB97916dd14bf6CFEe4E',
    createdAt: 1646045101,
  },
  {
    id: 'ripae-pae-wavax',
    name: 'PAE-AVAX LP',
    token: 'PAE-AVAX LP',
    tokenDescription: 'TraderJoe (Ripae)',
    tokenAddress: '0x6139361Ccd4f40abF3d5D22AA3b72A195010F9AB',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooRipaePAE-AVAX',
    earnedTokenAddress: '0x2c0E2Ec5C2C84346497cc82F5afF72f8A29dA835',
    earnContractAddress: '0x2c0E2Ec5C2C84346497cc82F5afF72f8A29dA835',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ripae-pae-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Ripae',
    assets: ['PAE', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/AVAX/0x9466Ab927611725B9AF76b9F31B2F879Ff14233d',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0x9466Ab927611725B9AF76b9F31B2F879Ff14233d',
    createdAt: 1646045101,
  },

  {
    id: 'png-acre-wavax',
    name: 'ACRE-AVAX LP',
    token: 'ACRE-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x64694FC8dFCA286bF1A15b0903FAC98217dC3AD7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinV2ACRE-AVAX',
    earnedTokenAddress: '0xB6aE1f6Be8575a44D22af3cD2C5385CC9c293978',
    earnContractAddress: '0xB6aE1f6Be8575a44D22af3cD2C5385CC9c293978',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-acre-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['ACRE', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0x00EE200Df31b869a321B10400Da10b561F3ee60d',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?outputCurrency=0x00EE200Df31b869a321B10400Da10b561F3ee60d',
    createdAt: 1645887882,
  },
  {
    id: 'png-bribe-wavax-eol',
    name: 'BRIBE-AVAX LP',
    token: 'BRIBE-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x7472887De3B0aA65168a1Da22164C81DE5fd4044',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinV2BRIBE-AVAX',
    earnedTokenAddress: '0xbaCeC852971EB461DA6Ad8F5C2e37694dca56002',
    earnContractAddress: '0xbaCeC852971EB461DA6Ad8F5C2e37694dca56002',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-bribe-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['BRIBE', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/ 0xCe2fbed816E320258161CeD52c2d0CEBcdFd8136',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?outputCurrency=0xCe2fbed816E320258161CeD52c2d0CEBcdFd8136',
    createdAt: 1645972427,
  },
  {
    id: 'png-tus-wavax',
    name: 'TUS-AVAX LP',
    token: 'TUS-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xbCEd3B6D759B9CA8Fc7706E46Aa81627b2e9EAE8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinV2TUS-AVAX',
    earnedTokenAddress: '0xEd7208d44f5cC209DE534461a5D5b3cf60fDdeE5',
    earnContractAddress: '0xEd7208d44f5cC209DE534461a5D5b3cf60fDdeE5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-tus-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['TUS', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xf693248F96Fe03422FEa95aC0aFbBBc4a8FdD172',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?outputCurrency=0xf693248F96Fe03422FEa95aC0aFbBBc4a8FdD172',
    createdAt: 1645972427,
  },
  {
    id: 'png-fire-wavax-eol',
    name: 'FIRE-AVAX LP',
    token: 'FIRE-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x45324950c6ba08112EbF72754004a66a0a2b7721',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinV2FIRE-AVAX',
    earnedTokenAddress: '0xcd1f50DFfbC44F5eeFBA2ae6a19dE8196B51427a',
    earnContractAddress: '0xcd1f50DFfbC44F5eeFBA2ae6a19dE8196B51427a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-fire-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['FIRE', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xfcc6CE74f4cd7eDEF0C5429bB99d38A3608043a5',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?outputCurrency=0xfcc6CE74f4cd7eDEF0C5429bB99d38A3608043a5',
    createdAt: 1645197575,
  },
  {
    id: 'curve-avax-ren',
    logo: 'single-assets/renBTC.png',
    name: 'WBTC/renBTC',
    token: 'btcCRV',
    tokenDescription: 'Curve',
    tokenAddress: '0xC2b1DF84112619D190193E48148000e3990Bf627',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCurveRen',
    earnedTokenAddress: '0xD437ba91922f7e0066C780cC17f175708Fd7b736',
    earnContractAddress: '0xD437ba91922f7e0066C780cC17f175708Fd7b736',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-avax-ren',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['WBTC', 'renBTC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://avax.curve.fi/ren/deposit',
    createdAt: 1652662923,
  },
  {
    id: 'joe-wavax-savax',
    name: 'sAVAX-AVAX LP',
    token: 'sAVAX-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x4b946c91C2B1a7d7C40FB3C130CdfBaf8389094d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeAVAX-sAVAX',
    earnedTokenAddress: '0x37a51D57958386E52f6d7D7d08ACb381abDA9157',
    earnContractAddress: '0x37a51D57958386E52f6d7D7d08ACb381abDA9157',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-savax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['sAVAX', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0x2b2C81e08f1Af8835a78Bb2A90AE924ACE0eA4bE/AVAX',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0x2b2C81e08f1Af8835a78Bb2A90AE924ACE0eA4bE',
    createdAt: 1645036500,
  },
  {
    id: 'png-luna-wavax',
    name: 'LUNA-AVAX LP',
    token: 'LUNA-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x40e747f27E6398b1f7C017c5ff5c31a2Ab69261c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinV2LUNA-AVAX',
    earnedTokenAddress: '0x5508222678C5337e76D93A3005dC008056715655',
    earnContractAddress: '0x5508222678C5337e76D93A3005dC008056715655',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-luna-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['LUNA', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0x120AD3e5A7c796349e591F1570D9f7980F4eA9cb',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?outputCurrency=0x120AD3e5A7c796349e591F1570D9f7980F4eA9cb',
    createdAt: 1644484239,
  },
  {
    id: 'joe-wavax-domi',
    name: 'DOMI-AVAX LP',
    token: 'DOMI-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x5B9Faf0feA95df4D4cB894Ef920704daFf656f3E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeAVAX-DOMI',
    earnedTokenAddress: '0x440F1249349B185748C9aA8dAB26F067A515A68F',
    earnContractAddress: '0x440F1249349B185748C9aA8dAB26F067A515A68F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-domi',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['DOMI', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0xFc6Da929c031162841370af240dEc19099861d3B/AVAX',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0xFc6Da929c031162841370af240dEc19099861d3B',
    createdAt: 1644407428,
  },
  {
    id: 'joe-wavax-ime-eol',
    name: 'IME-AVAX LP',
    token: 'IME-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x5d95ae932D42E53Bb9DA4DE65E9b7263A4fA8564',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeAvax-IME',
    earnedTokenAddress: '0x6330c1eC0449c721F0DB17980344Abd6dC071Dfb',
    earnContractAddress: '0x6330c1eC0449c721F0DB17980344Abd6dC071Dfb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-ime',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Trader Joe',
    assets: ['IME', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/AVAX/0xf891214fdcf9cdaa5fdc42369ee4f27f226adad6',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0xF891214fdcF9cDaa5fdC42369eE4F27F226AdaD6#/',
    createdAt: 1643668967,
  },
  {
    id: 'joe-wavax-pefi2-eol',
    name: 'PEFI-AVAX LP',
    token: 'PEFI-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xb78c8238bD907c42BE45AeBdB4A8C8a5D7B49755',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeAvax-PEFI',
    earnedTokenAddress: '0x35A338091c2077f33BED09717EB31BA0f02Efb2f',
    earnContractAddress: '0x35A338091c2077f33BED09717EB31BA0f02Efb2f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-pefi2',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Trader Joe',
    assets: ['PEFI', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/AVAX/0xe896cdeaac9615145c0ca09c8cd5c25bced6384c',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0xe896CDeaAC9615145c0cA09C8Cd5C25bced6384c',
    createdAt: 1643975170,
  },
  {
    id: 'joe-link.e-usdc.e-eol',
    name: 'Link.e-USDC.e LP',
    token: 'Link.e-USDC.e JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xb9f425bC9AF072a91c423e31e9eb7e04F226B39D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeLink.e-USDC.e',
    earnedTokenAddress: '0x6537015Ea91516FdA74deB5E361d1FB4482D9525',
    earnContractAddress: '0x6537015Ea91516FdA74deB5E361d1FB4482D9525',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-link.e-usdc.e',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Trader Joe',
    assets: ['USDCe', 'LINKe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0x5947bb275c521040051d82396192181b413227a3/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0x5947BB275c521040051D82396192181b413227A3',
    createdAt: 1643975317,
  },
  {
    id: 'png-mim-usdc.e-eol',
    name: 'MIM-USDC.e LP',
    token: 'MIM-USDC.e LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xE75eD6E50e3e2dc6b06FAf38b943560BD22e343B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinV2MIM-USDC.e',
    earnedTokenAddress: '0x83E05C8a4AdEfA875ABe0f30a18bb6F9b54b807e',
    earnContractAddress: '0x83E05C8a4AdEfA875ABe0f30a18bb6F9b54b807e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-mim-usdc.e',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['MIM', 'USDCe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/0x130966628846BFd36ff31a822705796e8cb8C18D/0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664&outputCurrency=0x130966628846BFd36ff31a822705796e8cb8C18D',
    createdAt: 1643465038,
  },
  {
    id: 'png-ust-wavax',
    name: 'UST-AVAX LP',
    token: 'UST-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xdeaBb6e80141F5E557EcBDD7e9580F37D7BBc371',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinV2UST-AVAX',
    earnedTokenAddress: '0xDf306fBdA58527729A8D5185aB8fEF96BFa94c7A',
    earnContractAddress: '0xDf306fBdA58527729A8D5185aB8fEF96BFa94c7A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-ust-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['UST', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
      'ALGO_STABLE',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0x260Bbf5698121EB85e7a74f2E45E16Ce762EbE11',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?outputCurrency=0x260Bbf5698121EB85e7a74f2E45E16Ce762EbE11',
    createdAt: 1643836585,
  },
  {
    id: 'png-ust-usdc',
    name: 'UST-USDC LP',
    token: 'UST-USDC LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x3c0ECf5F430bbE6B16A8911CB25d898Ef20805cF',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinV2UST-USDC',
    earnedTokenAddress: '0x6399A5E96CD627404b203Ea80517C3F8F9F78Fe6',
    earnContractAddress: '0x6399A5E96CD627404b203Ea80517C3F8F9F78Fe6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-ust-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['UST', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
      'ALGO_STABLE',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E/0x260Bbf5698121EB85e7a74f2E45E16Ce762EbE11',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E&outputCurrency=0x260Bbf5698121EB85e7a74f2E45E16Ce762EbE11',
    createdAt: 1643839674,
  },
  {
    id: 'grape-grape-mim',
    name: 'GRAPE-MIM LP',
    token: 'GRAPE-MIM LP',
    tokenDescription: 'Trader Joe (Grape)',
    tokenAddress: '0xb382247667fe8CA5327cA1Fa4835AE77A9907Bc8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooGrapeGRAPE-MIM',
    earnedTokenAddress: '0x9b03eF3896edea1cC1Ee19b8Fed35cA82843Aed4',
    earnContractAddress: '0x9b03eF3896edea1cC1Ee19b8Fed35cA82843Aed4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'grape-grape-mim',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Other',
    assets: ['GRAPE', 'MIM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0x130966628846bfd36ff31a822705796e8cb8c18d/0x5541d83efad1f281571b343977648b75d95cdac2',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0x5541D83EFaD1f281571B343977648B75d95cdAC2',
    createdAt: 1643717100,
  },
  {
    id: 'grape-wine-mim',
    name: 'WINE-MIM LP',
    token: 'WINE-MIM LP',
    tokenDescription: 'Trader Joe (Grape)',
    tokenAddress: '0x00cB5b42684DA62909665d8151fF80D1567722c3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooGrapeWINE-MIM',
    earnedTokenAddress: '0x0421AE152e3967361a54396486C970257c14E096',
    earnContractAddress: '0x0421AE152e3967361a54396486C970257c14E096',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'grape-wine-mim',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Other',
    assets: ['WINE', 'MIM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0x130966628846bfd36ff31a822705796e8cb8c18d/0xC55036B5348CfB45a932481744645985010d3A44',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0xC55036B5348CfB45a932481744645985010d3A44',
    createdAt: 1643718763,
  },
  {
    id: 'png-usdc.e-wavax',
    name: 'USDC.e-AVAX LP',
    token: 'USDC.e-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xbd918Ed441767fe7924e99F6a0E0B568ac1970D9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinV2USDC.e-AVAX',
    earnedTokenAddress: '0x9B02209a331c072637C6eBd34cdCD6b6A16987a9',
    earnContractAddress: '0x9B02209a331c072637C6eBd34cdCD6b6A16987a9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-usdc.e-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['USDCe', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664/0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664&outputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    createdAt: 1643458455,
  },
  {
    id: 'png-png-usdc.e-eol',
    name: 'PNG-USDC.e LP',
    token: 'PNG-USDC.e LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xC33Ac18900b2f63DFb60B554B1F53Cd5b474d4cd',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinV2PNG-USDC.e',
    earnedTokenAddress: '0x9829E51f6C1292563892dC65A4270b94E09B30C0',
    earnContractAddress: '0x9829E51f6C1292563892dC65A4270b94E09B30C0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-png-usdc.e',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['PNG', 'USDCe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/0x60781c2586d68229fde47564546784ab3faca982/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0x60781c2586d68229fde47564546784ab3faca982&outputCurrency=0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
    createdAt: 1643469425,
  },
  {
    id: 'png-png-wavax-eol',
    name: 'PNG-AVAX LP',
    token: 'PNG-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xd7538cABBf8605BdE1f4901B47B8D42c61DE0367',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinV2PNG-AVAX',
    earnedTokenAddress: '0x180A73F4e0a84E4343019529429344cBEC21d946',
    earnContractAddress: '0x180A73F4e0a84E4343019529429344cBEC21d946',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-png-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'Pangolin',
    assets: ['PNG', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/0x60781c2586d68229fde47564546784ab3faca982/0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0x60781c2586d68229fde47564546784ab3faca982&outputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    createdAt: 1643465763,
  },
  {
    id: 'joe-wavax-xava',
    name: 'XAVA-AVAX LP',
    token: 'XAVA-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x72c3438cf1c915EcF5D9F17A6eD346B273d5bF71',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeAVAX-XAVA',
    earnedTokenAddress: '0x72A6F8E2510B9332Eaf744FEb1F7c19E3aCB2201',
    earnContractAddress: '0x72A6F8E2510B9332Eaf744FEb1F7c19E3aCB2201',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-xava',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['XAVA', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_SMALL',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0xd1c3f94DE7e5B45fa4eDBBA472491a9f4B166FC4/AVAX',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0xd1c3f94DE7e5B45fa4eDBBA472491a9f4B166FC4',
    createdAt: 1643391049,
  },
  {
    id: 'joe-wavax-cook-eol',
    name: 'COOK-AVAX LP',
    token: 'COOK-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x3fcD1d5450e63FA6af495A601E6EA1230f01c4E3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeAVAX-COOK',
    earnedTokenAddress: '0x4E685C0d068cb0af88Ff662B89951Ad5B3727387',
    earnContractAddress: '0x4E685C0d068cb0af88Ff662B89951Ad5B3727387',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-cook',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'Trader Joe',
    assets: ['COOK', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0x637afeff75ca669fF92e4570B14D6399A658902f/AVAX',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0x637afeff75ca669fF92e4570B14D6399A658902f',
    createdAt: 1643382032,
  },
  {
    id: 'joe-wavax-dcau',
    name: 'DCAU-AVAX LP',
    token: 'DCAU-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x81034A38a124A3290DC226798f34c6645B153a02',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeAVAX-DCAU',
    earnedTokenAddress: '0x2D48D94ABfaD5a95b5fCA0fAB9cD37dd722C84A4',
    earnContractAddress: '0x2D48D94ABfaD5a95b5fCA0fAB9cD37dd722C84A4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-dcau',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['DCAU', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30/AVAX',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30',
    createdAt: 1643387033,
  },
  {
    id: 'joe-wavax-hec-eol',
    name: 'HEC-AVAX LP',
    token: 'HEC-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x4dC5291cdc7Ad03342994E35D0Ccc76De065A566',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeAVAX-HEC',
    earnedTokenAddress: '0xD8E92bcBf66a675b2Ac45f0BBbb0F285ba607AB7',
    earnContractAddress: '0xD8E92bcBf66a675b2Ac45f0BBbb0F285ba607AB7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-hec',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Trader Joe',
    assets: ['HEC', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0xC7f4debC8072e23fe9259A5C0398326d8EfB7f5c/AVAX',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0xC7f4debC8072e23fe9259A5C0398326d8EfB7f5c',
    createdAt: 1643388939,
  },
  {
    id: 'png-wavax-frax-eol',
    name: 'FRAX-AVAX LP',
    token: 'FRAX-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x0CE543c0f81ac9AAa665cCaAe5EeC70861a6b559',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinV2AVAX-FRAX',
    earnedTokenAddress: '0x405e20D81E659382e2bcaE6D6Cb6581203Fcd95D',
    earnContractAddress: '0x405e20D81E659382e2bcaE6D6Cb6581203Fcd95D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-wavax-frax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'Pangolin',
    assets: ['FRAX', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/0xD24C2Ad096400B6FBcd2ad8B24E7acBc21A1da64/AVAX',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?outputCurrency=0xD24C2Ad096400B6FBcd2ad8B24E7acBc21A1da64',
    createdAt: 1643443063,
  },
  {
    id: 'png-link.e-wavax',
    name: 'LINK.e-AVAX LP',
    token: 'LINK.e-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x5875c368Cddd5FB9Bf2f410666ca5aad236DAbD4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinV2LINKe-AVAX',
    earnedTokenAddress: '0xb2f030af9B6000912e13764018A8121751D86BE8',
    earnContractAddress: '0xb2f030af9B6000912e13764018A8121751D86BE8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-link.e-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['LINKe', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/0x5947BB275c521040051D82396192181b413227A3/AVAX',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?outputCurrency=0x5947BB275c521040051D82396192181b413227A3',
    createdAt: 1643442618,
  },
  {
    id: 'png-jewel-wavax',
    name: 'JEWEL-AVAX LP',
    token: 'JEWEL-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x9AA76aE9f804E7a70bA3Fb8395D0042079238E9C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinV2JEWEL-AVAX',
    earnedTokenAddress: '0x84Cb5FF7f5068AC02a7B0fd4772577c670A5aFC6',
    earnContractAddress: '0x84Cb5FF7f5068AC02a7B0fd4772577c670A5aFC6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-jewel-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['JEWEL', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/0x4f60a160D8C2DDdaAfe16FCC57566dB84D674BD6/AVAX',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?outputCurrency=0x4f60a160D8C2DDdaAfe16FCC57566dB84D674BD6',
    createdAt: 1643431788,
  },
  {
    id: 'joe-ust-wavax-eol',
    name: 'UST-AVAX LP',
    token: 'UST-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x7BF98BD74E19AD8eB5e14076140Ee0103F8F872B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeUST-AVAX',
    earnedTokenAddress: '0xe6f6466E1cA56ab02eBb909C8228eD76534686D7',
    earnContractAddress: '0xe6f6466E1cA56ab02eBb909C8228eD76534686D7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-ust-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Trader Joe',
    assets: ['UST', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
      'ALGO_STABLE',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0x260Bbf5698121EB85e7a74f2E45E16Ce762EbE11/AVAX',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0x260Bbf5698121EB85e7a74f2E45E16Ce762EbE11',
    createdAt: 1643314337,
  },
  {
    id: 'joe-wavax-more',
    name: 'MORE-AVAX LP',
    token: 'MORE-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xb8361D0E3F3B0fc5e6071f3a3C3271223C49e3d9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeMORE-AVAX',
    earnedTokenAddress: '0xA413a9a99F2681c89369Fe1e5F91d209C8bc7d53',
    earnContractAddress: '0xA413a9a99F2681c89369Fe1e5F91d209C8bc7d53',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-more',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['MORE', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0xd9D90f882CDdD6063959A9d837B05Cb748718A05/AVAX',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0xd9D90f882CDdD6063959A9d837B05Cb748718A05',
    createdAt: 1643314704,
  },
  {
    id: 'joe-gmx-wavax',
    name: 'GMX-AVAX LP',
    token: 'GMX-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x0c91a070f862666bBcce281346BE45766d874D98',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeGMX-AVAX',
    earnedTokenAddress: '0x682d9fB30cfe4Ced14a307F57AAbE9B27E05CC56',
    earnContractAddress: '0x682d9fB30cfe4Ced14a307F57AAbE9B27E05CC56',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-gmx-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['GMX', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0x62edc0692BD897D2295872a9FFCac5425011c661/AVAX',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0x62edc0692BD897D2295872a9FFCac5425011c661',
    createdAt: 1643314903,
  },
  {
    id: 'png-png',
    logo: 'single-assets/PNG.png',
    name: 'PNG',
    token: 'PNG',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x60781C2586D68229fde47564546784ab3fACA982',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinV2PNG',
    earnedTokenAddress: '0x808D5f0A62336917Da14fA9A10E9575B1040f71c',
    earnContractAddress: '0x808D5f0A62336917Da14fA9A10E9575B1040f71c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'PNG',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pangolin',
    assets: ['PNG'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x60781C2586D68229fde47564546784ab3fACA982',
    createdAt: 1642706787,
  },
  {
    id: 'joe-usdc.e-usdc',
    name: 'USDC-USDC.e LP',
    token: 'USDC-USDC.e JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x2A8A315e82F85D1f0658C5D66A452Bbdd9356783',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeUSDC.e-USDC',
    earnedTokenAddress: '0x42ab5A790E99dF1b5d46f1C5C3e61d0Cd63D1f6E',
    earnContractAddress: '0x42ab5A790E99dF1b5d46f1C5C3e61d0Cd63D1f6E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-usdc.e-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['USDC', 'USDCe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664/0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
    createdAt: 1639756286,
  },
  {
    id: 'joe-usdt-usdt.e',
    name: 'USDT-USDT.e LP',
    token: 'USDt-USDT.e JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x74B651Eff97871eA99fcc14423E611d85Eb0EA93',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeUSDT-USDTe',
    earnedTokenAddress: '0x258e9884c111E2e3e0273372521982bd57ef29Bd',
    earnContractAddress: '0x258e9884c111E2e3e0273372521982bd57ef29Bd',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-usdt-usdt.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['USDT', 'USDTe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7/0xc7198437980c041c805a1edcba50c1ce5db95118',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7#/',
    createdAt: 1643233920,
  },
  {
    id: 'joe-wavax-frax-eol',
    name: 'FRAX-AVAX LP',
    token: 'FRAX-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x862905a82382Db9405a40DCAa8Ee9e8F4af52C89',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeAvax-FRAX',
    earnedTokenAddress: '0xd2D0Cf1675961357AfC934856421D2C5ffd87b1e',
    earnContractAddress: '0xd2D0Cf1675961357AfC934856421D2C5ffd87b1e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-frax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Trader Joe',
    assets: ['FRAX', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/AVAX/0xd24c2ad096400b6fbcd2ad8b24e7acbc21a1da64',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0xd24c2ad096400b6fbcd2ad8b24e7acbc21a1da64#/',
    createdAt: 1643310908,
  },
  {
    id: 'joe-wavax-cly-eol',
    name: 'CLY-AVAX LP',
    token: 'CLY-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x0B2777b0c55AEaAeb56E86B6eEFa6cC2Cfa00e07',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeAVAX-CLY',
    earnedTokenAddress: '0x374DC1BaF7a05e2D47f8D169FB9c8c862A9082CA',
    earnContractAddress: '0x374DC1BaF7a05e2D47f8D169FB9c8c862A9082CA',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-cly',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Trader Joe',
    assets: ['CLY', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/AVAX/0xec3492a2508DDf4FDc0cD76F31f340b30d1793e6',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0xec3492a2508DDf4FDc0cD76F31f340b30d1793e6',
    createdAt: 1639753516,
  },
  {
    id: 'joe-ptp-wavax',
    name: 'PTP-AVAX LP',
    token: 'PTP-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xCDFD91eEa657cc2701117fe9711C9a4F61FEED23',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoePTP-AVAX',
    earnedTokenAddress: '0xc7024B02a3C3893C482F5DD03193CFD1DBEC604f',
    earnContractAddress: '0xc7024B02a3C3893C482F5DD03193CFD1DBEC604f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-ptp-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['PTP', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/AVAX/0x22d4002028f537599bE9f666d1c4Fa138522f9c8',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0x22d4002028f537599bE9f666d1c4Fa138522f9c8',
    createdAt: 1639751352,
  },
  {
    id: 'joe-awool-wavax-eol',
    name: 'aWOOL-AVAX LP',
    token: 'aWOOL-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xf895E6588585912e60b0131fa173e986859f69D5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeaWOOL-AVAX',
    earnedTokenAddress: '0xCefB5D22477394222F5C683E917Aa7c65847262C',
    earnContractAddress: '0xCefB5D22477394222F5C683E917Aa7c65847262C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-awool-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Trader Joe',
    assets: ['aWOOL', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/AVAX/0x5eDE350E84223fb50775fD91a723F2ca71034cf7',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0x5eDE350E84223fb50775fD91a723F2ca71034cf7',
    createdAt: 1639412305,
  },
  {
    id: 'joe-klo-wavax-eol',
    name: 'KLO-AVAX LP',
    token: 'KLO-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xb2fF0817ad078C92C3AfB82326592e06C92581B8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeKLO-AVAX',
    earnedTokenAddress: '0x57c7128A8FbA6425F380AF712EFDCD7688acE332',
    earnContractAddress: '0x57c7128A8FbA6425F380AF712EFDCD7688acE332',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-klo-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Trader Joe',
    assets: ['KLO', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/AVAX/0xb27c8941a7Df8958A1778c0259f76D1F8B711C35',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0xb27c8941a7Df8958A1778c0259f76D1F8B711C35',
    createdAt: 1639411464,
  },
  {
    id: 'joe-craft-wavax-eol',
    name: 'CRAFT-AVAX LP',
    token: 'CRAFT-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x86D1b1Ab4812a104BC1Ea1FbD07809DE636E6C6b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeCRAFT-AVAX',
    earnedTokenAddress: '0x5526851c271d8B59F6412e3244A7A6db3A13808f',
    earnContractAddress: '0x5526851c271d8B59F6412e3244A7A6db3A13808f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-craft-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Trader Joe',
    assets: ['CRAFT', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/AVAX/0x8aE8be25C23833e0A01Aa200403e826F611f9CD2',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0x8aE8be25C23833e0A01Aa200403e826F611f9CD2',
    createdAt: 1639410461,
  },
  {
    id: 'joe-melt-wavax-eol',
    name: 'MELT-AVAX LP',
    token: 'MELT-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x2923a62b2531EC744ca0C1e61dfFab1Ad9369FeB',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeMELT-AVAX',
    earnedTokenAddress: '0x2ebAF60a6d6AE9ffe2C418470dF0D2444b996AE9',
    earnContractAddress: '0x2ebAF60a6d6AE9ffe2C418470dF0D2444b996AE9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-melt-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'Trader Joe',
    assets: ['MELT', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/AVAX/0x47EB6F7525C1aA999FBC9ee92715F5231eB1241D',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0x47EB6F7525C1aA999FBC9ee92715F5231eB1241D',
    createdAt: 1639409390,
  },
  {
    id: 'joe-gohm-wavax',
    name: 'gOHM-AVAX LP',
    token: 'gOHM-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xB674f93952F02F2538214D4572Aa47F262e990Ff',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoegOHM-AVAX',
    earnedTokenAddress: '0x40324434a0b53dd1ED167Ba30dcB6B4bd7a9536d',
    earnContractAddress: '0x40324434a0b53dd1ED167Ba30dcB6B4bd7a9536d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-gohm-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['gOHM', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/AVAX/0x321E7092a180BB43555132ec53AaA65a5bF84251',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0x321E7092a180BB43555132ec53AaA65a5bF84251',
    createdAt: 1638350965,
  },
  {
    id: 'synapse-nusdlp',
    logo: 'uncategorized/nUSD-LP.png',
    name: 'nUSD/DAI/USDC/USDT',
    token: 'nUSD-LP',
    tokenDescription: 'Synapse',
    tokenAddress: '0xCA87BF3ec55372D9540437d7a86a7750B42C02f4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSynapsenUSD-LP',
    earnedTokenAddress: '0xa803058482F2681f347BB0da6f31F2DF94d72DEA',
    earnContractAddress: '0xa803058482F2681f347BB0da6f31F2DF94d72DEA',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'synapse-nusdlp',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Synapse',
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'ALGO_STABLE',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratMultiLP',
    assets: ['nUSD', 'DAIe', 'USDCe', 'USDTe'],
    withdrawalFee: '0.01%',
    addLiquidityUrl: 'https://synapseprotocol.com/pools/avalanchenusd',
    createdAt: 1637768693,
  },
  {
    id: 'blizz-avax-pause',
    logo: 'single-assets/AVAX.svg',
    name: 'AVAX',
    token: 'AVAX',
    tokenDescription: 'Blizz',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBlizzAVAX',
    earnedTokenAddress: '0x99EeB92A4896a9F45E9390e2A05ceE5647BA0f95',
    earnContractAddress: '0x99EeB92A4896a9F45E9390e2A05ceE5647BA0f95',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'AVAX',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Blizz',
    assets: ['AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    createdAt: 1637579519,
  },
  {
    id: 'blizz-eth-pause',
    logo: 'single-assets/ETH.svg',
    name: 'WETH.e',
    token: 'WETH.e',
    tokenDescription: 'Blizz',
    tokenAddress: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBlizzETH',
    earnedTokenAddress: '0x6cBaEBC1333E72206FC326e810972D28f8250485',
    earnContractAddress: '0x6cBaEBC1333E72206FC326e810972D28f8250485',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WETH.e',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Blizz',
    assets: ['WETHe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
    createdAt: 1637587754,
  },
  {
    id: 'blizz-wbtc-pause',
    logo: 'single-assets/WBTC.svg',
    name: 'WBTC.e',
    token: 'WBTC.e',
    tokenDescription: 'Blizz',
    tokenAddress: '0x50b7545627a5162F82A992c33b87aDc75187B218',
    tokenDecimals: 8,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBlizzBTC',
    earnedTokenAddress: '0x39F94cb5d9239858Ba8eeBD1174b8B13e34b240B',
    earnContractAddress: '0x39F94cb5d9239858Ba8eeBD1174b8B13e34b240B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WBTC.e',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Blizz',
    assets: ['WBTCe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0x50b7545627a5162F82A992c33b87aDc75187B218',
    createdAt: 1637589664,
  },
  {
    id: 'blizz-blzz-avax',
    name: 'BLZZ-AVAX LP',
    token: 'BLZZ-AVAX LP',
    tokenDescription: 'Trader Joe (Blizz)',
    tokenAddress: '0xac3F978714c613E768272c502a8912bC03DCf624',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBlizzBLZZ-AVAX',
    earnedTokenAddress: '0xE06575Cfd4a631e8C0Cd9FBDd8d77AB04CFfA52C',
    earnContractAddress: '0xE06575Cfd4a631e8C0Cd9FBDd8d77AB04CFfA52C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'blizz-blzz-avax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Blizz',
    assets: ['BLZZ', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/AVAX/0x0f34919404a290e71fc6A510cB4a6aCb8D764b24',
    buyTokenUrl:
      'https://traderjoexyz.com/trade?outputCurrency=0x0f34919404a290e71fc6A510cB4a6aCb8D764b24',
    createdAt: 1636366466,
  },
  {
    id: 'joe-cra-wavax',
    name: 'CRA-AVAX LP',
    token: 'CRA-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x140CAc5f0e05cBEc857e65353839FddD0D8482C1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeCRA-AVAX',
    earnedTokenAddress: '0x5dd766E859c4BB01eaBCaFc320dED1A5E8e4a147',
    earnContractAddress: '0x5dd766E859c4BB01eaBCaFc320dED1A5E8e4a147',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-cra-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['CRA', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/AVAX/0xA32608e873F9DdEF944B24798db69d80Bbb4d1ed',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0xA32608e873F9DdEF944B24798db69d80Bbb4d1ed',
    createdAt: 1637589059,
  },
  {
    id: 'joe-wavax-boo-eol',
    name: 'BOO-AVAX LP',
    token: 'BOO-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xebF50b8089a0c5E7c5de23F644fcD723818f65B3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeAVAX-BOO',
    earnedTokenAddress: '0x714A52Cdee18cD074BaA87F1f9631B13d302E516',
    earnContractAddress: '0x714A52Cdee18cD074BaA87F1f9631B13d302E516',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-boo',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Trader Joe',
    assets: ['BOO', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/AVAX/0xbD83010eB60F12112908774998F65761cf9f6f9a',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0xbD83010eB60F12112908774998F65761cf9f6f9a',
    createdAt: 1637251694,
  },
  {
    id: 'spell-avax-mim-crv-eol',
    logo: 'uncategorized/MIM2CRV.svg',
    name: 'MIM/USDC/USDT',
    token: 'MIM-3PoolV2',
    tokenDescription: 'Curve (MIM)',
    tokenAddress: '0xAEA2E71b631fA93683BCF256A8689dFa0e094fcD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAbrcdbrMIM-3PoolV2',
    earnedTokenAddress: '0x275eF36E6097c68bE8765c238100BF061B38A922',
    earnContractAddress: '0x275eF36E6097c68bE8765c238100BF061B38A922',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-avax-mim',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'MIM',
    assets: ['MIM', 'USDCe', 'USDTe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://avax.curve.fi/factory/4/deposit',
    createdAt: 1637146468,
  },
  {
    id: 'bankerjoe-avax',
    logo: 'single-assets/AVAX.svg',
    name: 'AVAX',
    token: 'AVAX',
    tokenDescription: 'Banker Joe',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBankerJoeAVAX',
    earnedTokenAddress: '0xfda2E1E9BE74F60738e935b06A5d9C32143B18D5',
    earnContractAddress: '0xfda2E1E9BE74F60738e935b06A5d9C32143B18D5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'AVAX',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Banker Joe',
    assets: ['AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    createdAt: 1636471887,
  },
  {
    id: 'bankerjoe-weth.e',
    logo: 'single-assets/ETH.svg',
    name: 'WETH.e',
    token: 'WETH.e',
    tokenDescription: 'Banker Joe',
    tokenAddress: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBankerJoeWETH.e',
    earnedTokenAddress: '0x7E47541114db3addD074EB5aAD3A2318671FD91D',
    earnContractAddress: '0x7E47541114db3addD074EB5aAD3A2318671FD91D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WETH.e',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Banker Joe',
    assets: ['WETHe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
    createdAt: 1636468072,
  },
  {
    id: 'bankerjoe-wbtc.e',
    logo: 'single-assets/WBTC.svg',
    name: 'WBTC.e',
    token: 'WBTC.e',
    tokenDescription: 'Banker Joe',
    tokenAddress: '0x50b7545627a5162F82A992c33b87aDc75187B218',
    tokenDecimals: 8,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBankerJoeWBTC.e',
    earnedTokenAddress: '0x4C25854f6DA3b5848F7B5C71dcb8EEe20b292d3E',
    earnContractAddress: '0x4C25854f6DA3b5848F7B5C71dcb8EEe20b292d3E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WBTC.e',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Banker Joe',
    assets: ['WBTCe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0x50b7545627a5162F82A992c33b87aDc75187B218',
    createdAt: 1636462707,
  },
  {
    id: 'bankerjoe-usdc.e',
    logo: 'single-assets/USDC.svg',
    name: 'USDC.e',
    token: 'USDC.e',
    tokenDescription: 'Banker Joe',
    tokenAddress: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    tokenDecimals: 6,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBankerJoeUSDC.e',
    earnedTokenAddress: '0xEbdf71f56BB3ae1D145a4121d0DDCa5ABEA7a946',
    earnContractAddress: '0xEbdf71f56BB3ae1D145a4121d0DDCa5ABEA7a946',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDC.e',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Banker Joe',
    assets: ['USDCe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    createdAt: 1636468925,
  },
  {
    id: 'bankerjoe-usdt.e',
    logo: 'single-assets/USDT.svg',
    name: 'USDT.e',
    token: 'USDT.e',
    tokenDescription: 'Banker Joe',
    tokenAddress: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    tokenDecimals: 6,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBankerJoeUSDT.e',
    earnedTokenAddress: '0x87c4F0BB71C4Bb92809F33dBd807614475eA8a5a',
    earnContractAddress: '0x87c4F0BB71C4Bb92809F33dBd807614475eA8a5a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDT.e',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Banker Joe',
    assets: ['USDTe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    createdAt: 1636470449,
  },
  {
    id: 'bankerjoe-link.e-eol',
    logo: 'single-assets/LINK.png',
    name: 'LINK.e',
    token: 'LINK.e',
    tokenDescription: 'Banker Joe',
    tokenAddress: '0x5947BB275c521040051D82396192181b413227A3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBankerJoeLINK.e',
    earnedTokenAddress: '0x4112D114658c8B9a7598E4b588f75e80B2d9b1F4',
    earnContractAddress: '0x4112D114658c8B9a7598E4b588f75e80B2d9b1F4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'LINK.e',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'Banker Joe',
    assets: ['LINKe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0x5947BB275c521040051D82396192181b413227A3',
    createdAt: 1636458630,
  },
  {
    id: 'bankerjoe-mim-eol',
    logo: 'single-assets/MIM.png',
    name: 'MIM',
    token: 'MIM',
    tokenDescription: 'Banker Joe',
    tokenAddress: '0x130966628846BFd36ff31a822705796e8cb8C18D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBankerJoeMIM',
    earnedTokenAddress: '0xfe27D61F8386370d6CfA63EA06470ED55F6DBFe7',
    earnContractAddress: '0xfe27D61F8386370d6CfA63EA06470ED55F6DBFe7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'MIM',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Banker Joe',
    assets: ['MIM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0x130966628846BFd36ff31a822705796e8cb8C18D',
    createdAt: 1636455936,
  },
  {
    id: 'joe-wavax-bifi-eol',
    name: 'BIFI-AVAX LP',
    token: 'BIFI-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x361221991B3B6282fF3a62Bc874d018bfAF1f8C8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeWAVAX-BIFI',
    earnedTokenAddress: '0xb1e29194d90d67b8d1c4104FDf6DaF0F7d3344D5',
    earnContractAddress: '0xb1e29194d90d67b8d1c4104FDf6DaF0F7d3344D5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-bifi',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Trader Joe',
    assets: ['BIFI', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/AVAX/0xd6070ae98b8069de6b494332d1a1a81b6179d960',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1632231693,
  },
  {
    id: 'curve-avax-av3crv',
    logo: 'polygon/CURVE-3Pool.png',
    name: 'DAI/USDC/USDT',
    token: 'av3CRV',
    tokenDescription: 'Curve',
    tokenAddress: '0x1337BedC9D22ecbe766dF105c9623922A27963EC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCurveAv3CRV',
    earnedTokenAddress: '0x79A44dc13e5863Cf4AB36ab13e038A5F16861Abc',
    earnContractAddress: '0x79A44dc13e5863Cf4AB36ab13e038A5F16861Abc',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-avax-av3crv',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['DAIe', 'USDCe', 'USDTe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://avax.curve.fi/aave/deposit',
    createdAt: 1652662923,
  },
  {
    id: 'joe-mai-wavax-v2-eol',
    name: 'MAI-AVAX LP',
    token: 'MAI-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x23dDca8de11eCcd8000263f008A92e10dC1f21e8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeMAI-AVAX',
    earnedTokenAddress: '0x41e6612044AeDDeB19102304775cA39c204Ad030',
    earnContractAddress: '0x41e6612044AeDDeB19102304775cA39c204Ad030',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-mai-wavax-v2',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'eol',
    platform: 'Trader Joe',
    assets: ['MAI', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/AVAX/0x5c49b268c9841AFF1Cc3B0a418ff5c3442eE3F3b',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1636310053,
  },
  {
    id: 'joe-ampl-wavax-eol',
    name: 'AMPL-AVAX LP',
    token: 'AMPL-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x230c4aD11510360aD0DB564a889C33559A959487',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeAMPL-AVAX',
    earnedTokenAddress: '0x936d1f2ECF2C911Efba16BCBB9F205184d827986',
    earnContractAddress: '0x936d1f2ECF2C911Efba16BCBB9F205184d827986',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-ampl-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Trader Joe',
    assets: ['AMPL', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/pool/AVAX/0x027dbcA046ca156De9622cD1e2D907d375e53aa7',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1636308849,
    retiredReason: 'tvl',
  },
  {
    id: 'sing-usdt.e-sing-eol',
    name: 'SING-USDT.e LP',
    token: 'SING-USDT.e LP',
    tokenDescription: 'TraderJoe (Singular)',
    tokenAddress: '0x42B33De8FAa08F9242F9E20d268FBd251DCEBd72',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSingUSDT.e-SING',
    earnedTokenAddress: '0x865628e18e99Ab563405E1F329024C50f7093318',
    earnContractAddress: '0x865628e18e99Ab563405E1F329024C50f7093318',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sing-usdt.e-sing',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['SING', 'USDTe'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/0xc7198437980c041c805a1edcba50c1ce5db95118/0xf9a075c9647e91410bf6c402bdf166e1540f67f0',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0xf9a075c9647e91410bf6c402bdf166e1540f67f0',
    createdAt: 1633701180,
    retiredReason: 'tvl',
  },
  {
    id: 'aave-usdc.e-eol',
    logo: 'single-assets/USDC.svg',
    name: 'USDC.e',
    token: 'USDC.e',
    tokenDescription: 'Aave',
    tokenAddress: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    tokenDecimals: 6,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveUSDC.e',
    earnedTokenAddress: '0xD795d70ec3C7b990ffED7a725a18Be5A9579c3b9',
    earnContractAddress: '0xD795d70ec3C7b990ffED7a725a18Be5A9579c3b9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDC.e',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Aave',
    assets: ['USDCe'],
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    retireReason: 'rewards',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    createdAt: 1633512435,
  },
  {
    id: 'aave-usdt.e-eol',
    logo: 'single-assets/USDT.svg',
    name: 'USDT.e',
    token: 'USDT.e',
    tokenDescription: 'Aave',
    tokenAddress: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    tokenDecimals: 6,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveUSDT.e',
    earnedTokenAddress: '0xb6767518b205ea8B312d2EF4d992A2a08C2f2416',
    earnContractAddress: '0xb6767518b205ea8B312d2EF4d992A2a08C2f2416',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDT.e',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Aave',
    assets: ['USDTe'],
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    createdAt: 1633529509,
    retiredReason: 'tvl',
  },
  {
    id: 'aave-aave.e-eol',
    logo: 'single-assets/AAVE.svg',
    name: 'AAVE.e',
    token: 'AAVE.e',
    tokenDescription: 'Aave',
    tokenAddress: '0x63a72806098Bd3D9520cC43356dD78afe5D386D9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAaveAAVE.e',
    earnedTokenAddress: '0x6944E87349dA351b441c4CFa4B7B749AD2DD2c81',
    earnContractAddress: '0x6944E87349dA351b441c4CFa4B7B749AD2DD2c81',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'AAVE.e',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Aave',
    assets: ['AAVEe'],
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    retireReason: 'rewards',
    buyTokenUrl:
      'https://www.traderjoexyz.com/trade?outputCurrency=0x63a72806098Bd3D9520cC43356dD78afe5D386D9',
    createdAt: 1633524150,
  },
  {
    id: 'joe-joe-usdc.e-eol',
    name: 'JOE-USDC.e LP',
    token: 'JOE-USDC.e JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x67926d973cD8eE876aD210fAaf7DFfA99E414aCf',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeJOE-USDC.e',
    earnedTokenAddress: '0xea75ff4F580418A1430Bd3EBaF26B03C096D9489',
    earnContractAddress: '0xea75ff4F580418A1430Bd3EBaF26B03C096D9489',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-joe-usdc.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'eol',
    platform: 'Trader Joe',
    assets: ['JOE', 'USDCe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1632845828,
  },
  {
    id: 'joe-wavax-aave.e-eol',
    name: 'AAVE.e-AVAX LP',
    token: 'AAVE.e-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xc3e6D9f7a3A5E3e223356383C7C055Ee3F26A34F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeAVAX-AAVE.e',
    earnedTokenAddress: '0xaF5D5a21E6d53b96daf37EC9fC9360c5b6B4AeBE',
    earnContractAddress: '0xaF5D5a21E6d53b96daf37EC9fC9360c5b6B4AeBE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-aave.e',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Trader Joe',
    assets: ['AAVEe', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/0x63a72806098bd3d9520cc43356dd78afe5d386d9/AVAX',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1632847091,
  },
  {
    id: 'joe-mai-wavax-eol',
    name: 'miMatic-AVAX LP',
    token: 'miMatic-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xD6d03fe131dB3dE3aF5E6326036BaC4C1Cf8C80d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeMiMatic-AVAX',
    earnedTokenAddress: '0xe419f17821F10Ce741F67232406F6a8585d52005',
    earnContractAddress: '0xe419f17821F10Ce741F67232406F6a8585d52005',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-mai-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'eol',
    platform: 'Trader Joe',
    assets: ['miMatic', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/0x3b55e45fd6bd7d4724f5c47e0d1bcaedd059263e/AVAX',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1632848062,
  },
  {
    id: 'joe-weth.e-usdc.e-eol',
    name: 'WETH.e-USDC.e LP',
    token: 'WETH.e-USDC.e JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x199fb78019A08af2Cb6a078409D0C8233Eba8a0c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeWETH.e-USDC.e',
    earnedTokenAddress: '0xA4D0b2100107479dd637526F9296D34D8CBdde13',
    earnContractAddress: '0xA4D0b2100107479dd637526F9296D34D8CBdde13',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-weth.e-usdc.e',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Trader Joe',
    assets: ['WETHe', 'USDCe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1632849037,
  },
  {
    id: 'joe-wbtc.e-usdc.e-eol',
    name: 'WBTC.e-USDC.e LP',
    token: 'WBTC.e-USDC.e JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x62475f52aDd016A06B398aA3b2C2f2E540d36859',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeWBTC.e-USDC.e',
    earnedTokenAddress: '0x0D6f7D5Bf8406FAaa1eeB66756f684dBc6790350',
    earnContractAddress: '0x0D6f7D5Bf8406FAaa1eeB66756f684dBc6790350',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wbtc.e-usdc.e',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Trader Joe',
    assets: ['WBTCe', 'USDCe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/0x50b7545627a5162f82a992c33b87adc75187b218/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1632850669,
  },
  {
    id: 'joe-bnb-wavax',
    name: 'BNB-AVAX LP',
    token: 'BNB-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xeb8eB6300c53C3AddBb7382Ff6c6FbC4165B0742',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeBNB-WAVAX',
    earnedTokenAddress: '0x2b7066770EF90eb7faCFB6cbe6A975C91FA13151',
    earnContractAddress: '0x2b7066770EF90eb7faCFB6cbe6A975C91FA13151',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-bnb-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['BNB', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/0x264c1383ea520f73dd837f915ef3a732e204a493/AVAX',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1632232139,
  },
  {
    id: 'joe-usdc.e-usdt.e-eol',
    name: 'USDC.e-USDT.e LP',
    token: 'USDC.e-USDT.e JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x2E02539203256c83c7a9F6fA6f8608A32A2b1Ca2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeUSDC.e-USDT.e',
    earnedTokenAddress: '0xE37CeD3b5FBeDb523a48eB4eA3c3e13b2092352a',
    earnContractAddress: '0xE37CeD3b5FBeDb523a48eB4eA3c3e13b2092352a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-usdc.e-usdt.e',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Trader Joe',
    assets: ['USDCe', 'USDTe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664/0xc7198437980c041c805a1edcba50c1ce5db95118',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1632154081,
  },
  {
    id: 'joe-usdt.e-dai.e-eol',
    name: 'USDT.e-DAI.e LP',
    token: 'USDT.e-DAI.e JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xa6908C7E3Be8F4Cd2eB704B5cB73583eBF56Ee62',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeUSDT.e-DAI.e',
    earnedTokenAddress: '0x95D11A45B0B55F96D75f70d63EBf95a824cD836D',
    earnContractAddress: '0x95D11A45B0B55F96D75f70d63EBf95a824cD836D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-usdt.e-dai.e',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Trader Joe',
    assets: ['USDTe', 'DAIe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/0xc7198437980c041c805a1edcba50c1ce5db95118/0xd586e7f844cea2f87f50152665bcbc2c279d8d70',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1632133345,
  },
  {
    id: 'joe-wavax-usdt.e-eol',
    name: 'USDT.e-AVAX LP',
    token: 'USDT.e-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xeD8CBD9F0cE3C6986b22002F03c6475CEb7a6256',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeWAVAX-USDT.e',
    earnedTokenAddress: '0xc1e960785292E68a027FC88042e3ec1A5A064B3C',
    earnContractAddress: '0xc1e960785292E68a027FC88042e3ec1A5A064B3C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-usdt.e',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Trader Joe',
    assets: ['USDTe', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/AVAX/0xc7198437980c041c805a1edcba50c1ce5db95118',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1632129313,
  },
  {
    id: 'joe-link.e-wavax',
    name: 'LINK.e-AVAX LP',
    token: 'LINK.e-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x6F3a0C89f611Ef5dC9d96650324ac633D02265D3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeLINK.e-WAVAX',
    earnedTokenAddress: '0x7cB719CD18E171c3db4607536804f34A01AE506F',
    earnContractAddress: '0x7cB719CD18E171c3db4607536804f34A01AE506F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-link.e-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['LINKe', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/0x5947bb275c521040051d82396192181b413227a3/AVAX',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1632125698,
  },
  {
    id: 'joe-wavax-spell-eol',
    name: 'SPELL-AVAX LP',
    token: 'SPELL-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x62cf16BF2BC053E7102E2AC1DEE5029b94008d99',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeSPELL-WAVAX',
    earnedTokenAddress: '0x3E25CcCD4aC11d4F417b992285188714E80BAE8C',
    earnContractAddress: '0x3E25CcCD4aC11d4F417b992285188714E80BAE8C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-spell',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Trader Joe',
    assets: ['SPELL', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7/0xCE1bFFBD5374Dac86a2893119683F4911a2F7814',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1631896399,
  },
  {
    id: 'joe-frax-wavax-eol',
    name: 'FRAX-AVAX LP',
    token: 'FRAX-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x0d84595e8638dBc631076c51000B2d31120D8aa1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeFRAX-WAVAX',
    earnedTokenAddress: '0xdAfD1803956c0eb7D85d475eb10D69Baa207C72d',
    earnContractAddress: '0xdAfD1803956c0eb7D85d475eb10D69Baa207C72d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-frax-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Trader Joe',
    assets: ['FRAX', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7/0xDC42728B0eA910349ed3c6e1c9Dc06b5FB591f98',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1631635903,
  },
  {
    id: 'joe-time-wavax-eol',
    name: 'TIME-AVAX LP',
    token: 'TIME-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xf64e1c5B6E17031f5504481Ac8145F4c3eab4917',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeTIME-AVAX',
    earnedTokenAddress: '0x4502e2F6802D48578f76920e5D56557459C04B7D',
    earnContractAddress: '0x4502e2F6802D48578f76920e5D56557459C04B7D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-time-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Trader Joe',
    assets: ['TIME', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7/0xb54f16fB19478766A268F172C9480f8da1a7c9C3',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1631633988,
  },
  {
    id: 'pangolin-png-pause-eol',
    logo: 'single-assets/PNG.png',
    name: 'PNG',
    token: 'PNG',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x60781C2586D68229fde47564546784ab3fACA982',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinPNG',
    earnedTokenAddress: '0x670AF270FeE3BbC89e32DDd7B8ec43663A910793',
    earnContractAddress: '0x670AF270FeE3BbC89e32DDd7B8ec43663A910793',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'PNG',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['PNG'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x60781C2586D68229fde47564546784ab3fACA982',
    createdAt: 1631361439,
  },
  {
    id: 'joe-mai-usdc.e-eol',
    name: 'MAI-USDC.e LP',
    token: 'MAI-USDC.e JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x58f75d7745ec383491053947Cd2AE6Ee7fc8B8f8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeMAI-USDC.e',
    earnedTokenAddress: '0xaa5a5AD8a27fEd7F791952705ce90134eac620dc',
    earnContractAddress: '0xaa5a5AD8a27fEd7F791952705ce90134eac620dc',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-mai-usdc.e',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Trader Joe',
    assets: ['miMatic', 'USDCe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/0x3b55e45fd6bd7d4724f5c47e0d1bcaedd059263e/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1631305379,
  },
  {
    id: 'joe-joe-wavax',
    name: 'JOE-AVAX LP',
    token: 'JOE-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x454E67025631C065d3cFAD6d71E6892f74487a15',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeJOE-AVAX',
    earnedTokenAddress: '0x3D81A269E05e6057e4dF9E2D76E254E65a65Eb66',
    earnContractAddress: '0x3D81A269E05e6057e4dF9E2D76E254E65a65Eb66',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-joe-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['JOE', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/AVAX/0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1631119335,
  },
  {
    id: 'joe-joe-usdt.e-eol',
    name: 'JOE-USDT.e LP',
    token: 'JOE-USDT.e JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x1643de2efB8e35374D796297a9f95f64C082a8ce',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeJOE-USDT.e',
    earnedTokenAddress: '0xD0B0B19f2DC29a17175A2afc47b29C6DDd74d3D4',
    earnContractAddress: '0xD0B0B19f2DC29a17175A2afc47b29C6DDd74d3D4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-joe-usdt.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'eol',
    platform: 'Trader Joe',
    assets: ['JOE', 'USDTe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    retireReason: 'rewards',
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/AVAX/0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1631119603,
  },
  {
    id: 'joe-mim-wavax',
    name: 'MIM-AVAX LP',
    token: 'MIM-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x781655d802670bbA3c89aeBaaEa59D3182fD755D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeMIM-WAVAX',
    earnedTokenAddress: '0xb387376366C479Ac1d97833c10cFe738CC786dd1',
    earnContractAddress: '0xb387376366C479Ac1d97833c10cFe738CC786dd1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-mim-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['MIM', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/AVAX/0x130966628846BFd36ff31a822705796e8cb8C18D',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1630670273,
  },
  {
    id: 'joe-qi-wavax-eol',
    name: 'QI-AVAX LP',
    token: 'QI-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x2774516897AC629aD3ED9dCac7e375Dda78412b9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeQI-WAVAX',
    earnedTokenAddress: '0x1CA786F754D339797BBecE959799fBA063C22F44',
    earnContractAddress: '0x1CA786F754D339797BBecE959799fBA063C22F44',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-qi-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Trader Joe',
    assets: ['aQI', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/AVAX/0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1630670133,
  },
  {
    id: 'joe-syn-wavax-eol',
    name: 'SYN-AVAX LP',
    token: 'SYN-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x20ABdC20758990b6afc90dA2f2D30CD0aa3F73c6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeSYN-WAVAX',
    earnedTokenAddress: '0x9b50B06B81f033ca86D70F0a44F30BD7E0155737',
    earnContractAddress: '0x9b50B06B81f033ca86D70F0a44F30BD7E0155737',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-syn-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Trader Joe',
    assets: ['SYN', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/AVAX/0x1f1E7c893855525b303f99bDF5c3c05Be09ca251',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1630670690,
  },
  {
    id: 'joe-wbtc.e-usdt.e-eol',
    name: 'WBTC.e-USDT.e LP',
    token: 'WBTC.e-USDT.e JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xB8D5E8a9247db183847c7D79af9C67F6aeF759f7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeUSDT.e-WBTC.e',
    earnedTokenAddress: '0x3f5E367AC9Fb78A21Dc83d96dc1477eDE1F6219D',
    earnContractAddress: '0x3f5E367AC9Fb78A21Dc83d96dc1477eDE1F6219D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wbtc.e-usdt.e',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Trader Joe',
    assets: ['WBTCe', 'USDTe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/0xc7198437980c041c805A1EDcbA50c1Ce5db95118/0x50b7545627a5162F82A992c33b87aDc75187B218',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1630496447,
  },
  {
    id: 'joe-wbtc.e-wavax',
    name: 'WBTC.e-AVAX LP',
    token: 'WBTC.e-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xd5a37dC5C9A396A03dd1136Fc76A1a02B1c88Ffa',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeWBTC.e-AVAX',
    earnedTokenAddress: '0xf32E23EB10350e381aA8b775d381e27C50a9195f',
    earnContractAddress: '0xf32E23EB10350e381aA8b775d381e27C50a9195f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wbtc.e-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['WBTCe', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/AVAX/0x50b7545627a5162F82A992c33b87aDc75187B218',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1630496650,
  },
  {
    id: 'joe-weth.e-wavax',
    name: 'WETH.e-AVAX LP',
    token: 'WETH.e-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xFE15c2695F1F920da45C30AAE47d11dE51007AF9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeWETH.e-AVAX',
    earnedTokenAddress: '0x68866acc5C940938B373F55F7009f824c7662F5B',
    earnContractAddress: '0x68866acc5C940938B373F55F7009f824c7662F5B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-weth.e-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['WETHe', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/AVAX/0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1630496791,
  },
  {
    id: 'joe-usdc.e-dai.e-eol',
    name: 'USDC.e-DAI.e LP',
    token: 'USDC.e-DAI.e JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x63ABE32d0Ee76C05a11838722A63e012008416E6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeUSDC.e-DAI.e',
    earnedTokenAddress: '0x8EdD09d683bD30baCB0EF6040160997Afe7d36c0',
    earnContractAddress: '0x8EdD09d683bD30baCB0EF6040160997Afe7d36c0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-usdc.e-dai.e',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Trader Joe',
    assets: ['USDCe', 'DAIe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/0xd586E7F844cEa2F87f50152665BCbc2C279D8d70/0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1630085264,
  },
  {
    id: 'joe-usdc.e-wavax-eol',
    name: 'USDC.e-AVAX LP',
    token: 'USDC.e-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xA389f9430876455C36478DeEa9769B7Ca4E3DDB1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeUSDC.e-AVAX',
    earnedTokenAddress: '0xdD63306A9792Ecbd1cd6baED3f1b18BEA638aaCe',
    earnContractAddress: '0xdD63306A9792Ecbd1cd6baED3f1b18BEA638aaCe',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-usdc.e-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Trader Joe',
    assets: ['USDCe', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/AVAX/0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1630085124,
  },
  {
    id: 'joe-wavax-dai.e-eol',
    name: 'DAI.e-AVAX LP',
    token: 'DAI.e-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x87Dee1cC9FFd464B79e058ba20387c1984aed86a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJoeDAI.e-AVAX',
    earnedTokenAddress: '0x71d57De132b3a090BFF5027A1e88947Bed1a32ba',
    earnContractAddress: '0x71d57De132b3a090BFF5027A1e88947Bed1a32ba',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-dai.e',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Trader Joe',
    assets: ['DAIe', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/pool/AVAX/0xd586e7f844cea2f87f50152665bcbc2c279d8d70',
    buyTokenUrl: 'https://www.traderjoexyz.com/trade',
    createdAt: 1630074200,
  },
  {
    id: 'pangolin-usdc.e-wavax-eol',
    name: 'USDC.e-AVAX LP',
    token: 'USDC.e-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xbd918Ed441767fe7924e99F6a0E0B568ac1970D9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinUSDC.e-AVAX',
    earnedTokenAddress: '0x8eb23a3010795574eE3DD101843dC90bD63b5099',
    earnContractAddress: '0x8eb23a3010795574eE3DD101843dC90bD63b5099',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-usdc.e-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['USDCe', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    createdAt: 1630269626,
  },
  {
    id: 'olive-dai.e-usdt.e',
    name: 'DAI.e-USDT.e LP',
    token: 'DAI.e-USDT.e LP',
    tokenDescription: 'OliveSwap',
    tokenAddress: '0x4758441C1F5d067839776456033f6cd0EB9fb3d3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooOliveDAIe-USDTe',
    earnedTokenAddress: '0xabd4a1aDaC20ff42d9946EF5ec5FbF822Ccd5B74',
    earnContractAddress: '0xabd4a1aDaC20ff42d9946EF5ec5FbF822Ccd5B74',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'olive-dai.e-usdt.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'OliveSwap',
    assets: ['DAIe', 'USDTe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://avax.olive.cash/add/0xd586E7F844cEa2F87f50152665BCbc2C279D8d70/0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    buyTokenUrl:
      'https://avax.olive.cash/swap?inputCurrency=0xd586E7F844cEa2F87f50152665BCbc2C279D8d70&outputCurrency=0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    createdAt: 1630057114,
  },
  {
    id: 'olive-busd.e-usdt.e',
    name: 'BUSD.e-USDT.e LP',
    token: 'BUSD.e-USDT.e LP',
    tokenDescription: 'OliveSwap',
    tokenAddress: '0x9e4562caC6b91f5154a42e6270F2Ea58A4149b3E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooOliveBUSDe-USDTe',
    earnedTokenAddress: '0x2913966e88490B5a65D67298801c0c51f377BB9C',
    earnContractAddress: '0x2913966e88490B5a65D67298801c0c51f377BB9C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'olive-busd.e-usdt.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'OliveSwap',
    assets: ['BUSDe', 'USDTe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://avax.olive.cash/add/0x19860CCB0A68fd4213aB9D8266F7bBf05A8dDe98/0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    buyTokenUrl:
      'https://avax.olive.cash/swap?inputCurrency=0x19860CCB0A68fd4213aB9D8266F7bBf05A8dDe98&outputCurrency=0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    createdAt: 1630054596,
  },
  {
    id: 'pangolin-png-qi-eol',
    name: 'QI-PNG LP',
    token: 'QI-PNG LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x50E7e19281a80E3C24a07016eDB87EbA9fe8C6cA',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinQI-PNG',
    earnedTokenAddress: '0xB297EC6eb512d646f558F6514e85aa59cD583a1F',
    earnContractAddress: '0xB297EC6eb512d646f558F6514e85aa59cD583a1F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-png-qi',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['aQI', 'PNG'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/0x60781C2586D68229fde47564546784ab3fACA982/0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5',
    createdAt: 1629466316,
  },
  {
    id: 'pangolin-qi-wavax-eol',
    name: 'QI-AVAX LP',
    token: 'QI-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xE530dC2095Ef5653205CF5ea79F8979a7028065c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinQI-AVAX',
    earnedTokenAddress: '0x052B006A2CfB2523042b3041f15adC7fa5356312',
    earnContractAddress: '0x052B006A2CfB2523042b3041f15adC7fa5356312',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-qi-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['aQI', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5',
    createdAt: 1629466194,
  },
  {
    id: 'pangolin-uni.e-wavax-eol',
    name: 'UNI.e-AVAX LP',
    token: 'UNI.e-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x99dD520748eB0355c69DAE2692E4615C8Ab031ce',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinUNI.e-AVAX',
    earnedTokenAddress: '0x48cc86214C58d7EaA78C100156c55DD45A676Ed1',
    earnContractAddress: '0x48cc86214C58d7EaA78C100156c55DD45A676Ed1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-uni.e-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['UNIe', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0x8eBAf22B6F053dFFeaf46f4Dd9eFA95D89ba8580',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x8eBAf22B6F053dFFeaf46f4Dd9eFA95D89ba8580',
    createdAt: 1629466081,
  },
  {
    id: 'pangolin-aave.e-wavax-eol',
    name: 'AAVE.e-AVAX LP',
    token: 'AAVE.e-AVAXLP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x5944f135e4F1E3fA2E5550d4B5170783868cc4fE',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinAAVE.e-AVAX',
    earnedTokenAddress: '0xd9fe7Ff89C5303E439a14a5155F7F48E34F28518',
    earnContractAddress: '0xd9fe7Ff89C5303E439a14a5155F7F48E34F28518',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-aave.e-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['AAVEe', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0x63a72806098Bd3D9520cC43356dD78afe5D386D9',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x63a72806098Bd3D9520cC43356dD78afe5D386D9',
    createdAt: 1629465789,
  },
  {
    id: 'pangolin-wavax-xava-eol',
    name: 'XAVA-AVAX LP',
    token: 'XAVA-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x42152bDD72dE8d6767FE3B4E17a221D6985E8B25',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinXAVA-AVAX',
    earnedTokenAddress: '0xC556387AEA8B0E55f672edf15FEc3acbcF45dC17',
    earnContractAddress: '0xC556387AEA8B0E55f672edf15FEc3acbcF45dC17',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-wavax-xava',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['XAVA', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0x5947BB275c521040051D82396192181b413227A3',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x5947BB275c521040051D82396192181b413227A3',
    createdAt: 1629305419,
  },
  {
    id: 'pangolin-png-usdt.e-eol',
    name: 'USDT.e-PNG LP',
    token: 'USDT.e-PNG LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x1fFB6ffC629f5D820DCf578409c2d26A2998a140',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinUSDT.e-PNG',
    earnedTokenAddress: '0x97e860CE03ae3da20Ba9740b8dA90036EE891f81',
    earnContractAddress: '0x97e860CE03ae3da20Ba9740b8dA90036EE891f81',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-png-usdt.e',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['USDTe', 'PNG'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/0x60781c2586d68229fde47564546784ab3faca982/0xc7198437980c041c805a1edcba50c1ce5db95118',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    createdAt: 1629306156,
  },
  {
    id: 'pangolin-weth.e-png-eol',
    name: 'WETH.e-PNG LP',
    token: 'WETH.e-PNG LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xcf35400A595EFCF0Af591D3Aeb5a35cBCD120d54',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinWETH.e-PNG',
    earnedTokenAddress: '0xa66DfCBdAfbe4B4D62535f64f1C2Fb50FF42E4C6',
    earnContractAddress: '0xa66DfCBdAfbe4B4D62535f64f1C2Fb50FF42E4C6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-weth.e-png',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['WETHe', 'PNG'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    createdAt: 1629306038,
  },
  {
    id: 'pangolin-wavax-dai.e-eol',
    name: 'DAI.e-AVAX LP',
    token: 'DAI.e-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xbA09679Ab223C6bdaf44D45Ba2d7279959289AB0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinDAI.e-AVAX',
    earnedTokenAddress: '0x97102bAdf07DA7af61594b686fe311d06930B76e',
    earnContractAddress: '0x97102bAdf07DA7af61594b686fe311d06930B76e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-wavax-dai.e',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['DAIe', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
    createdAt: 1629305758,
  },
  {
    id: 'pangolin-link.e-wavax-eol',
    name: 'LINK.e-AVAX LP',
    token: 'LINK.e-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x5875c368Cddd5FB9Bf2f410666ca5aad236DAbD4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinLINK.e-AVAX',
    earnedTokenAddress: '0x2bAe08Cf46867b6c29d6bcA000Dc43dFCCAc73E3',
    earnContractAddress: '0x2bAe08Cf46867b6c29d6bcA000Dc43dFCCAc73E3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-link.e-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['LINKe', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0x5947BB275c521040051D82396192181b413227A3',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x5947BB275c521040051D82396192181b413227A3',
    createdAt: 1629305632,
  },
  {
    id: 'pangolin-weth.e-wavax-eol',
    name: 'WETH.e-AVAX LP',
    token: 'WETH.e-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x7c05d54fc5CB6e4Ad87c6f5db3b807C94bB89c52',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinWETH.e-AVAX',
    earnedTokenAddress: '0xc98fF20bB8cd7C68C483c7C66f36e3F69ad81B47',
    earnContractAddress: '0xc98fF20bB8cd7C68C483c7C66f36e3F69ad81B47',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-weth.e-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['WETHe', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    createdAt: 1629305055,
  },
  {
    id: 'pangolin-wavax-usdt.e-eol',
    name: 'USDT.e-AVAX LP',
    token: 'USDT.e-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xe28984e1EE8D431346D32BeC9Ec800Efb643eef4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinUSDT.e-AVAX',
    earnedTokenAddress: '0xC24c73C256d527726a7a012e1360c78d0cc02eEA',
    earnContractAddress: '0xC24c73C256d527726a7a012e1360c78d0cc02eEA',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-wavax-usdt.e',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['USDTe', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    createdAt: 1629304913,
  },
  {
    id: 'pangolin-wbtc.e-wavax-eol',
    name: 'WBTC.e-AVAX LP',
    token: 'WBTC.e-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x5764b8D8039C6E32f1e5d8DE8Da05DdF974EF5D3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinWBTC.e-AVAX',
    earnedTokenAddress: '0xBBBe0fA93c8ced0614351bAF74979aB1243cF9c8',
    earnContractAddress: '0xBBBe0fA93c8ced0614351bAF74979aB1243cF9c8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pangolin-wbtc.e-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['WBTCe', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0x50b7545627a5162F82A992c33b87aDc75187B218',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x50b7545627a5162F82A992c33b87aDc75187B218',
    createdAt: 1629297701,
  },
  {
    id: 'gondola-usdt-zusdt-eol',
    logo: 'single-assets/USDT.svg',
    name: 'zUSDT-USDT LP',
    token: 'zUSDT-USDT LP',
    tokenDescription: 'Gondola',
    tokenAddress: '0xE586dB7Db75B87A3E84110a73b99960F5f106c6A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooGondolaZUSDT-USDT',
    earnedTokenAddress: '0xEbAE4810Fd13605957e7D90bd49d2fD8F8148923',
    earnContractAddress: '0xEbAE4810Fd13605957e7D90bd49d2fD8F8148923',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'gondola-usdt-zusdt',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['zUSDT', 'USDT'],
    addLiquidityUrl: 'https://app.gondola.finance/#/deposit/usdt',
    buyTokenUrl: 'https://app.gondola.finance/#/swap/usdt',
    createdAt: 1621080551,
  },
  {
    id: 'lyd-lyd-usdt-eol',
    logo: 'usdt-pairs/LYD-USDT.png',
    name: 'LYD-USDT LP',
    token: 'LYD-USDT LP',
    tokenDescription: 'Lydia',
    tokenAddress: '0x752C59f22fAAA861108649F4596034796C69bC3f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooLydiaLYD-USDT',
    earnedTokenAddress: '0xc44d493B6219A7f5C286724b74c158CEBd7fB6f7',
    earnContractAddress: '0xc44d493B6219A7f5C286724b74c158CEBd7fB6f7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'lyd-lyd-usdt',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Lydia',
    assets: ['LYD', 'USDT'],
    addLiquidityUrl:
      'https://www.lydia.finance/#/add/0x4c9b4e1ac6f24cde3660d5e4ef1ebf77c710c084/0xde3a24028580884448a5397872046a019649b084',
    buyTokenUrl:
      'https://exchange.lydia.finance/#/swap?inputCurrency=0x4c9b4e1ac6f24cde3660d5e4ef1ebf77c710c084&outputCurrency=0xde3a24028580884448a5397872046a019649b084',
    createdAt: 1619706954,
  },
  {
    id: 'lyd-lyd-avax-eol',
    logo: 'avax-pairs/LYD-AVAX.png',
    name: 'LYD-AVAX LP',
    token: 'LYD-AVAX LP',
    tokenDescription: 'Lydia',
    tokenAddress: '0xFba4EdaAd3248B03f1a3261ad06Ad846A8e50765',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooLydiaLYD-AVAX',
    earnedTokenAddress: '0x994aB71F95A8de4dAaF6DE3D9862284693fB2bDf',
    earnContractAddress: '0x994aB71F95A8de4dAaF6DE3D9862284693fB2bDf',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'lyd-lyd-avax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'Lydia',
    assets: ['LYD', 'AVAX'],
    addLiquidityUrl:
      'https://www.lydia.finance/#/add/0x4c9b4e1ac6f24cde3660d5e4ef1ebf77c710c084/0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    buyTokenUrl:
      'https://exchange.lydia.finance/#/swap?inputCurrency=0x4c9b4e1ac6f24cde3660d5e4ef1ebf77c710c084&outputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    createdAt: 1619706922,
  },
  {
    id: 'olive-olive-avax-eol',
    logo: 'avax-pairs/OLIVE-AVAX.png',
    name: 'OLIVE-AVAX LP',
    token: 'OLIVE-AVAX LP',
    tokenDescription: 'OliveSwap',
    tokenAddress: '0x57cc32Cd7F5a531953E9af25e1C9394093428082',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooOliveOLIVE-AVAX',
    earnedTokenAddress: '0x9Bb9B11917e7bC3528B6048E9B01cD5E302bb27B',
    earnContractAddress: '0x9Bb9B11917e7bC3528B6048E9B01cD5E302bb27B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'olive-olive-avax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'OliveSwap',
    assets: ['OLIVE', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://avax.olive.cash/add/AVAX/0x617724974218A18769020A70162165A539c07E8a',
    buyTokenUrl:
      'https://avax.olive.cash/swap?inputCurrency=AVAX&outputCurrency=0x617724974218A18769020A70162165A539c07E8a',
    createdAt: 1617865515,
    retiredReason: 'tvl',
  },
  {
    id: 'olive-olive-usdt-eol',
    logo: 'avax-pairs/OLIVE-USDT.png',
    name: 'OLIVE-USDT LP',
    token: 'OLIVE-USDT LP',
    tokenDescription: 'OliveSwap',
    tokenAddress: '0xF54a719215622f602FCA5BF5a6509734C3574a4c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooOliveOLIVE-USDT',
    earnedTokenAddress: '0xBdA8bC79705BC60226adCA2766e94Eb5512949a3',
    earnContractAddress: '0xBdA8bC79705BC60226adCA2766e94Eb5512949a3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'olive-olive-usdt',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'OliveSwap',
    assets: ['OLIVE', 'USDT'],
    addLiquidityUrl:
      'https://swap.olive.cash/#/add/0xde3A24028580884448a5397872046a019649b084/0x617724974218A18769020A70162165A539c07E8a',
    buyTokenUrl:
      'https://swap.olive.cash/#/swap?outputCurrency=0x617724974218A18769020A70162165A539c07E8a',
    createdAt: 1617867390,
  },
  {
    id: 'snob-3pool-eol',
    logo: 'avax-pairs/SNOB3P.png',
    name: 'USDT/BUSD/DAI',
    token: 'Snob 3Pool',
    tokenDescription: 'Snowball',
    tokenAddress: '0xdE1A11C331a0E45B9BA8FeE04D4B51A745f1e4A4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSnob3Pool',
    earnedTokenAddress: '0x0a350c62f4b8C7dA93fBca469e53a182b5BBD044',
    earnContractAddress: '0x0a350c62f4b8C7dA93fBca469e53a182b5BBD044',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'snob-3pool',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['USDT', 'BUSD', 'DAI'],
    addLiquidityUrl: 'https://snowball.network/stablevault/',
    createdAt: 1617723074,
  },
  {
    id: 'com-eth-avax-eol',
    logo: 'avax-pairs/ETH-AVAX.png',
    name: 'ETH-AVAX LP',
    token: 'ETH-AVAX CLP',
    tokenDescription: 'Complus',
    tokenAddress: '0x0CA373D27CE17C4804D108afEee0A77EfEb33775',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComplusETH-AVAX',
    earnedTokenAddress: '0x0c89Ca08b6831e6b81f9f969F37A966a2C44d3d1',
    earnContractAddress: '0x0c89Ca08b6831e6b81f9f969F37A966a2C44d3d1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'com-eth-avax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['ETH', 'AVAX'],
    addLiquidityUrl:
      'https://avadex.complus.exchange/#/add/AVAX/0xf20d962a6c8f70c731bd838a3a388D7d48fA6e15',
    buyTokenUrl:
      'https://avadex.complus.exchange/#/swap?inputCurrency=0xf20d962a6c8f70c731bd838a3a388D7d48fA6e15&outputCurrency=AVAX',
    createdAt: 1616228400,
  },
  {
    id: 'com-png-avax-eol',
    logo: 'avax-pairs/PNG-AVAX.png',
    name: 'PNG-AVAX LP',
    token: 'PNG-AVAX CLP',
    tokenDescription: 'Complus',
    tokenAddress: '0xEcee953e187d9e82D57bAedAaEF1e56E5283f5C3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComplusPNG-AVAX',
    earnedTokenAddress: '0x7076a33b6525132fF77F0FeE2daB2a1e79688DA0',
    earnContractAddress: '0x7076a33b6525132fF77F0FeE2daB2a1e79688DA0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'com-png-avax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['PNG', 'AVAX'],
    addLiquidityUrl:
      'https://avadex.complus.exchange/#/add/AVAX/0x60781C2586D68229fde47564546784ab3fACA982',
    buyTokenUrl:
      'https://avadex.complus.exchange/#/swap?inputCurrency=0x60781C2586D68229fde47564546784ab3fACA982&outputCurrency=AVAX',
    createdAt: 1616228454,
  },
  {
    id: 'snob-snob-avax-eol',
    logo: 'avax-pairs/SNOB-AVAX.svg',
    name: 'SNOB-AVAX LP',
    token: 'SNOB-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xa1C2c3B6b120cBd4Cec7D2371FFd4a931A134A32',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSnowballSNOB-AVAX',
    earnedTokenAddress: '0x114c5f7f42fB75b7960aa3e4c327f53288360F58',
    earnContractAddress: '0x114c5f7f42fB75b7960aa3e4c327f53288360F58',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'snob-snob-avax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['SNOB', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xC38f41A296A4493Ff429F1238e030924A1542e50',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xC38f41A296A4493Ff429F1238e030924A1542e50',
    createdAt: 1616082970,
  },
  {
    id: 'com-com-avax-eol',
    logo: 'avax-pairs/COM-AVAX.png',
    name: 'COM-AVAX LP',
    token: 'COM-AVAX LP',
    tokenDescription: 'Complus',
    tokenAddress: '0xF0ED25fD26E0b64C86c6c78b661F2ef283E9B6FF',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComplusCOM-AVAX',
    earnedTokenAddress: '0xA43d8f6Db69610C8260B953658553cabF01D77c6',
    earnContractAddress: '0xA43d8f6Db69610C8260B953658553cabF01D77c6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'com-com-avax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['COM', 'AVAX'],
    retireReason: 'tvl',
    addLiquidityUrl:
      'https://avadex.complus.exchange/#/add/AVAX/0x3711c397B6c8F7173391361e27e67d72F252cAad',
    buyTokenUrl:
      'https://avadex.complus.exchange/#/swap?inputCurrency=0x3711c397B6c8F7173391361e27e67d72F252cAad&outputCurrency=AVAX',
    createdAt: 1616082096,
  },
  {
    id: 'png-sushi-avax-eol',
    name: 'SUSHI-AVAX LP',
    token: 'SUSHI-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xd8B262C0676E13100B33590F10564b46eeF652AD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinSUSHI-AVAX',
    earnedTokenAddress: '0x3094Ab4Af54f5208B867125B5CCeCc94Bc17cbB6',
    earnContractAddress: '0x3094Ab4Af54f5208B867125B5CCeCc94Bc17cbB6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-sushi-avax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['SUSHI', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0x39cf1BD5f15fb22eC3D9Ff86b0727aFc203427cc',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x39cf1BD5f15fb22eC3D9Ff86b0727aFc203427cc',
    createdAt: 1615919277,
  },
  {
    id: 'png-wbtc-avax-eol',
    name: 'WBTC-AVAX LP',
    token: 'WBTC-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x7a6131110B82dAcBb5872C7D352BfE071eA6A17C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinWBTC-AVAX',
    earnedTokenAddress: '0xd5ab3Fac6200B0D8e8d76daED62793026118A78c',
    earnContractAddress: '0xd5ab3Fac6200B0D8e8d76daED62793026118A78c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-wbtc-avax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['WBTC', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0x408D4cD0ADb7ceBd1F1A1C33A0Ba2098E1295bAB',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x408D4cD0ADb7ceBd1F1A1C33A0Ba2098E1295bAB',
    createdAt: 1615917865,
  },
  {
    id: 'png-link-avax-eol',
    name: 'LINK-AVAX LP',
    token: 'LINK-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xbbC7fFF833D27264AaC8806389E02F717A5506c9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinLINK-AVAX',
    earnedTokenAddress: '0x6571052b2FB67DF6DD003ED6ed371098A030Eb0d',
    earnContractAddress: '0x6571052b2FB67DF6DD003ED6ed371098A030Eb0d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-link-avax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['LINK', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xB3fe5374F67D7a22886A0eE082b2E2f9d2651651',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xB3fe5374F67D7a22886A0eE082b2E2f9d2651651',
    createdAt: 1615919058,
  },
  {
    id: 'png-uni-avax-eol',
    name: 'UNI-AVAX LP',
    token: 'UNI-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x92dC558cB9f8d0473391283EaD77b79b416877cA',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinUNI-AVAX',
    earnedTokenAddress: '0x17657955D954bD7F7315C388D7099af7B0b851FA',
    earnContractAddress: '0x17657955D954bD7F7315C388D7099af7B0b851FA',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-uni-avax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['UNI', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xf39f9671906d8630812f9d9863bBEf5D523c84Ab',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xf39f9671906d8630812f9d9863bBEf5D523c84Ab',
    createdAt: 1615919484,
  },
  {
    id: 'png-usdt-png-eol',
    name: 'USDT-PNG LP',
    token: 'USDT-PNG LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xE8AcF438B10A2C09f80aEf3Ef2858F8E758C98F9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinUSDT-PNG',
    earnedTokenAddress: '0x044e87f30bd9bD961c04028aC69155493E1b9eD0',
    earnContractAddress: '0x044e87f30bd9bD961c04028aC69155493E1b9eD0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-usdt-png',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['USDT', 'PNG'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/0x60781C2586D68229fde47564546784ab3fACA982/0xde3A24028580884448a5397872046a019649b084',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xde3A24028580884448a5397872046a019649b084',
    createdAt: 1615919670,
  },
  {
    id: 'png-eth-avax-eol',
    name: 'ETH-AVAX LP',
    token: 'ETH-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x1aCf1583bEBdCA21C8025E172D8E8f2817343d65',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinETH-AVAX',
    earnedTokenAddress: '0xDA875A511860f2752B891677489d08CaEDac00EA',
    earnContractAddress: '0xDA875A511860f2752B891677489d08CaEDac00EA',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-eth-avax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['ETH', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xf20d962a6c8f70c731bd838a3a388D7d48fA6e15',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xf20d962a6c8f70c731bd838a3a388D7d48fA6e15',
    createdAt: 1615742564,
  },
  {
    id: 'png-usdt-avax-eol',
    name: 'USDT-AVAX LP',
    token: 'USDT-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0x9EE0a4E21bd333a6bb2ab298194320b8DaA26516',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinUSDT-AVAX',
    earnedTokenAddress: '0x7a670e849DB824364d1031DEAfB4cD603144F23D',
    earnContractAddress: '0x7a670e849DB824364d1031DEAfB4cD603144F23D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-usdt-avax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['USDT', 'AVAX'],
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0xde3A24028580884448a5397872046a019649b084',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xde3A24028580884448a5397872046a019649b084',
    createdAt: 1615742703,
  },
  {
    id: 'png-png-avax-eol',
    name: 'PNG-AVAX LP',
    token: 'PNG-AVAX LP',
    tokenDescription: 'Pangolin',
    tokenAddress: '0xd7538cABBf8605BdE1f4901B47B8D42c61DE0367',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPangolinPNG-AVAX',
    earnedTokenAddress: '0x71b5852857b85D5096d4288AD6d293F217d8e162',
    earnContractAddress: '0x71b5852857b85D5096d4288AD6d293F217d8e162',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'png-png-avax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Pangolin',
    assets: ['PNG', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.pangolin.exchange/#/add/AVAX/0x60781C2586D68229fde47564546784ab3fACA982',
    buyTokenUrl:
      'https://app.pangolin.exchange/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x60781C2586D68229fde47564546784ab3fACA982',
    createdAt: 1615742387,
  },
];
