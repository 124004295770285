export const fantomPools = [
  {
    id: 'fantom-bifi-maxi',
    logo: 'single-assets/BIFI.png',
    name: 'BIFI Maxi',
    token: 'BIFI',
    tokenDescription: 'Beefy',
    tokenAddress: '0xd6070ae98b8069de6B494332d1A1a81B6179D960',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooFantomBIFI',
    earnedTokenAddress: '0xbF07093ccd6adFC3dEB259C557b61E94c1F66945',
    earnContractAddress: '0xbF07093ccd6adFC3dEB259C557b61E94c1F66945',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BIFI',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beefy',
    assets: ['BIFI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Maxi',
    withdrawalFee: '0.05%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0xd6070ae98b8069de6B494332d1A1a81B6179D960',
    createdAt: 1622574935,
  },
  {
    id: 'beefy-beFTM',
    logo: 'single-assets/beFTM.svg',
    name: 'beFTM',
    token: 'beFTM',
    tokenDescription: 'Beefy',
    tokenAddress: '0x7381eD41F6dE418DdE5e84B55590422a57917886',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'moobeFTM',
    earnedTokenAddress: '0x185647c55633A5706aAA3278132537565c925078',
    earnContractAddress: '0x185647c55633A5706aAA3278132537565c925078',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'beFTM',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beefy',
    assets: ['beFTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0.01%',
    mintTokenUrl: 'https://beta.beefy.finance/#/fantom/vault/beefy-beFTM',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x7381eD41F6dE418DdE5e84B55590422a57917886',
    createdAt: 1646162527,
  },
  {
    id: 'curve-ftm-geist',
    logo: 'uncategorized/3CRV.png',
    name: 'fUSDT/DAI/USDC',
    token: 'g3CRV',
    tokenDescription: 'Curve',
    tokenAddress: '0xD02a30d33153877BC20e5721ee53DeDEE0422B2F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCurveG3CRV',
    earnedTokenAddress: '0xf710D90A1Bd702dAEa619eEbbe876e7085c2A1df',
    earnContractAddress: '0xf710D90A1Bd702dAEa619eEbbe876e7085c2A1df',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-ftm-geist',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['fUSDT', 'USDC', 'DAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://ftm.curve.fi/geist/deposit',
    createdAt: 1653563943,
  },
  {
    id: 'tomb-ftm-dai',
    name: 'DAI-FTM LP',
    token: 'DAI-FTM TLP',
    tokenDescription: 'TombSwap',
    tokenAddress: '0xB89486a030075B42d589008Da7877dd783Af968F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombFTM-DAI',
    earnedTokenAddress: '0x51Ff746981ed53fc9e6B8651D0ccCA02DAC96F11',
    earnContractAddress: '0x51Ff746981ed53fc9e6B8651D0ccCA02DAC96F11',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-ftm-dai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Tomb',
    assets: ['DAI', 'FTM'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_HIGH', 'MCAP_MEDIUM', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://swap.tomb.com/#/add/ETH/0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    buyTokenUrl:
      'https://swap.tomb.com/#/swap?inputCurrency=ETH&outputCurrency=0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    createdAt: 1654074996,
  },
  {
    id: 'tomb-ftm-eth',
    name: 'ETH-FTM LP',
    token: 'ETH-FTM TLP',
    tokenDescription: 'TombSwap',
    tokenAddress: '0x8E49C8fBF6128356019D8A7d34E9b92f03bc2803',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombFTM-ETH',
    earnedTokenAddress: '0xB41a14B5AA644BE3A78a54d7f12c405cF004C758',
    earnContractAddress: '0xB41a14B5AA644BE3A78a54d7f12c405cF004C758',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-ftm-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Tomb',
    assets: ['ETH', 'FTM'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_HIGH', 'MCAP_MEDIUM', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://swap.tomb.com/#/add/ETH/0x74b23882a30290451A17c44f4F05243b6b58C76d',
    buyTokenUrl:
      'https://swap.tomb.com/#/swap?inputCurrency=ETH&outputCurrency=0x74b23882a30290451A17c44f4F05243b6b58C76d',
    createdAt: 1654075223,
  },
  {
    id: 'tomb-ftm-fusdt',
    name: 'fUSDT-FTM LP',
    token: 'fUSDT-FTM TLP',
    tokenDescription: 'TombSwap',
    tokenAddress: '0x681d32C8b374c2Dd83064775dBB48EA97db2c506',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombFTM-fUSDT',
    earnedTokenAddress: '0x0d367827F705E893C0e1ab0DfC800104b186579e',
    earnContractAddress: '0x0d367827F705E893C0e1ab0DfC800104b186579e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-ftm-fusdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Tomb',
    assets: ['fUSDT', 'FTM'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_HIGH', 'MCAP_MICRO', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://swap.tomb.com/#/add/ETH/0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    buyTokenUrl:
      'https://swap.tomb.com/#/swap?inputCurrency=ETH&outputCurrency=0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    createdAt: 1654075348,
  },
  {
    id: 'tomb-ftm-btc',
    name: 'BTC-FTM LP',
    token: 'BTC-FTM TLP',
    tokenDescription: 'TombSwap',
    tokenAddress: '0x5063C79e377332FB98CB6C8DB414d752DC7C478E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombFTM-BTC',
    earnedTokenAddress: '0xAdB792870c3F761301Ff23BC9Fb7fEABd451c212',
    earnContractAddress: '0xAdB792870c3F761301Ff23BC9Fb7fEABd451c212',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-ftm-btc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Tomb',
    assets: ['BTC', 'FTM'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_HIGH', 'MCAP_MEDIUM', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://swap.tomb.com/#/add/ETH/0x321162Cd933E2Be498Cd2267a90534A804051b11',
    buyTokenUrl:
      'https://swap.tomb.com/#/swap?inputCurrency=ETH&outputCurrency=0x321162Cd933E2Be498Cd2267a90534A804051b11',
    createdAt: 1654075440,
  },
  {
    id: 'tomb-ftm-mim',
    name: 'MIM-FTM LP',
    token: 'FTM-MIM TLP',
    tokenDescription: 'TombSwap',
    tokenAddress: '0xa7c86Fc1B87830b8aBFA623571405E03560a8326',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombFTM-MIM',
    earnedTokenAddress: '0x5Bd971AB8c4dC2bAC0Db6d5dDD9ab1BD8FaC1067',
    earnContractAddress: '0x5Bd971AB8c4dC2bAC0Db6d5dDD9ab1BD8FaC1067',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-ftm-mim',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Tomb',
    assets: ['FTM', 'MIM'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_HIGH', 'MCAP_MEDIUM', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://swap.tomb.com/#/add/ETH/0x82f0B8B456c1A451378467398982d4834b6829c1',
    buyTokenUrl:
      'https://swap.tomb.com/#/swap?inputCurrency=ETH&outputCurrency=0x82f0B8B456c1A451378467398982d4834b6829c1',
    createdAt: 1654075665,
  },
  {
    id: 'tomb-btc-eth',
    name: 'BTC-ETH LP',
    token: 'BTC-ETH TLP',
    tokenDescription: 'TombSwap',
    tokenAddress: '0x3f468804d133894a73b54cfc07D5886E5195255f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombBTC-ETH',
    earnedTokenAddress: '0xd23486489e3fB6F461107B7422c625c2c3B77fEf',
    earnContractAddress: '0xd23486489e3fB6F461107B7422c625c2c3B77fEf',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-btc-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Tomb',
    assets: ['BTC', 'ETH'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'MCAP_LARGE', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.tomb.com/#/add/0x321162Cd933E2Be498Cd2267a90534A804051b11/0x74b23882a30290451A17c44f4F05243b6b58C76d',
    buyTokenUrl:
      'https://swap.tomb.com/#/swap?inputCurrency=0x321162Cd933E2Be498Cd2267a90534A804051b11&outputCurrency=0x74b23882a30290451A17c44f4F05243b6b58C76d',
    createdAt: 1654075781,
  },
  {
    id: 'tomb-treeb-usdc',
    name: 'TREEB-USDC LP',
    token: 'TREEB-USDC TLP',
    tokenDescription: 'TombSwap',
    tokenAddress: '0x37fb87347BCAd93B12Fd4E43BCf07620d6387A92',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombTREEB-USDC',
    earnedTokenAddress: '0xE0c2D6349C7C108c2AD14E7e7cCd53E15264D143',
    earnContractAddress: '0xE0c2D6349C7C108c2AD14E7e7cCd53E15264D143',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-treeb-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Tomb',
    assets: ['TREEB', 'USDC'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_HIGH', 'MCAP_MICRO', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.tomb.com/#/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0xc60D7067dfBc6f2caf30523a064f416A5Af52963',
    buyTokenUrl:
      'https://swap.tomb.com/#/swap?inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&outputCurrency=0xc60D7067dfBc6f2caf30523a064f416A5Af52963',
    createdAt: 1653831633,
  },
  {
    id: 'tomb-fusd-usdc',
    name: 'FUSD-USDC LP',
    token: 'FUSD-USDC TLP',
    tokenDescription: 'TombSwap',
    tokenAddress: '0x38fF5377A42D0A45C829de45801481869087d22C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombFUSD-USDC',
    earnedTokenAddress: '0xF9Ec7e648a50806Ec3Cd21415bebAB3c942Ee01b',
    earnContractAddress: '0xF9Ec7e648a50806Ec3Cd21415bebAB3c942Ee01b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-fusd-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Tomb',
    assets: ['FUSD', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'CONTRACTS_VERIFIED',
      'ALGO_STABLE',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.tomb.com/#/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0xAd84341756Bf337f5a0164515b1f6F993D194E1f',
    buyTokenUrl:
      'https://swap.tomb.com/#/swap?inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&outputCurrency=0xAd84341756Bf337f5a0164515b1f6F993D194E1f',
    createdAt: 1653470845,
  },
  {
    id: 'tomb-tomb-treeb',
    name: 'TOMB-TREEB LP',
    token: 'TOMB-TREEB TLP',
    tokenDescription: 'TombSwap',
    tokenAddress: '0x801D17c21D0808Bc00D46E2f081214c9d82F4FbF',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombTOMB-TREEB',
    earnedTokenAddress: '0x6b9de11ACf846d961E0b7AF3B0B5066DDBED5eA1',
    earnContractAddress: '0x6b9de11ACf846d961E0b7AF3B0B5066DDBED5eA1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-tomb-treeb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Tomb',
    assets: ['TOMB', 'TREEB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'CONTRACTS_VERIFIED',
      'ALGO_STABLE',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.tomb.com/#/add/0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7/0xc60D7067dfBc6f2caf30523a064f416A5Af52963',
    buyTokenUrl:
      'https://swap.tomb.com/#/swap?inputCurrency=0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7&outputCurrency=0xc60D7067dfBc6f2caf30523a064f416A5Af52963',
    createdAt: 1653470692,
  },
  {
    id: 'tomb-tomb-zoo',
    name: 'TOMB-ZOO LP',
    token: 'TOMB-ZOO TLP',
    tokenDescription: 'TombSwap',
    tokenAddress: '0x67019E7B4A233cc2E875e5c713042333d879aaCE',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombTOMB-ZOO',
    earnedTokenAddress: '0x1A12C7F1413E261E21b48DB105D0f3bd904ceD58',
    earnContractAddress: '0x1A12C7F1413E261E21b48DB105D0f3bd904ceD58',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-tomb-zoo',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Tomb',
    assets: ['TOMB', 'ZOO'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'CONTRACTS_VERIFIED',
      'ALGO_STABLE',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.tomb.com/#/add/0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7/0x09e145A1D53c0045F41aEEf25D8ff982ae74dD56',
    buyTokenUrl:
      'https://swap.tomb.com/#/swap?inputCurrency=0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7&outputCurrency=0x09e145A1D53c0045F41aEEf25D8ff982ae74dD56',
    createdAt: 1653470478,
  },
  {
    id: 'tomb-tomb-scream',
    name: 'TOMB-SCREAM LP',
    token: 'TOMB-SCREAM TLP',
    tokenDescription: 'TombSwap',
    tokenAddress: '0x99680A9a70EdB9B2C2a7d2B293226d3a033074F8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombTOMB-SCREAM',
    earnedTokenAddress: '0xdeFc374Cb3F9E00a65241C89F9f0eed0B399BF6c',
    earnContractAddress: '0xdeFc374Cb3F9E00a65241C89F9f0eed0B399BF6c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-tomb-scream',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Tomb',
    assets: ['TOMB', 'SCREAM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'CONTRACTS_VERIFIED',
      'ALGO_STABLE',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.tomb.com/#/add/0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7/0xe0654C8e6fd4D733349ac7E09f6f23DA256bF475',
    buyTokenUrl:
      'https://swap.tomb.com/#/swap?inputCurrency=0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7&outputCurrency=0xe0654C8e6fd4D733349ac7E09f6f23DA256bF475',
    createdAt: 1653470613,
  },
  {
    id: 'spirit-wftm-midas',
    name: 'MIDAS-FTM LP',
    token: 'MIDAS-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0xede32b76302CB71cc0467C4B42DAbFfa6b091Dd1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritWFTM-MIDAS',
    earnedTokenAddress: '0x826B9d7Aaa2C597ec06efE4f0753C4D7A57b5eBF',
    earnContractAddress: '0x826B9d7Aaa2C597ec06efE4f0753C4D7A57b5eBF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-wftm-midas',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['MIDAS', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0xb37528DA6b4D378305d000a66Ad91bd88E626761',
    buyTokenUrl: 'https://swap.spiritswap.finance/#/exchange/swap/FTM/MIDAS',
    createdAt: 1653405420,
  },
  {
    id: 'hector-tor-crv',
    logo: 'fantom/TOR-2CRV.svg',
    name: 'TOR/DAI/USDC',
    token: 'TOR-2CRV',
    tokenDescription: 'Curve (Hector)',
    tokenAddress: '0x24699312CB27C26Cfc669459D670559E5E44EE60',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooHectorTOR-2CRV',
    earnedTokenAddress: '0x8B92DE822b121761a3caF894627a09a9f87864C0',
    earnContractAddress: '0x8B92DE822b121761a3caF894627a09a9f87864C0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'hector-tor-crv',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Hector',
    assets: ['TOR', 'DAI', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'NEW_STRAT',
      'MCAP_MICRO',
      'AUDIT',
      'IL_NONE',
      'CONTRACTS_VERIFIED',
      'ALGO_STABLE',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://app.hector.finance/#/farming',
    buyTokenUrl: 'https://app.hector.finance/#/dex',
    createdAt: 1650149559,
  },
  {
    id: 'boo-wftm-tor',
    name: 'TOR-FTM LP',
    token: 'TOR-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x41d88635029c4402BF9914782aE55c412f8F2142',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpookyTOR-FTM',
    earnedTokenAddress: '0xf723aE5478B1F03cA88C204F1ae5498d3576B78F',
    earnContractAddress: '0xf723aE5478B1F03cA88C204F1ae5498d3576B78F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-tor',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['TOR', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'ALGO_STABLE',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x74E23dF9110Aa9eA0b6ff2fAEE01e740CA1c642e',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x74E23dF9110Aa9eA0b6ff2fAEE01e740CA1c642e',
    createdAt: 1651814013,
  },
  {
    id: 'based-based-usdc',
    name: 'BASED-USDC LP',
    token: 'BASED-USDC TLP',
    tokenDescription: 'TombSwap (Based)',
    tokenAddress: '0x7c849a7E2cb08f09cf37482cc0A04ecB5891844a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBasedBASED-USDC',
    earnedTokenAddress: '0x4cb0dd253ae27Ff7231372424bC988c711a61783',
    earnContractAddress: '0x4cb0dd253ae27Ff7231372424bC988c711a61783',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'based-based-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Tomb',
    assets: ['BASED', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'CONTRACTS_VERIFIED',
      'ALGO_STABLE',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.tomb.com/#/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae',
    buyTokenUrl:
      'https://swap.tomb.com/#/swap?inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&outputCurrency=0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae',
    createdAt: 1653896828,
  },
  {
    id: 'tomb-usdc-wftm',
    name: 'FTM-USDC LP',
    token: 'FTM-USDC LP',
    tokenDescription: 'TombSwap',
    tokenAddress: '0x8C853ce1561A2c2cD2E857670e3cCd04BA4cB27b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombFTM-USDC',
    earnedTokenAddress: '0x1f69E7B04458cf99B962c26aeCAe87F963F9513F',
    earnContractAddress: '0x1f69E7B04458cf99B962c26aeCAe87F963F9513F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-usdc-wftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Tomb',
    assets: ['FTM', 'USDC'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_HIGH', 'MCAP_LARGE', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.tomb.com/#/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    buyTokenUrl:
      'https://swap.tomb.com/#/swap?inputCurrency=0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83&outputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    createdAt: 1653010779,
  },
  {
    id: 'tomb-usdc-tshare',
    name: 'TSHARE-USDC LP',
    token: 'TSHARE-USDC LP',
    tokenDescription: 'TombSwap',
    tokenAddress: '0xDEc1259188E6c5273AcD1e84d5B4b58897CA013e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombTSHARE-USDC',
    earnedTokenAddress: '0xE1b37d6667c2cA297ce675618ec457C25cfbC174',
    earnContractAddress: '0xE1b37d6667c2cA297ce675618ec457C25cfbC174',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-usdc-tshare',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Tomb',
    assets: ['TSHARE', 'USDC'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_HIGH', 'MCAP_SMALL', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.tomb.com/#/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0x4cdF39285D7Ca8eB3f090fDA0C069ba5F4145B37',
    buyTokenUrl:
      'https://swap.tomb.com/#/swap?inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&outputCurrency=0x4cdF39285D7Ca8eB3f090fDA0C069ba5F4145B37',
    createdAt: 1653012955,
  },
  {
    id: 'tomb-usdc-tomb',
    name: 'TOMB-USDC LP',
    token: 'TOMB-USDC LP',
    tokenDescription: 'TombSwap',
    tokenAddress: '0xAA9BE68D990d5e56870B2E0544f96ffb0B1dA8F7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombTOMB-USDC',
    earnedTokenAddress: '0x3220A0005015f089d583b94aA4B103e6e28E3aE2',
    earnContractAddress: '0x3220A0005015f089d583b94aA4B103e6e28E3aE2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-usdc-tomb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Tomb',
    assets: ['TOMB', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'CONTRACTS_VERIFIED',
      'ALGO_STABLE',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.tomb.com/#/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
    buyTokenUrl:
      'https://swap.tomb.com/#/swap?inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&outputCurrency=0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
    createdAt: 1653013367,
  },
  {
    id: 'tomb-usdc-fusdt',
    name: 'fUSDT-USDC LP',
    token: 'fUSDT-USDC LP',
    tokenDescription: 'TombSwap',
    tokenAddress: '0x3486011E2E18ccf4558c4C84d5cbBcCFdbF16c03',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombFUSDT-USDC',
    earnedTokenAddress: '0x95EA2284111960c748edF4795cb3530e5E423b8c',
    earnContractAddress: '0x95EA2284111960c748edF4795cb3530e5E423b8c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-usdc-fusdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Tomb',
    assets: ['fUSDT', 'USDC'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_NONE', 'MCAP_LARGE', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.tomb.com/#/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    buyTokenUrl:
      'https://swap.tomb.com/#/swap?inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&outputCurrency=0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    createdAt: 1653065311,
  },
  {
    id: 'tomb-usdc-mim',
    name: 'MIM-USDC LP',
    token: 'MIM-USDC LP',
    tokenDescription: 'TombSwap',
    tokenAddress: '0xd840aF68b35469eC3478c9b0CBCDdc6dc80Dd98C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombMIM-USDC',
    earnedTokenAddress: '0xaf5C14D2CE23B5de3c9d7e6F0C4178D35Ffc07D6',
    earnContractAddress: '0xaf5C14D2CE23B5de3c9d7e6F0C4178D35Ffc07D6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-usdc-mim',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Tomb',
    assets: ['MIM', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.tomb.com/#/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0x82f0B8B456c1A451378467398982d4834b6829c1',
    buyTokenUrl:
      'https://swap.tomb.com/#/swap?inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&outputCurrency=0x82f0B8B456c1A451378467398982d4834b6829c1',
    createdAt: 1653013511,
  },
  {
    id: 'tomb-usdc-mai',
    name: 'MAI-USDC LP',
    token: 'MAI-USDC LP',
    tokenDescription: 'TombSwap',
    tokenAddress: '0xEdf6595E2ae78a4e5C8725D7eD2A28AD974f1B8e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombMAI-USDC',
    earnedTokenAddress: '0x76b8b4601A4c30dea22eAAdcc85f87D27052a2BB',
    earnContractAddress: '0x76b8b4601A4c30dea22eAAdcc85f87D27052a2BB',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-usdc-mai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Tomb',
    assets: ['MAI', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.tomb.com/#/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0xfB98B335551a418cD0737375a2ea0ded62Ea213b',
    buyTokenUrl:
      'https://swap.tomb.com/#/swap?inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&outputCurrency=0xfB98B335551a418cD0737375a2ea0ded62Ea213b',
    createdAt: 1653013565,
  },
  {
    id: 'tomb-tshare-weth',
    name: 'TSHARE-ETH LP',
    token: 'TSHARE-ETH LP',
    tokenDescription: 'TombSwap',
    tokenAddress: '0xd702D7495b010936EBc53a1efeE42D97996Ca5Ee',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombTSHARE-ETH',
    earnedTokenAddress: '0x14e57712195D05D1AF02cdCE7d41fa6244C6Eb13',
    earnContractAddress: '0x14e57712195D05D1AF02cdCE7d41fa6244C6Eb13',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-tshare-weth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Tomb',
    assets: ['TSHARE', 'ETH'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_HIGH', 'MCAP_SMALL', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.tomb.com/#/add/0x4cdF39285D7Ca8eB3f090fDA0C069ba5F4145B37/0x74b23882a30290451A17c44f4F05243b6b58C76d',
    buyTokenUrl:
      'https://swap.tomb.com/#/swap?inputCurrency=0x4cdF39285D7Ca8eB3f090fDA0C069ba5F4145B37&outputCurrency=0x74b23882a30290451A17c44f4F05243b6b58C76d',
    createdAt: 1653013670,
  },
  {
    id: 'curve-ftm-f-4pool',
    name: 'UST/FRAX/fUSDT/USDC',
    token: '4POOL-f',
    tokenDescription: 'Curve',
    tokenAddress: '0x9dc516a18775d492c9f061211C8a3FDCd476558d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCurve4pool',
    earnedTokenAddress: '0xf49bB8D23BB48f237CFDfa4Bb67F4B73ccf28A38',
    earnContractAddress: '0xf49bB8D23BB48f237CFDfa4Bb67F4B73ccf28A38',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-ftm-f-4pool',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['UST', 'FRAX', 'fUSDT', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'ALGO_STABLE',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://ftm.curve.fi/factory/84/deposit',
    createdAt: 1651152132,
  },
  {
    id: 'spirit-sftmx-ftm',
    name: 'sFTMx-FTM LP',
    token: 'sFTMx-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x72133BBff5072616E165237e69b3F4c87C1a94e8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritsFTMx-FTM',
    earnedTokenAddress: '0x8Cfc4d6772E5353CE3fB92421e535022a112306D',
    earnContractAddress: '0x8Cfc4d6772E5353CE3fB92421e535022a112306D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-sftmx-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['sFTMx', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0xd7028092c830b5C8FcE061Af2E593413EbbC1fc1',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/FTM/0xd7028092c830b5C8FcE061Af2E593413EbbC1fc1',
    createdAt: 1651141906,
  },
  {
    id: 'tomb-btc-tshare',
    name: 'TSHARE-BTC LP',
    token: 'TSHARE-BTC LP',
    tokenDescription: 'TombSwap',
    tokenAddress: '0xa1f4a9ee0d06115376dFf357D34C3F5eb4107398',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombBTC-TSHARE',
    earnedTokenAddress: '0x366Eb1dC4fDf8273f93a58d0B7EaeD5e4A85f2ed',
    earnContractAddress: '0x366Eb1dC4fDf8273f93a58d0B7EaeD5e4A85f2ed',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-btc-tshare',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Tomb',
    assets: ['TSHARE', 'WBTC'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_HIGH', 'MCAP_MICRO', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.tomb.com/#/add/0x321162Cd933E2Be498Cd2267a90534A804051b11/0x4cdF39285D7Ca8eB3f090fDA0C069ba5F4145B37',
    buyTokenUrl:
      'https://swap.tomb.com/#/swap?inputCurrency=0x321162Cd933E2Be498Cd2267a90534A804051b11&outputCurrency=0x4cdF39285D7Ca8eB3f090fDA0C069ba5F4145B37',
    createdAt: 1651725562,
  },
  {
    id: 'spirit-orkan-ftm',
    name: 'ORKAN-FTM LP',
    token: 'ORKAN-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x5A6880d3e9d715BEf5848c9749cea5F23a982A75',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritORKAN-FTM',
    earnedTokenAddress: '0x878b9c15B7856081D7Bcb3240D9a15fEC7718439',
    earnContractAddress: '0x878b9c15B7856081D7Bcb3240D9a15fEC7718439',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-orkan-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['ORKAN', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0xfB66e49e303A186a4c57414Ceeed651a7a78161a',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/FTM/0xfB66e49e303A186a4c57414Ceeed651a7a78161a',
    createdAt: 1651077919,
  },
  {
    id: 'beets-daiabolical-balance',
    name: 'A Dai-abolical Balance',
    token: 'A Dai-abolical Balance',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0xD5E946b5619fFf054c40D38c976f1d06C1e2fA82',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetDaiabolicalBalance',
    earnedTokenAddress: '0x971423A9A45A1A2DeB0bEe48C92d3859D793e73B',
    earnContractAddress: '0x971423A9A45A1A2DeB0bEe48C92d3859D793e73B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-daiabolical-balance',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beethoven X',
    assets: ['USDB', 'DAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://beets.fi/#/pool/0xd5e946b5619fff054c40d38c976f1d06c1e2fa820002000000000000000003ac',
    createdAt: 1651660702,
  },
  {
    id: 'boo-usdc-dei',
    name: 'DEI-USDC LP',
    token: 'DEI-USDC LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xD343b8361Ce32A9e570C1fC8D4244d32848df88B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooUSDC-DEI',
    earnedTokenAddress: '0xb57FF04862BA9fa1f76436cB68b92A6366Da4669',
    earnContractAddress: '0xb57FF04862BA9fa1f76436cB68b92A6366Da4669',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-usdc-dei',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['DEI', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://spooky.fi/#/add/0xDE12c7959E1a72bbe8a5f7A1dc8f8EeF9Ab011B3/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0xDE12c7959E1a72bbe8a5f7A1dc8f8EeF9Ab011B3',
    createdAt: 1649955002,
  },
  {
    id: 'boo-wftm-sftmx',
    name: 'sFTMx-FTM LP',
    token: 'sFTMx-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xE67980fc955FECfDA8A92BbbFBCc9f0C4bE60A9A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooFTM-sFTMx',
    earnedTokenAddress: '0x30E3154ce70660590FC8bBc1929Ce9236e66C50E',
    earnContractAddress: '0x30E3154ce70660590FC8bBc1929Ce9236e66C50E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-sftmx',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['sFTMx', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://spooky.fi/#/add/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0xd7028092c830b5C8FcE061Af2E593413EbbC1fc1',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0xd7028092c830b5C8FcE061Af2E593413EbbC1fc1',
    createdAt: 1650967301,
  },
  {
    id: 'boo-wftm-deus',
    name: 'DEUS-FTM LP',
    token: 'DEUS-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xaF918eF5b9f33231764A5557881E6D3e5277d456',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooFTM-DEUS',
    earnedTokenAddress: '0x6FC7AF3d1dF970Cd699E82941a71BC3Df03Ee986',
    earnContractAddress: '0x6FC7AF3d1dF970Cd699E82941a71BC3Df03Ee986',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-deus',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['DEUS', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/0xDE5ed76E7c05eC5e4572CfC88d1ACEA165109E44/FTM',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0xDE5ed76E7c05eC5e4572CfC88d1ACEA165109E44',
    createdAt: 1649955001,
  },
  {
    id: 'beets-two-gods-one-pool',
    name: 'Two Gods One Pool',
    token: 'Two Gods One Pool',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0x0e8e7307E43301CF28c5d21d5fD3EF0876217D41',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetTwoGodsOnePool',
    earnedTokenAddress: '0x9E8d0a070Ae90dB67B369cE54d2e81D0A3D8F1f3',
    earnContractAddress: '0x9E8d0a070Ae90dB67B369cE54d2e81D0A3D8F1f3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-two-gods-one-pool',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Beethoven X',
    assets: ['DEUS', 'DEI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://beets.fi/#/pool/0x0e8e7307e43301cf28c5d21d5fd3ef0876217d410002000000000000000003f1',
    createdAt: 1649878298,
  },
  {
    id: 'beets-clair-de-lune-eol',
    logo: 'fantom/Clair de Lune.png',
    name: 'Clair de Lune',
    token: 'Clair de Lune',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0x2fbb1EF03C02F9Bb2bD6f8C8c24F8dE347979d9e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetClairDeLune',
    earnedTokenAddress: '0xC1F06A159b53F326f9F16cfa37C76AEF643d9c40',
    earnContractAddress: '0xC1F06A159b53F326f9F16cfa37C76AEF643d9c40',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-clair-de-lune',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Beethoven X',
    assets: ['FTM', 'LUNAw', 'USTw'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'ALGO_STABLE',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://beets.fi/#/pool/0x2fbb1ef03c02f9bb2bd6f8c8c24f8de347979d9e00010000000000000000039a',
    createdAt: 1649509772,
  },
  {
    id: 'boo-wftm-luna-eol',
    name: 'LUNA-FTM LP',
    token: 'LUNA-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xD8934C58dE27e7B6342924B185dA0B3AB009F959',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooFTM-LUNA',
    earnedTokenAddress: '0xD677C20678b8C106D04D497fAF7F327Af7B74625',
    earnContractAddress: '0xD677C20678b8C106D04D497fAF7F327Af7B74625',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-luna',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'SpookySwap',
    assets: ['LUNA', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x593AE1d34c8BD7587C11D539E4F42BFf242c82Af',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x593AE1d34c8BD7587C11D539E4F42BFf242c82Af',
    createdAt: 1649436436,
  },
  {
    id: 'boo-wftm-ust-eol',
    name: 'UST-FTM LP',
    token: 'UST-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x656b3264695690322ACBad95F994b51C5a8C8eDF',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooFTM-UST',
    earnedTokenAddress: '0x808841CB5f23aDa8AF52Ed4b5cBCb8F0FD89868D',
    earnContractAddress: '0x808841CB5f23aDa8AF52Ed4b5cBCb8F0FD89868D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-ust',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'SpookySwap',
    assets: ['USTw', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'ALGO_STABLE',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x846e4D51d7E2043C1a87E0Ab7490B93FB940357b',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x846e4D51d7E2043C1a87E0Ab7490B93FB940357b',
    createdAt: 1649492747,
  },
  {
    id: 'curve-ftm-f-usdl',
    name: 'USDL/fUSDT/DAI/USDC',
    token: 'USDL-3CRV-f',
    tokenDescription: 'Curve',
    tokenAddress: '0x6EF78ad4a40E9A6c81B9229de3eCc33ce591bC34',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCurveUSDL',
    earnedTokenAddress: '0xc751C7114204eff5Ce50E6DDa0F03f8aF15996e2',
    earnContractAddress: '0xc751C7114204eff5Ce50E6DDa0F03f8aF15996e2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-ftm-f-usdl',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['USDL', 'fUSDT', 'DAI', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://ftm.curve.fi/factory/41/deposit',
    buyTokenUrl: 'https://app.topshelf.finance/#/mint',
    createdAt: 1649341331,
  },
  {
    id: 'beets-ust-ust-eol',
    name: 'UST (Axelar) - UST (Wormhole) LP',
    token: 'UST (Axelar) - UST (Wormhole) LP',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0x66Bcb58cF9754f421c268990B280e4462E10aac8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MooBeetUST-UST',
    earnedTokenAddress: '0x4867E7A3bB6163150Bcd3ed26cDca3EA026E6dF7',
    earnContractAddress: '0x4867E7A3bB6163150Bcd3ed26cDca3EA026E6dF7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-ust-ust',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Beethoven X',
    assets: ['USTw', 'USTaxl'],
    risks: [
      'COMPLEXITY_LOW',
      'NEW_STRAT',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'ALGO_STABLE',
    ],
    stratType: 'StableLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://beets.fi/#/pool/0x66bcb58cf9754f421c268990b280e4462e10aac8000200000000000000000339',
    createdAt: 1649404952,
  },
  {
    id: 'curve-ftm-f-ftml',
    name: 'FTML/FTM',
    token: 'FTM/FTML-f',
    tokenDescription: 'Curve',
    tokenAddress: '0x8B63F036F5a34226065bC0a7B0aE5bb5eBA1fF3D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCurveF-FTML',
    earnedTokenAddress: '0xaD3aD0D7140529eD329D7c9b4D876e1fBd7B2330',
    earnContractAddress: '0xaD3aD0D7140529eD329D7c9b4D876e1fBd7B2330',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-ftm-f-ftml',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['FTML', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://ftm.curve.fi/factory/42/deposit',
    buyTokenUrl: 'https://app.topshelf.finance/#/mint',
    createdAt: 1649341331,
  },
  {
    id: 'beefy-binspirit',
    logo: 'single-assets/binSPIRIT.svg',
    name: 'binSPIRIT',
    token: 'binSPIRIT',
    tokenDescription: 'Beefy',
    tokenAddress: '0x44e314190D9E4cE6d4C0903459204F8E21ff940A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBinSPIRIT',
    earnedTokenAddress: '0x7345a537A975d9Ca588eE631BEFdDfEF34fD5e8f',
    earnContractAddress: '0x7345a537A975d9Ca588eE631BEFdDfEF34fD5e8f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'binSPIRIT',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beefy',
    assets: ['binSPIRIT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    mintTokenUrl: 'https://beta.beefy.finance/#/fantom/vault/beefy-binspirit',
    buyTokenUrl: 'https://swap.spiritswap.finance/#/exchange/swap/SPIRIT/binSPIRIT',
    createdAt: 1643754413,
  },
  {
    id: 'tomb-tshare-mai',
    name: 'TSHARE-MAI LP',
    token: 'TSHARE-MAI LP',
    tokenDescription: 'TombSwap',
    tokenAddress: '0x67B2fAF48C1710fF1d2a9AC429b726B8F63eE83C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombTSHARE-MAI',
    earnedTokenAddress: '0x4afB1384f6641278D9BB22C6e33c1B526Cee2A40',
    earnContractAddress: '0x4afB1384f6641278D9BB22C6e33c1B526Cee2A40',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-tshare-mai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Tomb',
    assets: ['TSHARE', 'MAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.tomb.com/#/add/0x4cdF39285D7Ca8eB3f090fDA0C069ba5F4145B37/0xfB98B335551a418cD0737375a2ea0ded62Ea213b',
    buyTokenUrl:
      'https://swap.tomb.com/#/swap?inputCurrency=0xfB98B335551a418cD0737375a2ea0ded62Ea213b&outputCurrency=0x4cdF39285D7Ca8eB3f090fDA0C069ba5F4145B37',
    createdAt: 1648564274,
  },
  {
    id: 'stargate-fantom-usdc',
    name: 'USDC LP',
    token: 'S*USDC',
    logo: 'single-assets/USDC.svg',
    tokenDescription: 'Stargate',
    tokenAddress: '0x12edeA9cd262006cC3C4E77c90d2CD2DD4b1eb97',
    tokenDecimals: 6,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStargateUSDC',
    earnedTokenAddress: '0x07EC069DC86D5ca2eB3c705C7030525A62e6E78C',
    earnContractAddress: '0x07EC069DC86D5ca2eB3c705C7030525A62e6E78C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'sfUSDC',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stargate',
    assets: ['sfUSDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://stargate.finance/pool/USDC-FTM',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    createdAt: 1648657158,
  },
  {
    id: 'boo-wftm-beftm',
    name: 'beFTM-FTM LP',
    token: 'beFTM-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xB5512e3fA8304d33Cdae4a40C21F1d3f70Eba45a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooFTM-BeFTM',
    earnedTokenAddress: '0xdC72257947bb8C154Ffaf94D9E7C60ed54fEd380',
    earnContractAddress: '0xdC72257947bb8C154Ffaf94D9E7C60ed54fEd380',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-beftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['beFTM', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/0x7381eD41F6dE418DdE5e84B55590422a57917886/FTM',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x7381eD41F6dE418DdE5e84B55590422a57917886',
    createdAt: 1648777474,
  },
  {
    id: 'tomb-tomb-wftm',
    name: 'TOMB-FTM LP',
    token: 'TOMB-FTM TLP',
    tokenDescription: 'TombSwap',
    tokenAddress: '0xfca12A13ac324C09e9F43B5e5cfC9262f3Ab3223',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombTOMB-WFTM',
    earnedTokenAddress: '0x429590a528A86a0da0ACa9Aa7CD087BAdc790Af8',
    earnContractAddress: '0x429590a528A86a0da0ACa9Aa7CD087BAdc790Af8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-tomb-wftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Tomb',
    assets: ['TOMB', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'ALGO-STABLE',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://swap.tomb.com/#/add/0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7/ETH',
    buyTokenUrl:
      'https://swap.tomb.com/#/swap?inputCurrency=ETH&outputCurrency=0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
    createdAt: 1648676349,
  },
  {
    id: 'based-based-bshare',
    name: 'BASED-BSHARE LP',
    token: 'BASED-BSHARE LP',
    tokenDescription: 'SpookySwap (Based)',
    tokenAddress: '0x5748b5Dd1f59342f85d170c48C427959c2f9f262',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBasedBASED-BSHARE',
    earnedTokenAddress: '0x3a7ec503CD55A153bC3A3Ec5a2ee340136C005Ff',
    earnContractAddress: '0x3a7ec503CD55A153bC3A3Ec5a2ee340136C005Ff',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'based-based-bshare',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Other',
    assets: ['BASED', 'BSHARE'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'ALGO-STABLE',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    depositFee: '0.2%',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://spooky.fi/#/add/0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae/0x49C290Ff692149A4E16611c694fdED42C954ab7a',
    buyTokenUrl:
      'https://spooky.fi/#/swap?inputCurrency=0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae&outputCurrency=0x49C290Ff692149A4E16611c694fdED42C954ab7a',
    createdAt: 1648136284,
  },
  {
    id: 'beets-god-between-stables-eol',
    name: 'One God Between Two Stables',
    token: 'One God Between Two Stables',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0x8B858Eaf095A7337dE6f9bC212993338773cA34e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetGodBetweenTwoStables',
    earnedTokenAddress: '0xCbc3A0ae73ba667d69f9023c2d11276E016a9C09',
    earnContractAddress: '0xCbc3A0ae73ba667d69f9023c2d11276E016a9C09',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-god-between-stables',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Beethoven X',
    assets: ['DEI', 'USDC'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_NONE', 'MCAP_MICRO', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://beets.fi/#/pool/0x8b858eaf095a7337de6f9bc212993338773ca34e00020000000000000000023c',
    createdAt: 1648130947,
  },
  {
    id: 'beets-exploding-shrapnel-eol',
    name: 'Exploding Shrapnel',
    token: 'Exploding Shrapnel',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0x2Ba4953C75860e70Cd70f15a2D5Fe07DE832Bcd1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetExplodingShrapnel',
    earnedTokenAddress: '0xE480703dD6959D997359B675B7b2C008f8778e5a',
    earnContractAddress: '0xE480703dD6959D997359B675B7b2C008f8778e5a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-exploding-shrapnel',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Beethoven X',
    assets: ['FTM', 'SHRAP'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_HIGH', 'MCAP_MICRO', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://beets.fi/#/pool/0x2ba4953c75860e70cd70f15a2d5fe07de832bcd1000200000000000000000210',
    createdAt: 1647978282,
  },
  {
    id: 'boo-wftm-ape',
    name: 'APE-FTM LP',
    token: 'APE-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xC9FB686f14bDA7e2653cF8F605Dc8551B6a53FD3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooFTM-APE',
    earnedTokenAddress: '0xe2A269BE12bCfBc3EA33eafe53e9B068848017f6',
    earnContractAddress: '0xe2A269BE12bCfBc3EA33eafe53e9B068848017f6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-ape',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['APE', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x2d72A97a31Dc920dB03330780d30074626e39C8A',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x2d72A97a31Dc920dB03330780d30074626e39C8A',
    createdAt: 1648237084,
  },
  {
    id: 'boo-usdc-boo',
    name: 'BOO-USDC LP',
    token: 'BOO-USDC LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xf8Cb2980120469d79958151daa45Eb937c6E1eD6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooUSDC-BOO',
    earnedTokenAddress: '0xce6A6b6935A1aB3aF7Be53fbc8A149791b418278',
    earnContractAddress: '0xce6A6b6935A1aB3aF7Be53fbc8A149791b418278',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-usdc-boo',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['BOO', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://spooky.fi/#/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE',
    buyTokenUrl:
      'https://spooky.fi/#/swap?inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&outputCurrency=0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE',
    createdAt: 1648237004,
  },
  {
    id: 'based-tomb-based',
    name: 'BASED-TOMB LP',
    token: 'BASED-TOMB TLP',
    tokenDescription: 'TombSwap (Based)',
    tokenAddress: '0x172BFaA8991A54ABD0b3EE3d4F8CBDab7046FF79',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombBASED-TOMB',
    earnedTokenAddress: '0x39E5C480bc28b77D8B5960Abc248A21C0cF4bE30',
    earnContractAddress: '0x39E5C480bc28b77D8B5960Abc248A21C0cF4bE30',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'based-tomb-based',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Other',
    assets: ['BASED', 'TOMB'],
    risks: [
      'COMPLEXITY_LOW',
      'ALGO_STABLE',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    depositFee: '0.2%',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.tomb.com/#/add/0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7/0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae',
    buyTokenUrl:
      'https://swap.tomb.com/#/swap?inputCurrency=0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7&outputCurrency=0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae',
    createdAt: 1648299488,
  },
  {
    id: 'wigo-wigo',
    logo: 'single-assets/WIGO.svg',
    name: 'WIGO',
    token: 'WIGO',
    tokenDescription: 'WigoSwap',
    tokenAddress: '0xE992bEAb6659BFF447893641A378FbbF031C5bD6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWigoWIGO',
    earnedTokenAddress: '0xE14Cf6e87f3777f6F1D09D3076D15027cC0f4cb1',
    earnContractAddress: '0xE14Cf6e87f3777f6F1D09D3076D15027cC0f4cb1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WIGO',
    oraclePrice: 0,
    depositsPaused: false,
    platform: 'WigoSwap',
    assets: ['WIGO'],
    status: 'active',
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://wigoswap.io/swap?outputCurrency=0xE992bEAb6659BFF447893641A378FbbF031C5bD6',
    createdAt: 1648108327,
  },
  {
    id: 'spirit-ust-ftm',
    name: 'UST-FTM LP',
    token: 'UST-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0xFA84CED3DC4bFFAF93d21B9E3A4750F5C2A42886',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritUST-FTM',
    earnedTokenAddress: '0x92F44893D5f22bDc8540CEb83B0b3E3112951bEe',
    earnContractAddress: '0x92F44893D5f22bDc8540CEb83B0b3E3112951bEe',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ust-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['USTw', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'ALGO_STABLE',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0x846e4D51d7E2043C1a87E0Ab7490B93FB940357b',
    buyTokenUrl: 'https://swap.spiritswap.finance/#/exchange/swap/FTM/UST',
    createdAt: 1647533765,
  },
  {
    id: 'based-based-mai',
    name: 'BASED-MAI LP',
    token: 'BASED-MAI LP',
    tokenDescription: 'TombSwap (Based)',
    tokenAddress: '0x7B5B3751550be4FF87aC6bda89533F7A0c9825B3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBasedBASED-MAI',
    earnedTokenAddress: '0x5Ddb9a342672ecEe80a028CE40500F16ba1Bca44',
    earnContractAddress: '0x5Ddb9a342672ecEe80a028CE40500F16ba1Bca44',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'based-based-mai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Other',
    assets: ['BASED', 'MAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'ALGO_STABLE',
      'IL_HIGH',
      'MCAP_MICRO',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    depositFee: '0.2%',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.tomb.com/#/add/0xfB98B335551a418cD0737375a2ea0ded62Ea213b/0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae',
    buyTokenUrl:
      'https://swap.tomb.com/#/swap?inputCurrency=0xfB98B335551a418cD0737375a2ea0ded62Ea213b&outputCurrency=0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae',
    createdAt: 1647380053,
  },
  {
    id: 'tomb-tomb-mai',
    name: 'TOMB-MAI LP',
    token: 'TOMB-MAI LP',
    tokenDescription: 'TombSwap',
    tokenAddress: '0x45f4682B560d4e3B8FF1F1b3A38FDBe775C7177b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombTOMB-MAI',
    earnedTokenAddress: '0xb2be5Cd33DBFf412Bce9587E44b5647a4BdA6a66',
    earnContractAddress: '0xb2be5Cd33DBFf412Bce9587E44b5647a4BdA6a66',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-tomb-mai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Tomb',
    assets: ['TOMB', 'MAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.tomb.com/#/add/0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7/0xfB98B335551a418cD0737375a2ea0ded62Ea213b',
    buyTokenUrl:
      'https://swap.tomb.com/#/swap?inputCurrency=0xfB98B335551a418cD0737375a2ea0ded62Ea213b&outputCurrency=0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
    createdAt: 1647007175,
  },
  {
    id: 'spirit-hnd-ftm',
    name: 'HND-FTM LP',
    token: 'HND-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x03dE4A4897941712BeBE5362729181257dC9E8B6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritHND-FTM',
    earnedTokenAddress: '0x8E1fE6FeA7aC27526643c3FCFd7DA50047FCE7Cb',
    earnContractAddress: '0x8E1fE6FeA7aC27526643c3FCFd7DA50047FCE7Cb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-hnd-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['HND', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0x10010078a54396F62c96dF8532dc2B4847d47ED3',
    buyTokenUrl: 'https://swap.spiritswap.finance/#/exchange/swap/FTM/HND',
    createdAt: 1646913142,
  },
  {
    id: 'beets-cre8r-f-major-eol',
    name: 'Cre8r in F Major',
    token: 'Cre8r in F Major',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0xbb4607beDE4610e80d35C15692eFcB7807A2d0A6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetCre8rFMajor',
    earnedTokenAddress: '0x9FA6bC4fCd487ED9CF5216A03AeCF6F1A82ABc76',
    earnContractAddress: '0x9FA6bC4fCd487ED9CF5216A03AeCF6F1A82ABc76',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-cre8r-f-major',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Beethoven X',
    assets: ['CRE8R', 'FTM'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_HIGH', 'MCAP_MICRO', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://beets.fi/#/pool/0xbb4607bede4610e80d35c15692efcb7807a2d0a6000200000000000000000140',
    createdAt: 1646301115,
  },
  {
    id: 'spirit-ftm-treeb',
    name: 'TREEB-FTM LP',
    token: 'TREEB-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x2cEfF1982591c8B0a73b36D2A6C2A6964Da0E869',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritFTM-TREEB',
    earnedTokenAddress: '0x1c85Ea06184603e43fb0386ba44589eAe3d06980',
    earnContractAddress: '0x1c85Ea06184603e43fb0386ba44589eAe3d06980',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-treeb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['TREEB', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0xc60D7067dfBc6f2caf30523a064f416A5Af52963',
    buyTokenUrl: 'https://swap.spiritswap.finance/#/exchange/swap/FTM/TREEB',
    createdAt: 1646304431,
  },
  {
    id: 'spirit-ftm-beftm',
    name: 'beFTM-FTM LP',
    token: 'beFTM-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0xE3D4C22d0543E050a8b3F713899854Ed792fc1bD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritBeFTM-FTM',
    earnedTokenAddress: '0x4ED766aF5B30a4695F7b247AE1bB0f6B34bC6378',
    earnContractAddress: '0x4ED766aF5B30a4695F7b247AE1bB0f6B34bC6378',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-beftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['beFTM', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0x7381ed41f6de418dde5e84b55590422a57917886',
    buyTokenUrl: 'https://swap.spiritswap.finance/#/exchange/swap/FTM/beFTM',
    createdAt: 1646866226,
  },
  {
    id: 'beets-beefy-tale',
    name: 'Beefy Tale Of Two Fantom Sisters',
    token: 'Beefy Tale Of Two Fantom Sisters',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0x3bd4c3d1f6F40d77B2e9d0007D6f76E4F183A46d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetBeefyTale',
    earnedTokenAddress: '0x12c9aa044D4474b1abE3Fa4875c9ADbDc05Ef898',
    earnContractAddress: '0x12c9aa044D4474b1abE3Fa4875c9ADbDc05Ef898',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-beefy-tale',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beethoven X',
    assets: ['beFTM', 'FTM'],
    risks: ['COMPLEXITY_LOW', 'IL_LOW', 'MCAP_MICRO', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://beets.fi/#/pool/0x3bd4c3d1f6f40d77b2e9d0007d6f76e4f183a46d0002000000000000000002d7',
    createdAt: 1646864556,
  },
  {
    id: 'spirit-binspirit-spirit',
    name: 'binSPIRIT-SPIRIT LP',
    token: 'binSPIRIT-SPIRIT SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0xD4F6574fD9ba1e218673971f92Ad0f73abe31dEE',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritBinSPIRIT-SPIRIT',
    earnedTokenAddress: '0x794cEaD3c864B5390254ffca7ecd6a9aE868661a',
    earnContractAddress: '0x794cEaD3c864B5390254ffca7ecd6a9aE868661a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-binspirit-spirit',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['binSPIRIT', 'SPIRIT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/0x44e314190D9E4cE6d4C0903459204F8E21ff940A/0x5Cc61A78F164885776AA610fb0FE1257df78E59B',
    buyTokenUrl: 'https://swap.spiritswap.finance/#/exchange/swap/SPIRIT/binSPIRIT',
    createdAt: 1643907163,
  },
  {
    id: 'based-based-tomb',
    name: 'BASED-TOMB LP',
    token: 'BASED-TOMB LP',
    tokenDescription: 'SpookySwap (Based)',
    tokenAddress: '0xaB2ddCBB346327bBDF97120b0dD5eE172a9c8f9E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBasedBASED-TOMB',
    earnedTokenAddress: '0xEb27F60f652fF53cBf3EfF0c7033B380148b6CB9',
    earnContractAddress: '0xEb27F60f652fF53cBf3EfF0c7033B380148b6CB9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'based-based-tomb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Other',
    assets: ['BASED', 'TOMB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'ALGO-STABLE',
      'IL_LOW',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://spooky.fi/#/add/0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7/0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae',
    createdAt: 1646398565,
  },
  {
    id: 'based-bshare-ftm',
    name: 'BSHARE-FTM LP',
    token: 'BSHARE-FTM LP',
    tokenDescription: 'SpookySwap (Based)',
    tokenAddress: '0x6F607443DC307DCBe570D0ecFf79d65838630B56',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBasedBSHARE-FTM',
    earnedTokenAddress: '0x44B35db29db8c5277bF842c67b4d36D42323514C',
    earnContractAddress: '0x44B35db29db8c5277bF842c67b4d36D42323514C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'based-bshare-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Other',
    assets: ['BSHARE', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x49C290Ff692149A4E16611c694fdED42C954ab7a',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x49C290Ff692149A4E16611c694fdED42C954ab7a',
    createdAt: 1646398565,
  },
  {
    id: 'spirit-ftm-atlas',
    name: 'ATLAS-FTM LP',
    token: 'ATLAS-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0xFFcF183126dF14EC4E59409bAb431885ccEEb1C2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritFTM-ATLAS',
    earnedTokenAddress: '0x75de621246281c8b2B0E09459Af95D56b5fD7536',
    earnContractAddress: '0x75de621246281c8b2B0E09459Af95D56b5fD7536',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-atlas',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['ATLAS', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0x92df3eaBf7c1c2A6b3D5793f6d53778eA78c48b2',
    buyTokenUrl: 'https://swap.spiritswap.finance/#/exchange/swap/FTM/ATLAS',
    createdAt: 1646304431,
  },
  {
    id: 'spirit-ftm-bifi',
    name: 'BIFI-FTM LP',
    token: 'BIFI-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0xc28cf9aeBfe1A07A27B3A4d722C841310e504Fe3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritFTM-BIFI',
    earnedTokenAddress: '0x83A002075ceCBB9772160383a32D953630CDc6F1',
    earnContractAddress: '0x83A002075ceCBB9772160383a32D953630CDc6F1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-bifi',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['BIFI', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0xd6070ae98b8069de6B494332d1A1a81B6179D960',
    buyTokenUrl: 'https://swap.spiritswap.finance/#/exchange/swap/FTM/BIFI',
    createdAt: 1646229229,
  },
  {
    id: 'beets-statera-duet-eol',
    name: 'Statera Maxi Duet',
    token: 'Statera Maxi Duet',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0x5Bb2DeE206C2bCc5768B9B2865eA971Bd9c0fF19',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetFreshStateraMaxiDuet',
    earnedTokenAddress: '0xc92DFA1FCAEBfDF6Ac60508Ad22B968B1B71E6Ac',
    earnContractAddress: '0xc92DFA1FCAEBfDF6Ac60508Ad22B968B1B71E6Ac',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-statera-duet',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Beethoven X',
    assets: ['WSTA', 'BEETS'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_HIGH', 'MCAP_SMALL', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://beets.fi/#/pool/0x5bb2dee206c2bcc5768b9b2865ea971bd9c0ff19000200000000000000000219',
    createdAt: 1646154004,
  },
  {
    id: 'wigo-wigo-ftm',
    name: 'WIGO-FTM LP',
    token: 'WIGO-FTM WLP',
    tokenDescription: 'WigoSwap',
    tokenAddress: '0xB66E5c89EbA830B31B3dDcc468dD50b3256737c5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWigoFTM-WIGO',
    earnedTokenAddress: '0x44D955415414321C26B3F87A49688545b11DbA09',
    earnContractAddress: '0x44D955415414321C26B3F87A49688545b11DbA09',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wigo-wigo-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WigoSwap',
    assets: ['WIGO', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://wigoswap.io/add/FTM/0xE992bEAb6659BFF447893641A378FbbF031C5bD6',
    buyTokenUrl:
      'https://wigoswap.io/swap?outputCurrency=0xE992bEAb6659BFF447893641A378FbbF031C5bD6',
    createdAt: 1645955122,
  },
  {
    id: 'wigo-usdc-wigo',
    name: 'WIGO-USDC LP',
    token: 'WIGO-USDC WLP',
    tokenDescription: 'WigoSwap',
    tokenAddress: '0x96bDF4d9fb8dB9FcD1E0CA146faBD891f2F1A96d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWigoWIGO-USDC',
    earnedTokenAddress: '0x6f991190577eE0FF85C0730A185746af3aFAe799',
    earnContractAddress: '0x6f991190577eE0FF85C0730A185746af3aFAe799',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wigo-usdc-wigo',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WigoSwap',
    assets: ['WIGO', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://wigoswap.io/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0xE992bEAb6659BFF447893641A378FbbF031C5bD6',
    buyTokenUrl:
      'https://wigoswap.io/swap?inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&outputCurrency=0xE992bEAb6659BFF447893641A378FbbF031C5bD6',
    createdAt: 1645947800,
  },
  {
    id: 'wigo-solid-wigo-eol',
    name: 'SOLID-WIGO LP',
    token: 'SOLID-WIGO WLP',
    tokenDescription: 'WigoSwap',
    tokenAddress: '0x86BF67e8B743b25586b7eD88243Efc2f1d09edB3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWigoSOLID-WIGO',
    earnedTokenAddress: '0xfCF7F94E6c2E8e8e65Ed58920E65040d24f7DFB8',
    earnContractAddress: '0xfCF7F94E6c2E8e8e65Ed58920E65040d24f7DFB8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wigo-solid-wigo',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'WigoSwap',
    assets: ['SOLID', 'WIGO'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://wigoswap.io/add/0xE992bEAb6659BFF447893641A378FbbF031C5bD6/0x888EF71766ca594DED1F0FA3AE64eD2941740A20',
    buyTokenUrl:
      'https://wigoswap.io/swap?inputCurrency=0xE992bEAb6659BFF447893641A378FbbF031C5bD6&outputCurrency=0x888EF71766ca594DED1F0FA3AE64eD2941740A20',
    createdAt: 1646001502,
  },
  {
    id: 'wigo-tomb-ftm-eol',
    logo: 'fantom/TOMB-FTM.png',
    name: 'TOMB-FTM LP',
    token: 'TOMB-FTM WLP',
    tokenDescription: 'WigoSwap',
    tokenAddress: '0x2198FEC7Aa15e533AAdc2350566be2a0150FFE9E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWigoFTM-TOMB',
    earnedTokenAddress: '0x51eBBbFF762119b2200910fDD97607575E27EdeD',
    earnContractAddress: '0x51eBBbFF762119b2200910fDD97607575E27EdeD',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wigo-tomb-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'WigoSwap',
    assets: ['TOMB', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://wigoswap.io/add/FTM/0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
    buyTokenUrl:
      'https://wigoswap.io/swap?outputCurrency=0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
    createdAt: 1645155345,
  },
  {
    id: 'wigo-beets-ftm-eol',
    name: 'BEETS-FTM LP',
    token: 'BEETS-FTM WLP',
    tokenDescription: 'WigoSwap',
    tokenAddress: '0x89385E754af37d9f5AD3D5D9186169c910B958bC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWigoFTM-BEETS',
    earnedTokenAddress: '0xf11c4f5F04f90C95a302D84cB6162429d007cf20',
    earnContractAddress: '0xf11c4f5F04f90C95a302D84cB6162429d007cf20',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wigo-beets-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'WigoSwap',
    assets: ['BEETS', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://wigoswap.io/add/FTM/0xF24Bcf4d1e507740041C9cFd2DddB29585aDCe1e',
    buyTokenUrl:
      'https://wigoswap.io/swap?outputCurrency=0xF24Bcf4d1e507740041C9cFd2DddB29585aDCe1e',
    createdAt: 1645161816,
  },
  {
    id: 'wigo-geist-ftm-eol',
    name: 'GEIST-FTM LP',
    token: 'GEIST-FTM WLP',
    tokenDescription: 'WigoSwap',
    tokenAddress: '0xC585346Cd04633e44AF7fB0c2cEf9bc2C6b38A87',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWigoFTM-GEIST',
    earnedTokenAddress: '0xC2003a3604A2eB576ACe7cF842EC08AfE0a18e0A',
    earnContractAddress: '0xC2003a3604A2eB576ACe7cF842EC08AfE0a18e0A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wigo-geist-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'WigoSwap',
    assets: ['GEIST', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://wigoswap.io/add/FTM/0xd8321AA83Fb0a4ECd6348D4577431310A6E0814d',
    buyTokenUrl:
      'https://wigoswap.io/swap?outputCurrency=0xd8321AA83Fb0a4ECd6348D4577431310A6E0814d',
    createdAt: 1645165726,
  },
  {
    id: 'wigo-treeb-ftm',
    name: 'TREEB-FTM LP',
    token: 'TREEB-FTM WLP',
    tokenDescription: 'WigoSwap',
    tokenAddress: '0x467c4f18CEDCC768F22233c8b047E990e440B470',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWigoFTM-TREEB',
    earnedTokenAddress: '0x4e186298855aD9743D00152ff5E2F761b2962Ff9',
    earnContractAddress: '0x4e186298855aD9743D00152ff5E2F761b2962Ff9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wigo-treeb-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WigoSwap',
    assets: ['TREEB', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://wigoswap.io/add/FTM/0xc60D7067dfBc6f2caf30523a064f416A5Af52963',
    buyTokenUrl:
      'https://wigoswap.io/swap?outputCurrency=0xc60D7067dfBc6f2caf30523a064f416A5Af52963',
    createdAt: 1645165612,
  },
  {
    id: 'wigo-crv-ftm',
    name: 'CRV-FTM LP',
    token: 'CRV-FTM WLP',
    tokenDescription: 'WigoSwap',
    tokenAddress: '0xEDa9715BE52e8819f5C5a2305813A851e568dAFf',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWigoCRV-FTM',
    earnedTokenAddress: '0x0c7cf8118eafea41bBBECe3e2d91dCdF6cafD5F9',
    earnContractAddress: '0x0c7cf8118eafea41bBBECe3e2d91dCdF6cafD5F9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wigo-crv-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WigoSwap',
    assets: ['CRV', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://wigoswap.io/add/FTM/0x1E4F97b9f9F913c46F1632781732927B9019C68b',
    buyTokenUrl:
      'https://wigoswap.io/swap?&outputCurrency=0x1E4F97b9f9F913c46F1632781732927B9019C68b',
    createdAt: 1645947800,
  },
  {
    id: 'wigo-dai-ftm',
    name: 'DAI-FTM LP',
    token: 'DAI-FTM WLP',
    tokenDescription: 'WigoSwap',
    tokenAddress: '0x280F2F0cC01B2fd1b10d830b5deAf2343a78D3D5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWigoFTM-DAI',
    earnedTokenAddress: '0xe65DE6b412aB7d221E0C87fe61EE893490E8E6B3',
    earnContractAddress: '0xe65DE6b412aB7d221E0C87fe61EE893490E8E6B3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wigo-dai-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WigoSwap',
    assets: ['DAI', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://wigoswap.io/add/FTM/0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    buyTokenUrl:
      'https://wigoswap.io/swap?outputCurrency=0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    createdAt: 1645166063,
  },
  {
    id: 'wigo-usdc-ftm',
    name: 'USDC-FTM LP',
    token: 'USDC-FTM WLP',
    tokenDescription: 'WigoSwap',
    tokenAddress: '0xaC97153e7ce86fB3e61681b969698AF7C22b4B12',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWigoUSDC-FTM',
    earnedTokenAddress: '0x70c6AF9Dff8C19B3db576E5E199B22A883874f05',
    earnContractAddress: '0x70c6AF9Dff8C19B3db576E5E199B22A883874f05',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wigo-usdc-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WigoSwap',
    assets: ['USDC', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://wigoswap.io/add/FTM/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    buyTokenUrl:
      'https://wigoswap.io/swap?&outputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    createdAt: 1645947800,
  },
  {
    id: 'wigo-fusdt-ftm-eol',
    name: 'fUSDT-FTM LP',
    token: 'fUSDT-FTM WLP',
    tokenDescription: 'WigoSwap',
    tokenAddress: '0x970DDee1709e28cE89EAB51692b56373E00077A9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWigofUSDT-FTM',
    earnedTokenAddress: '0x455C1A3052D57b55C97eFAdf0e09f9A5d59912b8',
    earnContractAddress: '0x455C1A3052D57b55C97eFAdf0e09f9A5d59912b8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wigo-fusdt-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'WigoSwap',
    assets: ['fUSDT', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://wigoswap.io/add/FTM/0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    buyTokenUrl:
      'https://wigoswap.io/swap?&outputCurrency=0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    createdAt: 1645947800,
  },
  {
    id: 'wigo-eth-ftm',
    name: 'ETH-FTM LP',
    token: 'ETH-FTM WLP',
    tokenDescription: 'WigoSwap',
    tokenAddress: '0x9B82CF433bEa2671Ea024A5c7066DE0c3292AdE6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWigoETH-FTM',
    earnedTokenAddress: '0xDb852f7398f9Bdbf868ed4Dda2eb3B055e219B3c',
    earnContractAddress: '0xDb852f7398f9Bdbf868ed4Dda2eb3B055e219B3c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wigo-eth-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WigoSwap',
    assets: ['ETH', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://wigoswap.io/add/FTM/0x74b23882a30290451A17c44f4F05243b6b58C76d',
    buyTokenUrl:
      'https://wigoswap.io/swap?&outputCurrency=0x74b23882a30290451A17c44f4F05243b6b58C76d',
    createdAt: 1645947800,
  },
  {
    id: 'wigo-eth-btc',
    name: 'ETH-BTC LP',
    token: 'ETH-BTC WLP',
    tokenDescription: 'WigoSwap',
    tokenAddress: '0xE3e5F47399864Ca63cDFBb22A207AFc398EFD660',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWigoETH-BTC',
    earnedTokenAddress: '0xED5c4150F867b278866bbAD33e947287cc5a2Ef1',
    earnContractAddress: '0xED5c4150F867b278866bbAD33e947287cc5a2Ef1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wigo-eth-btc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WigoSwap',
    assets: ['ETH', 'WBTC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://wigoswap.io/add/0x321162Cd933E2Be498Cd2267a90534A804051b11/0x74b23882a30290451A17c44f4F05243b6b58C76d',
    buyTokenUrl:
      'https://wigoswap.io/swap?inputCurrency=0x321162Cd933E2Be498Cd2267a90534A804051b11&outputCurrency=0x74b23882a30290451A17c44f4F05243b6b58C76d',
    createdAt: 1645947800,
  },
  {
    id: 'wigo-usdc-dai',
    name: 'USDC-DAI LP',
    token: 'USDC-DAI WLP',
    tokenDescription: 'WigoSwap',
    tokenAddress: '0xFDc67A84C3Aa2430c024B7d35B3c09872791d722',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWigoUSDC-DAI',
    earnedTokenAddress: '0x7fe35CeFe2E30A3fD2dA9eBDaf4d237fDDD97e18',
    earnContractAddress: '0x7fe35CeFe2E30A3fD2dA9eBDaf4d237fDDD97e18',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wigo-usdc-dai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WigoSwap',
    assets: ['USDC', 'DAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://wigoswap.io/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    buyTokenUrl:
      'https://wigoswap.io/swap?inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&outputCurrency=0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    createdAt: 1645161865,
  },
  {
    id: 'wigo-usdc-fusdt',
    name: 'USDC-fUSDT LP',
    token: 'USDC-fUSDT WLP',
    tokenDescription: 'WigoSwap',
    tokenAddress: '0x219eF2d8DaD28a72dA297E79ed6a990F65307a4C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWigoUSDC-fUSDT',
    earnedTokenAddress: '0xc5b437d5207233C1c651aCea4f11842BECdf1E37',
    earnContractAddress: '0xc5b437d5207233C1c651aCea4f11842BECdf1E37',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wigo-usdc-fusdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WigoSwap',
    assets: ['USDC', 'fUSDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://wigoswap.io/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    buyTokenUrl:
      'https://wigoswap.io/swap?inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&outputCurrency=0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    createdAt: 1645166692,
  },
  {
    id: 'wigo-usdc-frax',
    name: 'FRAX-USDC LP',
    token: 'FRAX-USDC WLP',
    tokenDescription: 'WigoSwap',
    tokenAddress: '0x3518B99C445d1f35E041f8D2893bbc113e392ea4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWigoFRAX-USDC',
    earnedTokenAddress: '0xdB58dF435192Ea66FFC273Ab83C6de81299c6A67',
    earnContractAddress: '0xdB58dF435192Ea66FFC273Ab83C6de81299c6A67',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wigo-usdc-frax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WigoSwap',
    assets: ['FRAX', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://wigoswap.io/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0xdc301622e621166BD8E82f2cA0A26c13Ad0BE355',
    buyTokenUrl:
      'https://wigoswap.io/swap?inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&outputCurrency=0xdc301622e621166BD8E82f2cA0A26c13Ad0BE355',
    createdAt: 1645947800,
  },
  {
    id: 'boo-kae-wftm',
    name: 'KAE-FTM LP',
    token: 'KAE-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xE6C2DB69dCDA38A8f56feAfC0229E6f039E5d5E2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooFTM-KAE',
    earnedTokenAddress: '0x8D4022a63634a785955a5Da01ab639a469430585',
    earnContractAddress: '0x8D4022a63634a785955a5Da01ab639a469430585',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-kae-wftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['KAE', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x65Def5029A0e7591e46B38742bFEdd1Fb7b24436',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x65Def5029A0e7591e46B38742bFEdd1Fb7b24436',
    createdAt: 1645886552,
  },
  {
    id: 'beets-beardhemian-brushsody-eol',
    logo: 'fantom/Beardhemian Brushsody.png',
    name: 'Beardhemian Brushsody',
    token: 'Beardhemian Brushsody',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0x8FdD16a23aEBe95B928f1863760618E9EC29e72D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetBeardhemianBrushsody',
    earnedTokenAddress: '0x4737a4d9112347937a9b03f449678Ba01A0AcEae',
    earnContractAddress: '0x4737a4d9112347937a9b03f449678Ba01A0AcEae',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-beardhemian-brushsody',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'Beethoven X',
    assets: ['BRUSH', 'USDC', 'FTM'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_HIGH', 'MCAP_MICRO', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://beets.fi/#/pool/0x8fdd16a23aebe95b928f1863760618e9ec29e72d000100000000000000000166',
    createdAt: 1644853476,
  },
  {
    id: 'boo-ftm-multi',
    name: 'MULTI-FTM LP',
    token: 'MULTI-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x297C8990134bf1eE08aE5D8805042fbac8781201',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooFTM-MULTI',
    earnedTokenAddress: '0x22f72e86A9CA2CDD3Cdc94012E9901D93E3d90F8',
    earnContractAddress: '0x22f72e86A9CA2CDD3Cdc94012E9901D93E3d90F8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-ftm-multi',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['MULTI', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x9fb9a33956351cf4fa040f65a13b835a3c8764e3',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x9fb9a33956351cf4fa040f65a13b835a3c8764e3',
    createdAt: 1645049415,
  },
  {
    id: 'spirit-usdc-dei',
    name: 'DEI-USDC LP',
    token: 'DEI-USDC SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x8eFD36aA4Afa9F4E157bec759F1744A7FeBaEA0e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritUSDC-DEI',
    earnedTokenAddress: '0x01CC6C3262E24e0d7f45c968e84A8b2ddC12b9cF',
    earnContractAddress: '0x01CC6C3262E24e0d7f45c968e84A8b2ddC12b9cF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-usdc-dei',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['DEI', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'ALGO_STABLE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/USDC/0xDE12c7959E1a72bbe8a5f7A1dc8f8EeF9Ab011B3',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/USDC/0xDE12c7959E1a72bbe8a5f7A1dc8f8EeF9Ab011B3',
    createdAt: 1645034296,
  },
  {
    id: 'spirit-ftm-deus',
    name: 'DEUS-FTM LP',
    token: 'DEUS-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x2599Eba5fD1e49F294C76D034557948034d6C96E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritFTM-DEUS',
    earnedTokenAddress: '0x4194cbcF85A1B3f419741432Ae455f28d51Ee7EF',
    earnContractAddress: '0x4194cbcF85A1B3f419741432Ae455f28d51Ee7EF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-deus',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['DEUS', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0xDE5ed76E7c05eC5e4572CfC88d1ACEA165109E44',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/FTM/0xDE5ed76E7c05eC5e4572CfC88d1ACEA165109E44',
    createdAt: 1645034296,
  },
  {
    id: 'spirit-ftm-cre8r',
    name: 'CRE8R-FTM LP',
    token: 'CRE8R-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x459e7c947E04d73687e786E4A48815005dFBd49A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritFTM-CRE8R',
    earnedTokenAddress: '0x503FF2102D51ec816C693017d26E31df666Cadf0',
    earnContractAddress: '0x503FF2102D51ec816C693017d26E31df666Cadf0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-cre8r',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['CRE8R', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0x2aD402655243203fcfa7dCB62F8A08cc2BA88ae0',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/FTM/0x2aD402655243203fcfa7dCB62F8A08cc2BA88ae0',
    createdAt: 1645034296,
  },
  {
    id: 'beets-treeb-me-bro-eol',
    name: 'Treeb Me Bro',
    token: 'Treeb Me Bro',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0xA1C5698A042638b6220e336Ed9cFAFb499560c20',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetTreebMeBro',
    earnedTokenAddress: '0xD8E92bcBf66a675b2Ac45f0BBbb0F285ba607AB7',
    earnContractAddress: '0xD8E92bcBf66a675b2Ac45f0BBbb0F285ba607AB7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-treeb-me-bro',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Beethoven X',
    assets: ['TREEB', 'USDC'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_HIGH', 'MCAP_MICRO', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://beets.fi/#/pool/0xa1c5698a042638b6220e336ed9cfafb499560c200002000000000000000001b6',
    createdAt: 1644853476,
  },
  {
    id: 'ripae-pftm-ftm',
    name: 'pFTM-FTM LP',
    token: 'pFTM-FTM LP',
    tokenDescription: 'SpookySwap (Ripae)',
    tokenAddress: '0x9ce8e9b090e8AF873e793e0b78C484076F8CEECE',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooRipaePFTM-FTM',
    earnedTokenAddress: '0xb97C963834319e1E07d4F241F1F42f6a41CAEB85',
    earnContractAddress: '0xb97C963834319e1E07d4F241F1F42f6a41CAEB85',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ripae-pftm-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Ripae',
    assets: ['pFTM', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x112dF7E3b4B7Ab424F07319D4E92F41e6608c48B',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x112dF7E3b4B7Ab424F07319D4E92F41e6608c48B',
    createdAt: 1644485111,
  },
  {
    id: 'ripae-pae-ftm',
    name: 'PAE-FTM LP',
    token: 'PAE-FTM LP',
    tokenDescription: 'SpookySwap (Ripae)',
    tokenAddress: '0x2DC234DbfC085DdbC36a6EACC061D7333Cd397b0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooRipaePAE-FTM',
    earnedTokenAddress: '0x1f01078af0b8a4e7E2BA6211Bda7e92F89393284',
    earnContractAddress: '0x1f01078af0b8a4e7E2BA6211Bda7e92F89393284',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ripae-pae-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Ripae',
    assets: ['PAE', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x8a41f13a4FaE75ca88B1ee726ee9D52B148b0498',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x8a41f13a4FaE75ca88B1ee726ee9D52B148b0498',
    createdAt: 1644485584,
  },
  {
    id: 'boo-mst-wftm-eol',
    name: 'MST-FTM LP',
    token: 'MST-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x1a88E447c7468B28de490b25a076A4fFc0C68b16',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooMST-WFTM',
    earnedTokenAddress: '0xf112252fB4b7c2cEA1A78659e8Da91C6Ef8fB35F',
    earnContractAddress: '0xf112252fB4b7c2cEA1A78659e8Da91C6Ef8fB35F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-mst-wftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'SpookySwap',
    assets: ['MST', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x152888854378201e173490956085c711f1DeD565',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x152888854378201e173490956085c711f1DeD565',
    createdAt: 1644813183,
  },
  {
    id: 'sushi-yfi-eth-eol',
    name: 'YFI-ETH LP',
    token: 'YFI-ETH SuLP',
    tokenDescription: 'Sushi',
    tokenAddress: '0xe3eD4237532E6095749a6C984bFCb449C2b86122',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiYFI-ETH',
    earnedTokenAddress: '0x38d58bd9E6DCA0bA4B6512db7f98aB37C2b7cEbA',
    earnContractAddress: '0x38d58bd9E6DCA0bA4B6512db7f98aB37C2b7cEbA',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-yfi-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'SushiSwap',
    withdrawalFee: '0%',
    assets: ['YFI', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x29b0Da86e484E1C0029B56e817912d778aC0EC69/0x74b23882a30290451A17c44f4F05243b6b58C76d',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x74b23882a30290451A17c44f4F05243b6b58C76d&outputCurrency=0x29b0Da86e484E1C0029B56e817912d778aC0EC69',
    createdAt: 1644347692,
  },
  {
    id: 'spirit-ftm-dai-eol',
    name: 'DAI-FTM LP',
    token: 'DAI-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0xdbc490b47508D31c9EC44aFB6e132AD01C61A02c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritFTM-DAI',
    earnedTokenAddress: '0x95a38faee2B13FE450BeF051b01672d65422e4e8',
    earnContractAddress: '0x95a38faee2B13FE450BeF051b01672d65422e4e8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-dai',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'SpiritSwap',
    assets: ['DAI', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/FTM/0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    createdAt: 1644340948,
  },
  {
    id: 'spirit-ftm-wshec',
    name: 'wsHEC-FTM LP',
    token: 'wsHEC-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0xE1fd274Ef08D50C3ecdaEe90c322b6c2342AE5DE',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritFTM-wsHEC',
    earnedTokenAddress: '0xBDC6830635BF9098b5c1d783c57a3C143826b38F',
    earnContractAddress: '0xBDC6830635BF9098b5c1d783c57a3C143826b38F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-wshec',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['wsHEC', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0x94CcF60f700146BeA8eF7832820800E2dFa92EdA',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/FTM/0x94CcF60f700146BeA8eF7832820800E2dFa92EdA',
    createdAt: 1644337415,
  },
  {
    id: 'spirit-ftm-multi-eol',
    name: 'MULTI-FTM LP',
    token: 'MULTI-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x15aFDbDb27767d58A58459ae159814b6bBe6f506',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritFTM-MULTI',
    earnedTokenAddress: '0x35564A47e75bfAF2ad912dfaB0E84066880a3B92',
    earnContractAddress: '0x35564A47e75bfAF2ad912dfaB0E84066880a3B92',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-multi',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'SpiritSwap',
    assets: ['MULTI', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0x9Fb9a33956351cf4fa040f65A13b835A3C8764E3',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/FTM/0x9Fb9a33956351cf4fa040f65A13b835A3C8764E3',
    createdAt: 1644338119,
  },
  {
    id: 'sushi-wftm-link-eol',
    name: 'LINK-FTM LP',
    token: 'LINK-FTM SuLP',
    tokenDescription: 'Sushi',
    tokenAddress: '0x1Ca86e57103564F47fFCea7259a6ce8Cc1301549',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiWFTM-LINK',
    earnedTokenAddress: '0x912d3e6c4d3CE89Ac85e3FbB22631D5E85D5158a',
    earnContractAddress: '0x912d3e6c4d3CE89Ac85e3FbB22631D5E85D5158a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-wftm-link',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'SushiSwap',
    withdrawalFee: '0%',
    assets: ['FTM', 'LINK'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0xb3654dc3D10Ea7645f8319668E8F54d2574FBdC8',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83&outputCurrency=0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8',
    createdAt: 1643801084,
  },
  {
    id: 'spirit-ftm-spell-eol',
    name: 'SPELL-FTM LP',
    token: 'SPELL-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x19d4092635740699B6E4735701742740e235165A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritFTM-SPELL',
    earnedTokenAddress: '0x321e12265BB425fAAe662f0cE5b12667F6A1C38A',
    earnContractAddress: '0x321e12265BB425fAAe662f0cE5b12667F6A1C38A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-spell',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'SpiritSwap',
    assets: ['SPELL', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0x468003B688943977e6130F4F68F23aad939a1040',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/FTM/0x468003B688943977e6130F4F68F23aad939a1040',
    createdAt: 1644170237,
  },
  {
    id: 'spirit-ftm-jewel',
    name: 'JEWEL-FTM LP',
    token: 'JEWEL-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x782b3e90d85b72fDD3A15dE534fD0DC9D5Ae46E7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritFTM-JEWEL',
    earnedTokenAddress: '0x2D68D7759B61CE73C03F3b48fa15b23d2b5475EE',
    earnContractAddress: '0x2D68D7759B61CE73C03F3b48fa15b23d2b5475EE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-jewel',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['JEWEL', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0xD97F9674E2597e7a252de4875985f4385B9608fB',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/FTM/0xD97F9674E2597e7a252de4875985f4385B9608fB',
    createdAt: 1644171366,
  },
  {
    id: 'spirit-ftm-pills-eol',
    name: 'PILLS-FTM LP',
    token: 'PILLS-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x9C775D3D66167685B2A3F4567B548567D2875350',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritFTM-PILLS',
    earnedTokenAddress: '0xB9aA08Bf789cDe3312F453B23c94b40be1F87613',
    earnContractAddress: '0xB9aA08Bf789cDe3312F453B23c94b40be1F87613',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-pills',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'SpiritSwap',
    assets: ['PILLS', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0xB66b5D38E183De42F21e92aBcAF3c712dd5d6286',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/FTM/0xB66b5D38E183De42F21e92aBcAF3c712dd5d6286',
    createdAt: 1644248016,
  },
  {
    id: 'spirit-ftm-eth-eol',
    name: 'ETH-FTM LP',
    token: 'ETH-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x613BF4E46b4817015c01c6Bb31C7ae9edAadc26e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritFTM-ETH',
    earnedTokenAddress: '0x12b5A9154d079d9bD3Fe5BF923Bc9CFa36439d18',
    earnContractAddress: '0x12b5A9154d079d9bD3Fe5BF923Bc9CFa36439d18',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-eth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'SpiritSwap',
    assets: ['ETH', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0x74b23882a30290451A17c44f4F05243b6b58C76d',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/FTM/0x74b23882a30290451A17c44f4F05243b6b58C76d',
    createdAt: 1644248760,
  },
  {
    id: 'boo-usdc-fs-eol',
    name: 'FS-USDC LP',
    token: 'FS-USDC LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x395215AaE81C5D266dCa89Ce82b5940f7F5C193e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooUSDC-FS',
    earnedTokenAddress: '0x7Bf927826a587050a908320027C8a62cd0c54B04',
    earnContractAddress: '0x7Bf927826a587050a908320027C8a62cd0c54B04',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-usdc-fs',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'SpookySwap',
    assets: ['FS', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&outputCurrency=0xC758295Cd1A564cdb020a78a681a838CF8e0627D',
    addLiquidityUrl:
      'https://spooky.fi/#/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0xC758295Cd1A564cdb020a78a681a838CF8e0627D',
    createdAt: 1644274576,
  },
  {
    id: 'boo-font-ftm-eol',
    name: 'FONT-FTM LP',
    token: 'FONT-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x1C8D76074619BCA749b521343Ce16bb96d9F3614',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooFONT-FTM',
    earnedTokenAddress: '0x90aa5be46B99eA5fF9aEa1C8115cB13caca20d04',
    earnContractAddress: '0x90aa5be46B99eA5fF9aEa1C8115cB13caca20d04',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-font-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'SpookySwap',
    assets: ['FONT', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0xbbc4a8d076f4b1888fec42581b6fc58d242cf2d5',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0xbbc4A8d076F4B1888fec42581B6fc58d242CF2D5',
    createdAt: 1644614666,
  },
  {
    id: 'spirit-ftm-frax',
    name: 'FRAX-FTM LP',
    token: 'FRAX-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x7ed0cdDB9BB6c6dfEa6fB63E117c8305479B8D7D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritFTM-FRAX',
    earnedTokenAddress: '0x0EB510C4Af8AD8A7E658Dee5F4DA36AaF9B1A146',
    earnContractAddress: '0x0EB510C4Af8AD8A7E658Dee5F4DA36AaF9B1A146',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-frax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['FRAX', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0xdc301622e621166BD8E82f2cA0A26c13Ad0BE355',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/FTM/0xdc301622e621166BD8E82f2cA0A26c13Ad0BE355',
    createdAt: 1644000284,
  },
  {
    id: 'spirit-ftm-mai',
    name: 'MAI-FTM LP',
    token: 'MAI-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x51Eb93ECfEFFbB2f6fE6106c4491B5a0B944E8bd',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritFTM-MAI',
    earnedTokenAddress: '0x256eB6bddadF04750C71b1d9701c64F011Aaa559',
    earnContractAddress: '0x256eB6bddadF04750C71b1d9701c64F011Aaa559',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-mai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['MAI', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0xfB98B335551a418cD0737375a2ea0ded62Ea213b',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/FTM/0xfB98B335551a418cD0737375a2ea0ded62Ea213b',
    createdAt: 1644003891,
  },
  {
    id: 'spirit-ftm-gohm',
    name: 'gOHM-FTM LP',
    token: 'gOHM-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0xae9BBa22E87866e48ccAcFf0689AFaa41eB94995',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritFTM-gOHM',
    earnedTokenAddress: '0xa031e6F2Acf9fE197C4A22c3fC584bFD4932F476',
    earnContractAddress: '0xa031e6F2Acf9fE197C4A22c3fC584bFD4932F476',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-gohm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['gOHM', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0x91fa20244Fb509e8289CA630E5db3E9166233FDc',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/FTM/0x91fa20244Fb509e8289CA630E5db3E9166233FDc',
    createdAt: 1644011850,
  },
  {
    id: '2omb-2omb-2share-eol',
    name: '2OMB-2SHARE LP',
    token: '2OMB-2SHARE LP',
    tokenDescription: 'SpookySwap (2omb)',
    tokenAddress: '0xd9B5f00d183df52D717046521152303129F088DD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'moo2omb2OMB-2SHARE',
    earnedTokenAddress: '0x0c6B9982B5B18f6D77E55fC754DB6182ae2D847f',
    earnContractAddress: '0x0c6B9982B5B18f6D77E55fC754DB6182ae2D847f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: '2omb-2omb-2share',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['2OMB', '2SHARES'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://spooky.fi/#/add/0x7a6e4E3CC2ac9924605DCa4bA31d1831c84b44aE/0xc54A1684fD1bef1f077a336E6be4Bd9a3096a6Ca',
    buyTokenUrl:
      'https://spooky.fi/#/swap?inputCurrency=0x7a6e4E3CC2ac9924605DCa4bA31d1831c84b44aE&outputCurrency=0xc54A1684fD1bef1f077a336E6be4Bd9a3096a6Ca',
    createdAt: 1644007618,
  },
  {
    id: 'spirit-ftm-spirit',
    name: 'SPIRIT-FTM LP',
    token: 'SPIRIT-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x30748322B6E34545DBe0788C421886AEB5297789',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritFTM-SPIRIT',
    earnedTokenAddress: '0x415ADafbfBbe183E10c059dEbf283C5428cb6628',
    earnContractAddress: '0x415ADafbfBbe183E10c059dEbf283C5428cb6628',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-spirit',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['SPIRIT', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0x5Cc61A78F164885776AA610fb0FE1257df78E59B',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/0x5Cc61A78F164885776AA610fb0FE1257df78E59B',
    createdAt: 1643747530,
  },
  {
    id: 'beets-demeters-degree-eol',
    name: 'Demeters Degree',
    token: 'Demeters Degree',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0xf7bF0f161d3240488807FFa23894452246049916',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetDemetersDegree',
    earnedTokenAddress: '0x283277751369c6012D9C4384e1779b2a8672F1d7',
    earnContractAddress: '0x283277751369c6012D9C4384e1779b2a8672F1d7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-demeters-degree',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Beethoven X',
    assets: ['gOHM', 'FTM'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_HIGH', 'MCAP_SMALL', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://beets.fi/#/pool/0xf7bf0f161d3240488807ffa23894452246049916000200000000000000000198',
    createdAt: 1643849905,
  },
  {
    id: '0xdao-xscream-eol',
    logo: 'single-assets/xSCREAM.png',
    name: 'xSCREAM',
    token: 'xSCREAM',
    tokenDescription: '0xDAO',
    tokenAddress: '0xe3D17C7e840ec140a7A51ACA351a482231760824',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooOXDxSCREAM',
    earnedTokenAddress: '0x7926eA7aba64AF38297b832DEc713804f99039fD',
    earnContractAddress: '0x7926eA7aba64AF38297b832DEc713804f99039fD',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'xSCREAM',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['xSCREAM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'NO_AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://scream.sh/stake',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0xe0654C8e6fd4D733349ac7E09f6f23DA256bF475',
    createdAt: 1643612827,
  },
  {
    id: '0xdao-xboo-eol',
    logo: 'single-assets/xBOO.png',
    name: 'xBOO',
    token: 'xBOO',
    tokenDescription: '0xDAO',
    tokenAddress: '0xa48d959AE2E88f1dAA7D5F611E01908106dE7598',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooOXDxBOO',
    earnedTokenAddress: '0xE95f98631895bB9657e37906ea4c61b272BddeB0',
    earnContractAddress: '0xE95f98631895bB9657e37906ea4c61b272BddeB0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'xBOO',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['xBOO'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_SMALL',
      'NO_AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/pools',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE',
    createdAt: 1643099828,
  },
  {
    id: 'scream-ftm',
    logo: 'single-assets/FTM.png',
    name: 'FTM',
    token: 'FTM',
    tokenDescription: 'Scream',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooScreamFTM',
    earnedTokenAddress: '0x49c68eDb7aeBd968F197121453e41b8704AcdE0C',
    earnContractAddress: '0x49c68eDb7aeBd968F197121453e41b8704AcdE0C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WFTM',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Scream',
    assets: ['FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0%',
    buyTokenUrl: 'https://spooky.fi/#/swap?outputCurrency=FTM',
    createdAt: 1628734094,
  },
  {
    id: '0xdao-oxd-eol',
    logo: 'single-assets/OXD.png',
    name: 'OXD',
    token: 'OXD',
    tokenDescription: '0xDAO',
    tokenAddress: '0xc165d941481e68696f43EE6E99BFB2B23E0E3114',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooOXD',
    earnedTokenAddress: '0x59a7e9F6B369aA88A7EABfbc43D3EF72DEf4bd81',
    earnContractAddress: '0x59a7e9F6B369aA88A7EABfbc43D3EF72DEf4bd81',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'OXD',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['OXD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'NO_AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0xc165d941481e68696f43EE6E99BFB2B23E0E3114',
    createdAt: 1643104856,
  },
  {
    id: 'sushi-usdc-wftm',
    name: 'USDC-FTM LP',
    token: 'USDC-FTM SuLP',
    tokenDescription: 'Sushi',
    tokenAddress: '0xA48869049e36f8Bfe0Cc5cf655632626988c0140',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiUSDC-FTM',
    earnedTokenAddress: '0xb870e4755C737D2753D7298D0e70344077905Ed5',
    earnContractAddress: '0xb870e4755C737D2753D7298D0e70344077905Ed5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-usdc-wftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    withdrawalFee: '0%',
    assets: ['USDC', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83&outputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    createdAt: 1642944703,
  },
  {
    id: 'sushi-wftm-eth',
    name: 'ETH-FTM LP',
    token: 'ETH-FTM SuLP',
    tokenDescription: 'Sushi',
    tokenAddress: '0x3d0BD54c48C2C433ea6fed609Cc3d5Fb7A77622B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiETH-FTM',
    earnedTokenAddress: '0xd6D138fb65c2a68E728D70EfbAA54c794B73B6A0',
    earnContractAddress: '0xd6D138fb65c2a68E728D70EfbAA54c794B73B6A0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-wftm-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    withdrawalFee: '0%',
    assets: ['ETH', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x74b23882a30290451A17c44f4F05243b6b58C76d/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83&outputCurrency=0x74b23882a30290451A17c44f4F05243b6b58C76d',
    createdAt: 1642945488,
  },
  {
    id: 'sushi-fusdt-wftm',
    name: 'fUSDT-FTM LP',
    token: 'fUSDT-FTM SuLP',
    tokenDescription: 'Sushi',
    tokenAddress: '0xd019dd7C760c6431797d6ed170bFFb8FAee11F99',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushifUSDT-FTM',
    earnedTokenAddress: '0xBfC2C7323739A616f555d4410e4a4bF4Dad89e93',
    earnContractAddress: '0xBfC2C7323739A616f555d4410e4a4bF4Dad89e93',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-fusdt-wftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    withdrawalFee: '0%',
    assets: ['fUSDT', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x049d68029688eAbF473097a2fC38ef61633A3C7A/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x049d68029688eAbF473097a2fC38ef61633A3C7A&outputCurrency=0x74b23882a30290451A17c44f4F05243b6b58C76d',
    createdAt: 1642946143,
  },
  {
    id: 'sushi-eth-dai',
    name: 'ETH-DAI LP',
    token: 'ETH-DAI SuLP',
    tokenDescription: 'Sushi',
    tokenAddress: '0x71C8BceEcE3dAf9E27741D2Cc1F03170f862555f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiETH-DAI',
    earnedTokenAddress: '0x5Ed95BbE1743370f42657e7D8f8E02b4055145EF',
    earnContractAddress: '0x5Ed95BbE1743370f42657e7D8f8E02b4055145EF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-eth-dai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    withdrawalFee: '0%',
    assets: ['ETH', 'DAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x74b23882a30290451A17c44f4F05243b6b58C76d/0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x74b23882a30290451A17c44f4F05243b6b58C76d&outputCurrency=0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    createdAt: 1642946550,
  },
  {
    id: 'sushi-usdc-mim',
    name: 'MIM-USDC LP',
    token: 'MIM-USDC SuLP',
    tokenDescription: 'Sushi',
    tokenAddress: '0xFFdc0531288dc91C1F49Db03A90Ed84725E9eDa7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiMIM-USDC',
    earnedTokenAddress: '0x8f4F3cF7ECbfbD68DEC61F5F022f5a6655F5E3e0',
    earnContractAddress: '0x8f4F3cF7ECbfbD68DEC61F5F022f5a6655F5E3e0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-usdc-mim',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    withdrawalFee: '0%',
    assets: ['MIM', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0x82f0B8B456c1A451378467398982d4834b6829c1',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&outputCurrency=0x82f0B8B456c1A451378467398982d4834b6829c1',
    createdAt: 1642946810,
  },
  {
    id: 'sushi-btc-eth',
    name: 'ETH-BTC LP',
    token: 'ETH-BTC SuLP',
    tokenDescription: 'Sushi',
    tokenAddress: '0x33e29a9eBdD370a8D50656e822aBFD3A910dA1b6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiETH-BTC',
    earnedTokenAddress: '0x4B541D2D395Cb943111A2a7CA6186f9C2901BAFA',
    earnContractAddress: '0x4B541D2D395Cb943111A2a7CA6186f9C2901BAFA',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-btc-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    withdrawalFee: '0.01%',
    assets: ['ETH', 'WBTC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x321162Cd933E2Be498Cd2267a90534A804051b11/0x74b23882a30290451A17c44f4F05243b6b58C76d',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x321162Cd933E2Be498Cd2267a90534A804051b11&outputCurrency=0x74b23882a30290451A17c44f4F05243b6b58C76d',
    createdAt: 1642947064,
  },
  {
    id: 'sushi-wftm-sushi',
    name: 'SUSHI-FTM LP',
    token: 'SUSHI-FTM SuLP',
    tokenDescription: 'Sushi',
    tokenAddress: '0x49D2e0DC99C7358D7A9A8633Bf6df111D0EE7F65',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiSUSHI-FTM',
    earnedTokenAddress: '0x5D060698F179E7D2233480A44d6D3979e4Ae9e7f',
    earnContractAddress: '0x5D060698F179E7D2233480A44d6D3979e4Ae9e7f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-wftm-sushi',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    withdrawalFee: '0%',
    assets: ['SUSHI', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0xae75A438b2E0cB8Bb01Ec1E1e376De11D44477CC',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83&outputCurrency=0xae75A438b2E0cB8Bb01Ec1E1e376De11D44477CC',
    createdAt: 1642948006,
  },
  {
    id: '0xdao-wftm-eol',
    logo: 'single-assets/FTM.png',
    name: 'FTM',
    token: 'FTM',
    tokenDescription: '0xDAO',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooOXDFTM',
    earnedTokenAddress: '0x3D6AA308a59311D57456c2E968AdC1Dd3628869a',
    earnContractAddress: '0x3D6AA308a59311D57456c2E968AdC1Dd3628869a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WFTM',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'NO_AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl: 'https://spooky.fi/#/swap?outputCurrency=FTM',
    createdAt: 1642887314,
  },
  {
    id: '0xdao-usdc-eol',
    logo: 'single-assets/USDC.svg',
    name: 'USDC',
    token: 'USDC',
    tokenDescription: '0xDAO',
    tokenAddress: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    tokenDecimals: 6,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooOXDUSDC',
    earnedTokenAddress: '0xc27bF3Bbafee07a8d91048a6bF665286461F07c2',
    earnContractAddress: '0xc27bF3Bbafee07a8d91048a6bF665286461F07c2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDC',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'NO_AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    createdAt: 1642877204,
  },
  {
    id: '0xdao-dai-eol',
    logo: 'single-assets/DAI.svg',
    name: 'DAI',
    token: 'DAI',
    tokenDescription: '0xDAO',
    tokenAddress: '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooOXDDAI',
    earnedTokenAddress: '0x80274861F0EB0a3dad0688040DBEA74b850C6b8c',
    earnContractAddress: '0x80274861F0EB0a3dad0688040DBEA74b850C6b8c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'DAI',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['DAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'NO_AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    createdAt: 1642877428,
  },
  {
    id: '0xdao-mim-eol',
    logo: 'single-assets/MIM.png',
    name: 'MIM',
    token: 'MIM',
    tokenDescription: '0xDAO',
    tokenAddress: '0x82f0B8B456c1A451378467398982d4834b6829c1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooOXDMIM',
    earnedTokenAddress: '0x06a435AB175554c26840b96E88dE161C2e1901E4',
    earnContractAddress: '0x06a435AB175554c26840b96E88dE161C2e1901E4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'MIM',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['MIM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'NO_AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x82f0B8B456c1A451378467398982d4834b6829c1',
    createdAt: 1642880855,
  },
  {
    id: '0xdao-tomb-eol',
    logo: 'single-assets/TOMB.png',
    name: 'TOMB',
    token: 'TOMB',
    tokenDescription: '0xDAO',
    tokenAddress: '0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooOXDTOMB',
    earnedTokenAddress: '0xF76e11b2e67169D2b835E7DE8755b37747A45D6D',
    earnContractAddress: '0xF76e11b2e67169D2b835E7DE8755b37747A45D6D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'TOMB',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['TOMB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'NO_AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
    createdAt: 1642865861,
  },
  {
    id: '0xdao-wbtc-eol',
    logo: 'single-assets/WBTC.svg',
    name: 'WBTC',
    token: 'WBTC',
    tokenDescription: '0xDAO',
    tokenAddress: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
    tokenDecimals: 8,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooOXDBTC',
    earnedTokenAddress: '0xa8dCAEd8C1eb9c37653Ac7f9060e90BdA0256553',
    earnContractAddress: '0xa8dCAEd8C1eb9c37653Ac7f9060e90BdA0256553',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WBTC',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['WBTC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'NO_AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x321162Cd933E2Be498Cd2267a90534A804051b11',
    createdAt: 1642875469,
  },
  {
    id: '0xdao-weth-eol',
    logo: 'single-assets/ETH.svg',
    name: 'ETH',
    token: 'ETH',
    tokenDescription: '0xDAO',
    tokenAddress: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooOXDETH',
    earnedTokenAddress: '0x3C9e374DE5F1763279e22df3194C87544fdEB7E1',
    earnContractAddress: '0x3C9e374DE5F1763279e22df3194C87544fdEB7E1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'ETH',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'NO_AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x74b23882a30290451A17c44f4F05243b6b58C76d',
    createdAt: 1642860948,
  },
  {
    id: 'oxd-oxd-usdc-eol',
    name: 'OXD-USDC LP',
    token: 'OXD-USDC LP',
    tokenDescription: 'SpookySwap (0xDAO)',
    tokenAddress: '0xD5fa400a24EB2EA55BC5Bd29c989E70fbC626FfF',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooOXDOXD-USDC',
    earnedTokenAddress: '0x350f0E29d67Bc6745D28b17554CFB5F53bBe90ac',
    earnContractAddress: '0x350f0E29d67Bc6745D28b17554CFB5F53bBe90ac',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'oxd-oxd-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['OXD', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'NO_AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://spooky.fi/#/add/0xc165d941481e68696f43EE6E99BFB2B23E0E3114/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    buyTokenUrl:
      'https://spooky.fi/#/swap?inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&outputCurrency=0xc165d941481e68696f43EE6E99BFB2B23E0E3114',
    createdAt: 1642811156,
  },
  {
    id: 'beets-spooky-tempered-clavier-eol',
    logo: 'fantom/The Spooky-Tempered Clavier.png',
    name: 'The Spooky-Tempered Clavier',
    token: 'The Spooky-Tempered Clavier',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0xfA901cAdCAf38cd533E8B1f693D090Fc7005658e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetTemperedClavier',
    earnedTokenAddress: '0xFd393C038DeaaF14D3186d6DA638D2dd6bFeD90B',
    earnContractAddress: '0xFd393C038DeaaF14D3186d6DA638D2dd6bFeD90B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-spooky-tempered-clavier',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Beethoven X',
    assets: ['BOO', 'USDC', 'FTM'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_HIGH', 'MCAP_LARGE', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://beets.fi/#/pool/0xfa901cadcaf38cd533e8b1f693d090fc7005658e000100000000000000000165',
    createdAt: 1642690961,
  },
  {
    id: 'boo-btc-eth',
    name: 'WBTC-ETH LP',
    token: 'WBTC-ETH LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xEc454EdA10accdD66209C57aF8C12924556F3aBD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooBTC-ETH',
    earnedTokenAddress: '0x1313b9C550bbDF55Fc06f63a41D8BDC719d056A6',
    earnContractAddress: '0x1313b9C550bbDF55Fc06f63a41D8BDC719d056A6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-btc-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['WBTC', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0.01%',
    addLiquidityUrl:
      'https://spooky.fi/#/add/0x74b23882a30290451A17c44f4F05243b6b58C76d/0x321162Cd933E2Be498Cd2267a90534A804051b11',
    buyTokenUrl:
      'https://spooky.fi/#/swap?inputCurrency=0x74b23882a30290451A17c44f4F05243b6b58C76d&outputCurrency=0x321162Cd933E2Be498Cd2267a90534A804051b11',
    createdAt: 1642672280,
  },
  {
    id: 'boo-wftm-beets',
    name: 'BEETS-FTM LP',
    token: 'BEETS-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x648a7452DA25B4fB4BDB79bADf374a8f8a5ea2b5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooFTM-BEETS',
    earnedTokenAddress: '0xBdd229d2bcf8f31Fa90FD04c314078dAF4e2A0A4',
    earnContractAddress: '0xBdd229d2bcf8f31Fa90FD04c314078dAF4e2A0A4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-beets',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['BEETS', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0xF24Bcf4d1e507740041C9cFd2DddB29585aDCe1e',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0xF24Bcf4d1e507740041C9cFd2DddB29585aDCe1e',
    createdAt: 1642634017,
  },
  {
    id: 'spirit-fusdt-usdc',
    name: 'fUSDT-USDC LP',
    token: 'fUSDT-USDC SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0xe7F86CEf8FEf60ce5050899D1F8e465C00D04a79',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritUSDC-fUSDT',
    earnedTokenAddress: '0xFbfa12b10626151fB7735aBe4af5277104b480ee',
    earnContractAddress: '0xFbfa12b10626151fB7735aBe4af5277104b480ee',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-fusdt-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['fUSDT', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    createdAt: 1642557662,
  },
  {
    id: 'spirit-frax-usdc',
    name: 'FRAX-USDC LP',
    token: 'FRAX-USDC SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x1478AEC7896e40aE5fB858C77D389F0B3e6CbC5d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritUSDC-FRAX',
    earnedTokenAddress: '0x11d4D27364952b972AC74Fb6676DbbFa67fDa72F',
    earnContractAddress: '0x11d4D27364952b972AC74Fb6676DbbFa67fDa72F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-frax-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['FRAX', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'ALGO_STABLE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0xdc301622e621166BD8E82f2cA0A26c13Ad0BE355',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/0xdc301622e621166BD8E82f2cA0A26c13Ad0BE355',
    createdAt: 1642560206,
  },
  {
    id: 'spirit-mim-usdc',
    name: 'MIM-USDC LP',
    token: 'MIM-USDC SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0xc19C7615237f770179ed93d89126478c60742664',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritUSDC-MIM',
    earnedTokenAddress: '0x3aA3341433E8efE228F75777213787f1a51Ab09d',
    earnContractAddress: '0x3aA3341433E8efE228F75777213787f1a51Ab09d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-mim-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['MIM', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0x82f0B8B456c1A451378467398982d4834b6829c1',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/0x82f0B8B456c1A451378467398982d4834b6829c1',
    createdAt: 1642560383,
  },
  {
    id: '2omb-2omb-ftm-eol',
    name: '2OMB-FTM LP',
    token: '2OMB-FTM LP',
    tokenDescription: 'SpookySwap (2omb)',
    tokenAddress: '0xbdC7DFb7B88183e87f003ca6B5a2F81202343478',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'moo2omb2OMB-FTM',
    earnedTokenAddress: '0xf3A72885cB383543AEE60f44Ca51C760f0bC3b9b',
    earnContractAddress: '0xf3A72885cB383543AEE60f44Ca51C760f0bC3b9b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: '2omb-2omb-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['2OMB', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x7a6e4E3CC2ac9924605DCa4bA31d1831c84b44aE',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x7a6e4E3CC2ac9924605DCa4bA31d1831c84b44aE',
    createdAt: 1642150805,
  },
  {
    id: '2omb-2share-ftm-eol',
    name: '2SHARES-FTM LP',
    token: '2SHARES-FTM LP',
    tokenDescription: 'SpookySwap (2omb)',
    tokenAddress: '0x6398ACBBAB2561553a9e458Ab67dCFbD58944e52',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'moo2omb2SHARE-FTM',
    earnedTokenAddress: '0x03668Bd5dc63B1e15c39619b599091A4f68cAFB3',
    earnContractAddress: '0x03668Bd5dc63B1e15c39619b599091A4f68cAFB3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: '2omb-2share-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['2SHARES', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0xc54A1684fD1bef1f077a336E6be4Bd9a3096a6Ca',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0xc54A1684fD1bef1f077a336E6be4Bd9a3096a6Ca',
    createdAt: 1642152342,
  },
  {
    id: 'boo-wftm-brush',
    name: 'BRUSH-FTM LP',
    token: 'BRUSH-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x4EE115137ac73A3e5F99598564905465C101b11F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooFTM-BRUSH',
    earnedTokenAddress: '0xC62Bec8168404CcDE75Db95F5cED342C1AD770a5',
    earnContractAddress: '0xC62Bec8168404CcDE75Db95F5cED342C1AD770a5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-brush',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['BRUSH', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x85dec8c4B2680793661bCA91a8F129607571863d',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x85dec8c4B2680793661bCA91a8F129607571863d',
    createdAt: 1642291662,
  },
  {
    id: 'boo-kek-ftm-eol',
    name: 'KEK-FTM LP',
    token: 'KEK-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xf3582dbAEbb4287B462f00d308911621a960A5FD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooFTM-KEK',
    earnedTokenAddress: '0xB29929fC5bdD3769d94543D17E4C49fC555E9228',
    earnContractAddress: '0xB29929fC5bdD3769d94543D17E4C49fC555E9228',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-kek-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'SpookySwap',
    assets: ['KEK', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x627524d78B4fC840C887ffeC90563c7A42b671fD',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x627524d78B4fC840C887ffeC90563c7A42b671fD',
    createdAt: 1642139577,
  },
  {
    id: 'boo-inv-wftm-eol',
    name: 'INV-FTM LP',
    token: 'INV-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x9380485d65Fb628Ccd87Ddec4854F5306554d9d9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooINV-FTM',
    earnedTokenAddress: '0xFE0FC1710d9d723eA0876315437bD140fc952a2c',
    earnContractAddress: '0xFE0FC1710d9d723eA0876315437bD140fc952a2c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-inv-wftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'SpookySwap',
    assets: ['INV', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0xb84527D59b6Ecb96F433029ECc890D4492C5dCe1',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0xb84527D59b6Ecb96F433029ECc890D4492C5dCe1',
    createdAt: 1642097531,
  },
  {
    id: 'beets-steady-beets-2',
    name: 'Steady Beets 2',
    token: 'Steady Beets 2',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0xeCAa1cBd28459d34B766F9195413Cb20122Fb942',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetSteadyBeets2',
    earnedTokenAddress: '0xF08134f99EcBfdf7543BF56873324F697bA2a858',
    earnContractAddress: '0xF08134f99EcBfdf7543BF56873324F697bA2a858',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-steady-beets-2',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beethoven X',
    assets: ['USDC', 'DAI'],
    risks: [
      'COMPLEXITY_LOW',
      'NEW_STRAT',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://beets.fi/#/pool/0xecaa1cbd28459d34b766f9195413cb20122fb942000200000000000000000120',
    createdAt: 1641982117,
  },
  {
    id: 'beets-two-became-one',
    logo: 'fantom/When Two Became One.png',
    name: 'When Two Became One (Hundred)',
    token: 'When Two Became One (Hundred)',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0xD57Cda2caEBb9B64BB88905C4dE0F0Da217a77d7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetTwoBecameOne',
    earnedTokenAddress: '0xe48d1b1074e1B51c970674DB05669d3C50269108',
    earnContractAddress: '0xe48d1b1074e1B51c970674DB05669d3C50269108',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-two-became-one',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beethoven X',
    assets: ['USDC', 'HND', 'FTM'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_HIGH', 'MCAP_MICRO', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://beets.fi/#/pool/0xd57cda2caebb9b64bb88905c4de0f0da217a77d7000100000000000000000073',
    createdAt: 1641410484,
  },
  {
    id: 'beets-dante-symphony',
    logo: 'fantom/Dante Symphony.png',
    name: 'Dante Symphony',
    token: 'Dante Symphony',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0xc042EF6cA08576BdFb57d3055A7654344fd153E4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetDanteSymphony',
    earnedTokenAddress: '0xDc1b4e4Ff41bC392f1BBCdcE699d5aB3E3de9E66',
    earnContractAddress: '0xDc1b4e4Ff41bC392f1BBCdcE699d5aB3E3de9E66',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-dante-symphony',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beethoven X',
    assets: ['FTM', 'WBTC', 'ETH', 'WSTA', 'BEETS'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_HIGH', 'MCAP_MICRO', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beets.fi/#/pool/0xc042ef6ca08576bdfb57d3055a7654344fd153e400010000000000000000003a',
    createdAt: 1641988564,
  },
  {
    id: 'beets-ode-to-joy-eol',
    name: 'Ode To Joy',
    token: 'Ode To Joy',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0x5dD0D5D6c13E9302271Be0DfAde2b9Fd708E5dDB',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetOdeToJoy',
    earnedTokenAddress: '0x5C4d01b3d77C50278684b8b275874E2232706FE2',
    earnContractAddress: '0x5C4d01b3d77C50278684b8b275874E2232706FE2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-ode-to-joy',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Beethoven X',
    assets: ['WBTC', 'ETH'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_HIGH', 'MCAP_LARGE', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beets.fi/#/pool/0x5dd0d5d6c13e9302271be0dfade2b9fd708e5ddb0002000000000000000000e7',
    createdAt: 1641989971,
  },
  {
    id: 'beets-wagmi-index-eol',
    logo: 'fantom/WAGMI Index.png',
    name: 'WAGMI Index',
    token: 'WAGMI Index',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0x713ee620a7702b79eA5413096A90702244FE4532',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetWAGMIIndex',
    earnedTokenAddress: '0xf845a4bf053020AA9826b130248E921Ad0eb1037',
    earnContractAddress: '0xf845a4bf053020AA9826b130248E921Ad0eb1037',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-wagmi-index',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Beethoven X',
    assets: ['FTM', 'SPELL', 'MIM', 'SUSHI', 'wMEMO', 'ICE'],
    risks: [
      'COMPLEXITY_LOW',
      'NEW_STRAT',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beets.fi/#/pool/0x713ee620a7702b79ea5413096a90702244fe4532000100000000000000000105',
    createdAt: 1641992405,
  },
  {
    id: 'boo-hnd-wftm-eol',
    name: 'HND-FTM LP',
    token: 'HND-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x16b6DeEE778bbcF427e40FfA3c14c4840Be10e6a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooHND-FTM',
    earnedTokenAddress: '0x68c39886eA459b4a59758F1e94c3d20C93d47133',
    earnContractAddress: '0x68c39886eA459b4a59758F1e94c3d20C93d47133',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-hnd-wftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'SpookySwap',
    assets: ['HND', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x10010078a54396F62c96dF8532dc2B4847d47ED3',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x10010078a54396F62c96dF8532dc2B4847d47ED3',
    createdAt: 1641572631,
  },
  {
    id: 'spartacadabra-lambda-crv-eol',
    logo: 'single-assets/LAMBDA.svg',
    name: 'LAMBDA/DAI/USDC',
    token: 'LAMBDA-2CRV',
    tokenDescription: 'Curve (Spartacadabra)',
    tokenAddress: '0x075C1D1d7E9E1aF077B0b6117C6eA06CD0a260b5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpartacdbrLAMBDA-2CRV',
    earnedTokenAddress: '0x453054B9C2CD3dF1c57E0866241f460B78eE3ebB',
    earnContractAddress: '0x453054B9C2CD3dF1c57E0866241f460B78eE3ebB',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-ftm-lambda',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'Spartacadabra',
    assets: ['LAMBDA', 'DAI', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0.01%',
    addLiquidityUrl: 'https://ftm.curve.fi/factory/35/deposit',
    createdAt: 1641548990,
  },
  {
    id: 'spartacadabra-charm-usdc-eol',
    name: 'CHARM-USDC',
    token: 'CHARM-USDC LP',
    tokenDescription: 'SpookySwap (Spartacadabra)',
    tokenAddress: '0xaCf56C6aadDc1408A11AbAb3140b90b57Fc6Aaf7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpartacdbrCHARM-USDC',
    earnedTokenAddress: '0x47BE147d28089b94a360051986fe5C18a80EFF32',
    earnContractAddress: '0x47BE147d28089b94a360051986fe5C18a80EFF32',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spartacadabra-charm-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'Spartacadabra',
    assets: ['CHARM', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0.1%',
    addLiquidityUrl:
      'https://spooky.fi/#/add/0x04068da6c83afcfa0e13ba15a6696662335d5b75/0x248cb87dda803028dfead98101c9465a2fbda0d4',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x248cb87dda803028dfead98101c9465a2fbda0d4',
    createdAt: 1641550679,
  },
  {
    id: 'beets-guqin-qi-2',
    logo: 'fantom/Guqin Qi 2.png',
    name: 'Guqin Qi 2',
    token: 'Guqin Qi 2',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0x2C580C6F08044D6dfACA8976a66C8fAddDBD9901',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetGuqinQi2',
    earnedTokenAddress: '0x1711b008DA67a18B3a5171B906Dae7812BEb147b',
    earnContractAddress: '0x1711b008DA67a18B3a5171B906Dae7812BEb147b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-guqin-qi-2',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beethoven X',
    assets: ['USDC', 'DAI', 'MAI'],
    risks: [
      'COMPLEXITY_LOW',
      'NEW_STRAT',
      'IL_NONE',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beets.fi/#/pool/0x2c580c6f08044d6dfaca8976a66c8fadddbd9901000000000000000000000038',
    createdAt: 1635363222,
  },
  {
    id: 'boo-wftm-matic',
    name: 'MATIC-FTM LP',
    token: 'MATIC-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x7051C6F0C1F1437498505521a3bD949654923fE1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooFTM-MATIC',
    earnedTokenAddress: '0x6709B167f862e2AeD920C2D98BC89E976DbD0615',
    earnContractAddress: '0x6709B167f862e2AeD920C2D98BC89E976DbD0615',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['MATIC', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x40DF1Ae6074C35047BFF66675488Aa2f9f6384F3',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x40DF1Ae6074C35047BFF66675488Aa2f9f6384F3',
    createdAt: 1640973310,
  },
  {
    id: 'popsicle-ice-ftm',
    name: 'ICE-FTM',
    token: 'ICE-FTM SLP',
    tokenDescription: 'SushiSwap (Popsicle)',
    tokenAddress: '0x84311ECC54D7553378c067282940b0fdfb913675',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPopsicleICE-FTM',
    earnedTokenAddress: '0xD419254D5b682AA68B6aa13b755789Bc9459c9b7',
    earnContractAddress: '0xD419254D5b682AA68B6aa13b755789Bc9459c9b7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'popsicle-ice-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Other',
    assets: ['ICE', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://app.sushi.com/add/ETH/0xf16e81dce15B08F326220742020379B855B87DF9',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0xf16e81dce15B08F326220742020379B855B87DF9',
    createdAt: 1640767060,
  },
  {
    id: 'beets-variation-theme-eol',
    logo: 'fantom/Variation Theme USDC.png',
    name: 'Variations On A Theme By USD Circle',
    token: 'Variations On A Theme By USD Circle',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0x8Bb1839393359895836688165f7c5878f8C81C5e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetVariationUSDC',
    earnedTokenAddress: '0xa25eC518E66D41195FB988b068D212d9a61aabc9',
    earnContractAddress: '0xa25eC518E66D41195FB988b068D212d9a61aabc9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-variation-theme',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Beethoven X',
    assets: ['USDC', 'asUSDC'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_NONE', 'MCAP_LARGE', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beets.fi/#/pool/0x8bb1839393359895836688165f7c5878f8c81c5e0002000000000000000000e1',
    createdAt: 1640629158,
  },
  {
    id: 'scream-spell',
    logo: 'single-assets/SPELL.png',
    name: 'SPELL',
    token: 'SPELL',
    tokenDescription: 'Scream',
    tokenAddress: '0x468003B688943977e6130F4F68F23aad939a1040',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooScreamSPELL',
    earnedTokenAddress: '0x5EB148A571B0B3D8b8eB46053545E7DF833898Dc',
    earnContractAddress: '0x5EB148A571B0B3D8b8eB46053545E7DF833898Dc',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'SPELL',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Scream',
    assets: ['SPELL'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0%',
    showWarning: false,
    warning: 'liquidity',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x468003B688943977e6130F4F68F23aad939a1040',
    createdAt: 1640646636,
  },
  {
    id: 'spirit-dai-usdc',
    name: 'DAI-USDC LP',
    token: 'DAI-USDC SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x9606D683d03f012DDa296eF0ae9261207C4A5847',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritUSDC-DAI',
    earnedTokenAddress: '0x5d2EF803D6e255eF4D1c66762CBc8845051B54dB',
    earnContractAddress: '0x5d2EF803D6e255eF4D1c66762CBc8845051B54dB',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-dai-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['DAI', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    createdAt: 1640070468,
  },
  {
    id: 'spirit-yfi-ftm-eol',
    name: 'YFI-FTM LP',
    token: 'YFI-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x4fc38a2735C7da1d71ccAbf6DeC235a7DA4Ec52C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritWFTM-YFI',
    earnedTokenAddress: '0xDe0f8aBF5077AD155dC99F5693AceE20C2689ba8',
    earnContractAddress: '0xDe0f8aBF5077AD155dC99F5693AceE20C2689ba8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-yfi-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'SpiritSwap',
    assets: ['YFI', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    retireReason: 'tvl',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0x29b0Da86e484E1C0029B56e817912d778aC0EC69',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/0x29b0Da86e484E1C0029B56e817912d778aC0EC69',
    createdAt: 1640392035,
  },
  {
    id: 'geist-mim',
    logo: 'single-assets/MIM.png',
    name: 'MIM',
    token: 'MIM',
    tokenDescription: 'Geist',
    tokenAddress: '0x82f0B8B456c1A451378467398982d4834b6829c1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooGeistMIM',
    earnedTokenAddress: '0xE7E08D82853dcf1057B2f8BCeF781d904602B6a0',
    earnContractAddress: '0xE7E08D82853dcf1057B2f8BCeF781d904602B6a0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'MIM',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Geist',
    assets: ['MIM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'NO_AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x82f0B8B456c1A451378467398982d4834b6829c1',
    createdAt: 1640092257,
  },
  {
    id: 'beets-battle-bands-eol',
    logo: 'fantom/Battle Bands.png',
    name: 'Battle Of The Bands',
    token: 'Battle Of The Bands',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0x9af1F0e9aC9C844A4a4439d446c1437807183075',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetBattleBands',
    earnedTokenAddress: '0x0139C853539bF1EDf221cf9d665F282C2701335a',
    earnContractAddress: '0x0139C853539bF1EDf221cf9d665F282C2701335a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-battle-bands',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Beethoven X',
    assets: ['FTM', 'MATIC', 'SOL', 'AVAX', 'LUNA', 'BNB'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_LOW', 'MCAP_LARGE', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beets.fi/#/pool/0x9af1f0e9ac9c844a4a4439d446c14378071830750001000000000000000000da',
    createdAt: 1640018890,
  },
  {
    id: 'geist-crv',
    logo: 'single-assets/CRV.png',
    name: 'CRV',
    token: 'CRV',
    tokenDescription: 'Geist',
    tokenAddress: '0x1E4F97b9f9F913c46F1632781732927B9019C68b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooGeistCRV',
    earnedTokenAddress: '0xedEb044BC7Ce11Dfa436CFA8Be43e6eB0d601814',
    earnContractAddress: '0xedEb044BC7Ce11Dfa436CFA8Be43e6eB0d601814',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'CRV',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Geist',
    assets: ['CRV'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'NO_AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x1E4F97b9f9F913c46F1632781732927B9019C68b',
    createdAt: 1639737670,
  },
  {
    id: 'boo-wftm-avax',
    name: 'AVAX-FTM LP',
    token: 'AVAX-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x5DF809e410d9CC577f0d01b4E623C567C7aD56c1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooAVAX-FTM',
    earnedTokenAddress: '0x6e08721cE0eB1a6776E7965beb5441267D1742c7',
    earnContractAddress: '0x6e08721cE0eB1a6776E7965beb5441267D1742c7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-avax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['AVAX', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x511D35c52a3C244E7b8bd92c0C297755FbD89212',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x511D35c52a3C244E7b8bd92c0C297755FbD89212',
    createdAt: 1639923705,
  },
  {
    id: 'boo-wftm-woo',
    name: 'WOO-FTM LP',
    token: 'WOO-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xa7010b3ba9efb1AF9Fa8a30efe74C16A93891775',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooWOO-FTM',
    earnedTokenAddress: '0xddb3F5F56AeA6B8cE12e2f603Fac6b8f0D99b78a',
    earnContractAddress: '0xddb3F5F56AeA6B8cE12e2f603Fac6b8f0D99b78a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-woo',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['WOO', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x6626c47c00F1D87902fc13EECfaC3ed06D5E8D8a',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x6626c47c00F1D87902fc13EECfaC3ed06D5E8D8a',
    createdAt: 1639923811,
  },
  {
    id: 'boo-wftm-ooe',
    name: 'OOE-FTM LP',
    token: 'OOE-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x0F941ac6b81E27afdB883244b5C537dCBa8A6fCe',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooOOE-FTM',
    earnedTokenAddress: '0x3AE6dA5958d3448B0eD34f615606c891cA56dC8F',
    earnContractAddress: '0x3AE6dA5958d3448B0eD34f615606c891cA56dC8F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-ooe',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['OOE', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x9d8F97A3C2f9f397B6D46Cbe2d39CC1D8Cf19010',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x9d8F97A3C2f9f397B6D46Cbe2d39CC1D8Cf19010',
    createdAt: 1639423285,
  },
  {
    id: 'boo-wftm-wshec',
    name: 'wsHEC-FTM LP',
    token: 'wsHEC-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x0BfE6f893a6bc443B575Ddf361A30f39aa03e59c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBoowsHEC-FTM',
    earnedTokenAddress: '0x52c35741e7DFEC24FBf8436187dffC44376264Fb',
    earnContractAddress: '0x52c35741e7DFEC24FBf8436187dffC44376264Fb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-wshec',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['wsHEC', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x94CcF60f700146BeA8eF7832820800E2dFa92EdA',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x94CcF60f700146BeA8eF7832820800E2dFa92EdA',
    createdAt: 1639423158,
  },
  {
    id: 'boo-wftm-wsspa-eol',
    name: 'wsSPA-FTM LP',
    token: 'wsSPA-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x011732f65e2f28C50F528e32420A2F69937b9e68',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBoowsSPA-FTM',
    earnedTokenAddress: '0x2649b0D997FC267154a542540aa90C09CBC7C480',
    earnContractAddress: '0x2649b0D997FC267154a542540aa90C09CBC7C480',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-wsspa',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'SpookySwap',
    assets: ['wsSPA', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x89346B51A54263cF2e92dA79B1863759eFa68692',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x89346B51A54263cF2e92dA79B1863759eFa68692',
    createdAt: 1639423010,
  },
  {
    id: 'boo-wftm-yoshi',
    name: 'YOSHI-FTM LP',
    token: 'YOSHI-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x6bB685358BC3991D9279562710F3a44B7e5F2D9b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooYOSHI-FTM',
    earnedTokenAddress: '0x0edf387CDe7f901186834D073c09a7c1D1651000',
    earnContractAddress: '0x0edf387CDe7f901186834D073c09a7c1D1651000',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-yoshi',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['YOSHI', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x3dc57B391262e3aAe37a08D91241f9bA9d58b570',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x3dc57B391262e3aAe37a08D91241f9bA9d58b570',
    createdAt: 1639422895,
  },
  {
    id: 'beets-double-dollar-fugue-eol',
    logo: 'fantom/double-dollar-fugue.png',
    name: 'Double Dollar Fugue',
    token: 'Double Dollar Fugue',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0xF3f0970BCA8ce7E9672d17a301b54D1485D93807',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetDoubleDollarFugue',
    earnedTokenAddress: '0x3246893C1F8c1Bf928121094ea4558c7B93Bdc9A',
    earnContractAddress: '0x3246893C1F8c1Bf928121094ea4558c7B93Bdc9A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-double-dollar-fugue',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Beethoven X',
    assets: ['USDC', 'MIM', 'UST'],
    risks: [
      'COMPLEXITY_LOW',
      'NEW_STRAT',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'ALGO_STABLE',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beets.fi/#/pool/0xf3f0970bca8ce7e9672d17a301b54d1485d938070000000000000000000000c8',
    createdAt: 1638539115,
  },
  {
    id: 'beets-solana-sonata',
    logo: 'fantom/solana-sonata.png',
    name: 'Solana Sonata',
    token: 'Solana Sonata',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0x41870439b607A29293D48f7c9da10e6714217624',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetSolanaSonata',
    earnedTokenAddress: '0x58511De7Dc12230096Feb1AAB420872Df0371A45',
    earnContractAddress: '0x58511De7Dc12230096Feb1AAB420872Df0371A45',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-solana-sonata',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beethoven X',
    assets: ['USDC', 'FTM', 'SOL'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_LOW', 'MCAP_LARGE', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beets.fi/#/pool/0x41870439b607a29293d48f7c9da10e67142176240001000000000000000000a4',
    createdAt: 1638210632,
  },
  {
    id: 'scream-tusd',
    logo: 'single-assets/TUSD.png',
    name: 'TUSD',
    token: 'TUSD',
    tokenDescription: 'Scream',
    tokenAddress: '0x9879aBDea01a879644185341F7aF7d8343556B7a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooScreamTUSD',
    earnedTokenAddress: '0x42ECfA11Db08FB3Bb0AAf722857be56FA8E57Dc0',
    earnContractAddress: '0x42ECfA11Db08FB3Bb0AAf722857be56FA8E57Dc0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'TUSD',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Scream',
    assets: ['TUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0%',
    showWarning: false,
    warning: 'liquidity',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x9879aBDea01a879644185341F7aF7d8343556B7a',
    createdAt: 1637875008,
  },
  {
    id: 'beets-phantom-dai-opera-eol',
    name: 'Phantom Dai Opera',
    token: 'Phantom Dai Opera',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0x63386eF152E1Ddef96c065636D6cC0165Ff33291',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetsDaiOpera',
    earnedTokenAddress: '0x876A3E8AFD0548930Ed9fb9741787c27986a613C',
    earnContractAddress: '0x876A3E8AFD0548930Ed9fb9741787c27986a613C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-phantom-dai-opera',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Beethoven X',
    assets: ['FTM', 'DAI'],
    risks: [
      'COMPLEXITY_LOW',
      'NEW_STRAT',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beets.fi/#/pool/0x63386ef152e1ddef96c065636d6cc0165ff332910002000000000000000000a1',
    createdAt: 1637704300,
  },
  {
    id: 'boo-usdc-tusd',
    name: 'TUSD-USDC LP',
    token: 'TUSD-USDC LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x12692B3bf8dd9Aa1d2E721d1a79efD0C244d7d96',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooUSDC-TUSD',
    earnedTokenAddress: '0x6F0169e452211Ee0146812C1cc111FB228480d42',
    earnContractAddress: '0x6F0169e452211Ee0146812C1cc111FB228480d42',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-usdc-tusd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['TUSD', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://spooky.fi/#/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0x9879aBDea01a879644185341F7aF7d8343556B7a',
    buyTokenUrl:
      'https://spooky.fi/#/swap?inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&outputCurrency=0x9879aBDea01a879644185341F7aF7d8343556B7a',
    createdAt: 1637658328,
  },
  {
    id: 'boo-wftm-joe',
    name: 'JOE-FTM LP',
    token: 'JOE-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xd518737Ff601c2A7C67F55EbbEb0a4e3fF5C0C35',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooWFTM-JOE',
    earnedTokenAddress: '0xD75805Ec0234AD335228e5E9f341d91f0F44010D',
    earnContractAddress: '0xD75805Ec0234AD335228e5E9f341d91f0F44010D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-joe',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['JOE', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x9F47F313ACFd4bdC52F4373b493EaE7d5aC5b765',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x9F47F313ACFd4bdC52F4373b493EaE7d5aC5b765',
    createdAt: 1637587170,
  },
  {
    id: 'spirit-spell-sspell-eol',
    name: 'sSPELL-SPELL',
    token: 'sSPELL-SPELL LP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x4f41D03631Ea4dC14016CcF90690d6D22b24C12D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritsSPELL-SPELL',
    earnedTokenAddress: '0xC90c00Dd1E53D56Ca4a0FdcA7940C33C0fcf02e5',
    earnContractAddress: '0xC90c00Dd1E53D56Ca4a0FdcA7940C33C0fcf02e5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-spell-sspell',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'SpiritSwap',
    assets: ['sSPELL', 'SPELL'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/0x468003B688943977e6130F4F68F23aad939a1040/0xbB29D2A58d880Af8AA5859e30470134dEAf84F2B',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/0xbB29D2A58d880Af8AA5859e30470134dEAf84F2B',
    createdAt: 1637179993,
  },
  {
    id: 'beets-baron-von-binance',
    logo: 'fantom/baron-von-binance.png',
    name: 'Baron von Binance',
    token: 'Baron von Binance',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0x5E65474c8400d0BB76eDBBE066B2D2E7b0b6e7fb',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetBaronVonBinance',
    earnedTokenAddress: '0x255C2c11C36bf269bB12a9B40e131869dD9aab5D',
    earnContractAddress: '0x255C2c11C36bf269bB12a9B40e131869dD9aab5D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-baron-von-binance',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beethoven X',
    assets: ['USDC', 'FTM', 'BNB'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_HIGH', 'MCAP_LARGE', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beethovenx.io/#/pool/0x5e65474c8400d0bb76edbbe066b2d2e7b0b6e7fb00010000000000000000007c',
    createdAt: 1637221810,
  },
  {
    id: 'boo-usdc-mai',
    name: 'USDC-MAI',
    token: 'USDC-MAI LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x4dE9f0ED95de2461B6dB1660f908348c42893b1A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooUSDC-MAI',
    earnedTokenAddress: '0x278E3E675F673dB344EF14a4Dc2F12218967a313',
    earnContractAddress: '0x278E3E675F673dB344EF14a4Dc2F12218967a313',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-usdc-mai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['USDC', 'MAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&outputCurrency=0xfB98B335551a418cD0737375a2ea0ded62Ea213b',
    addLiquidityUrl:
      'https://spooky.fi/#/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0xfB98B335551a418cD0737375a2ea0ded62Ea213b',
    createdAt: 1636758731,
  },
  {
    id: 'geist-ftm',
    logo: 'single-assets/FTM.png',
    name: 'FTM',
    token: 'FTM',
    tokenDescription: 'Geist',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooGeistFTM',
    earnedTokenAddress: '0xbf1340159c1b69Ae98Ff08BE5fC77cdc084dDc73',
    earnContractAddress: '0xbf1340159c1b69Ae98Ff08BE5fC77cdc084dDc73',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'FTM',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Geist',
    assets: ['FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'NO_AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl: 'https://spooky.fi/#/swap?outputCurrency=FTM',
    createdAt: 1636115081,
  },
  {
    id: 'geist-wbtc',
    logo: 'single-assets/WBTC.svg',
    name: 'WBTC',
    token: 'WBTC',
    tokenDescription: 'Geist',
    tokenAddress: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
    tokenDecimals: 8,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooGeistWBTC',
    earnedTokenAddress: '0x7489bFD512BEDd6b16318fa7c33F1Bc7075C372d',
    earnContractAddress: '0x7489bFD512BEDd6b16318fa7c33F1Bc7075C372d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WBTC',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Geist',
    assets: ['WBTC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'NO_AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x321162Cd933E2Be498Cd2267a90534A804051b11',
    createdAt: 1636111832,
  },
  {
    id: 'geist-eth',
    logo: 'single-assets/ETH.svg',
    name: 'ETH',
    token: 'ETH',
    tokenDescription: 'Geist',
    tokenAddress: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooGeistETH',
    earnedTokenAddress: '0x7bdD39339bE40adC54199aB4b5Fb6B2A89D40d4c',
    earnContractAddress: '0x7bdD39339bE40adC54199aB4b5Fb6B2A89D40d4c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'ETH',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Geist',
    assets: ['ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'NO_AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x74b23882a30290451A17c44f4F05243b6b58C76d',
    createdAt: 1636106243,
  },
  {
    id: 'geist-fusdt-eol',
    logo: 'single-assets/USDT.svg',
    name: 'fUSDT',
    token: 'fUSDT',
    tokenDescription: 'Geist',
    tokenAddress: '0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    tokenDecimals: 6,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooGeistfUSDT',
    earnedTokenAddress: '0xe9B01CB6c9379a491108119713b7D62C38DB9C97',
    earnContractAddress: '0xe9B01CB6c9379a491108119713b7D62C38DB9C97',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'fUSDT',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'Geist',
    assets: ['fUSDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'NO_AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    createdAt: 1636108542,
  },
  {
    id: 'geist-usdc',
    logo: 'single-assets/USDC.svg',
    name: 'USDC',
    token: 'USDC',
    tokenDescription: 'Geist',
    tokenAddress: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    tokenDecimals: 6,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooGeistUSDC',
    earnedTokenAddress: '0x4Fa9263B35DC20bfa2eDEE24ee26113b40C4D3bB',
    earnContractAddress: '0x4Fa9263B35DC20bfa2eDEE24ee26113b40C4D3bB',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDC',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Geist',
    assets: ['USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'NO_AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    createdAt: 1636126692,
  },
  {
    id: 'geist-dai-eol',
    logo: 'single-assets/DAI.svg',
    name: 'DAI',
    token: 'DAI',
    tokenDescription: 'Geist',
    tokenAddress: '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooGeistDAI',
    earnedTokenAddress: '0x315d6030e908835b1dfE3393213Cab70bfB30Ace',
    earnContractAddress: '0x315d6030e908835b1dfE3393213Cab70bfB30Ace',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'DAI',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Geist',
    assets: ['DAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'NO_AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    createdAt: 1636100990,
    retireReason: 'tvl',
  },
  {
    id: 'beets-song-ice-fire',
    logo: 'fantom/Song Of Ice And Fire.png',
    name: 'Song Of Ice And Fire',
    token: 'Song Of Ice And Fire',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0x2BeA17EdE5D83ad19ae112B8592AadaA2B015De7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetSongIceFire',
    earnedTokenAddress: '0x0F12a6B93e00915Fac52070D42914Af678a29202',
    earnContractAddress: '0x0F12a6B93e00915Fac52070D42914Af678a29202',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-song-ice-fire',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beethoven X',
    assets: ['FTM', 'SPELL', 'MIM', 'ICE'],
    risks: [
      'COMPLEXITY_LOW',
      'NEW_STRAT',
      'IL_HIGH',
      'MCAP_MIRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beets.fi/#/pool/0x2bea17ede5d83ad19ae112b8592aadaa2b015de7000100000000000000000069',
    createdAt: 1636020955,
  },
  {
    id: 'beets-tubular-bells',
    logo: 'fantom/Tubular Bells.png',
    name: 'Tubular Bells',
    token: 'Tubular Bells',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0x65Bf2d53BD1d7050d22873f6B9Bb59edb0cA0b20',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetTubularBells',
    earnedTokenAddress: '0xF1Ba839207ec41a5526358b2c1a170423caBc712',
    earnContractAddress: '0xF1Ba839207ec41a5526358b2c1a170423caBc712',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-tubular-bells',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beethoven X',
    assets: ['USDC', 'CRV', 'FTM', 'LINK'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_HIGH', 'MCAP_MEDIUM', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beets.fi/#/pool/0x65bf2d53bd1d7050d22873f6b9bb59edb0ca0b2000010000000000000000006e',
    createdAt: 1636019838,
  },
  {
    id: 'beets-late-quartet',
    logo: 'fantom/Late Quartet.png',
    name: 'Late Quartet',
    token: 'Late Quartet',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0xf3A602d30dcB723A74a0198313a7551FEacA7DAc',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetLateQuartet',
    earnedTokenAddress: '0x7fed554476B64B41CBD9d3FA1C38cD781eE701b6',
    earnContractAddress: '0x7fed554476B64B41CBD9d3FA1C38cD781eE701b6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-late-quartet',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beethoven X',
    assets: ['USDC', 'FTM', 'WBTC', 'ETH'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_HIGH', 'MCAP_LARGE', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beets.fi/#/pool/0xf3a602d30dcb723a74a0198313a7551feaca7dac00010000000000000000005f',
    createdAt: 1635702186,
  },
  {
    id: 'boo-wftm-dola-eol',
    name: 'DOLA-FTM',
    token: 'DOLA-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x49EC56Cc2adAf19C1688d3131304Dbc3Df5e1cCd',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooFTM-DOLA',
    earnedTokenAddress: '0x7993CB93891C108F16182600394BeeCfCB911d1f',
    earnContractAddress: '0x7993CB93891C108F16182600394BeeCfCB911d1f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-dola',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'SpookySwap',
    assets: ['DOLA', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x3129662808bEC728a27Ab6a6b9AFd3cBacA8A43c',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x3129662808bEC728a27Ab6a6b9AFd3cBacA8A43c',
    createdAt: 1635415386,
  },
  {
    id: 'scream-dola',
    logo: 'single-assets/DOLA.png',
    name: 'DOLA',
    token: 'DOLA',
    tokenDescription: 'Scream',
    tokenAddress: '0x3129662808bEC728a27Ab6a6b9AFd3cBacA8A43c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooScreamDOLA',
    earnedTokenAddress: '0x897a1B6F3a2C3D2CB3137888F310Ecdc752bfcFB',
    earnContractAddress: '0x897a1B6F3a2C3D2CB3137888F310Ecdc752bfcFB',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'DOLA',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Scream',
    assets: ['DOLA'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x3129662808bEC728a27Ab6a6b9AFd3cBacA8A43c',
    createdAt: 1635454839,
  },
  {
    id: 'beets-fantom-conservatory',
    logo: 'fantom/Fantom Conservatory.png',
    name: 'Fantom Conservatory Of Music',
    token: 'Fantom Conservatory Of Music',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0xfC092F85125907fFcb0f9012e6e40B3f37c4De60',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetFantomConservatory',
    earnedTokenAddress: '0x41a3Bb418F6e7Dc9522b1F72473125410ae40a21',
    earnContractAddress: '0x41a3Bb418F6e7Dc9522b1F72473125410ae40a21',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-fantom-conservatory',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beethoven X',
    assets: ['FTM', 'SPIRIT', 'BOO', 'TAROT', 'ANY', 'SCREAM', 'BEETS'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_HIGH', 'MCAP_MICRO', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beets.fi/#/pool/0xfc092f85125907ffcb0f9012e6e40b3f37c4de60000100000000000000000044',
    createdAt: 1635369288,
  },
  {
    id: 'summit-summit-eol',
    logo: 'single-assets/SUMMIT.png',
    name: 'SUMMIT',
    token: 'SUMMIT',
    tokenDescription: 'SummitDefi',
    tokenAddress: '0x8F9bCCB6Dd999148Da1808aC290F2274b13D7994',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSummit',
    earnedTokenAddress: '0x8E923758B7A959Db4110DbE78f637c4b7a6a9007',
    earnContractAddress: '0x8E923758B7A959Db4110DbE78f637c4b7a6a9007',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'SUMMIT',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['SUMMIT'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_NONE', 'MCAP_MICRO', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x8F9bCCB6Dd999148Da1808aC290F2274b13D7994',
    createdAt: 1635336610,
  },
  {
    id: 'summit-summit-ftm-eol',
    name: 'SUMMIT-FTM LP',
    token: 'SUMMIT-FTM LP',
    tokenDescription: 'SpookySwap (SummitDefi)',
    tokenAddress: '0x06300c5e8e55cC40Bf3A0383F69aCf18f1d32EC1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSummitSUMMIT-FTM',
    earnedTokenAddress: '0x277Da1Fa86B7a82d4060db81bFABd194C17B48F5',
    earnContractAddress: '0x277Da1Fa86B7a82d4060db81bFABd194C17B48F5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'summit-summit-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Other',
    assets: ['SUMMIT', 'FTM'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_HIGH', 'MCAP_MICRO', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x8F9bCCB6Dd999148Da1808aC290F2274b13D7994',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x8F9bCCB6Dd999148Da1808aC290F2274b13D7994',
    createdAt: 1635338766,
  },
  {
    id: 'beets-sound-of-moosic',
    logo: 'fantom/Sound of Moosic.png',
    name: 'Sound Of Moosic',
    token: 'Sound Of Moosic',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0x2975035545008935152FdF48ca13406cc5D4e475',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetSoundOfMoosic',
    earnedTokenAddress: '0x79D6DbC8C45cBb76ED8442A52ae53a98082f485e',
    earnContractAddress: '0x79D6DbC8C45cBb76ED8442A52ae53a98082f485e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-sound-of-moosic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beethoven X',
    assets: ['FTM', 'mooBIFI', 'BIFI', 'BEETS'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_HIGH', 'MCAP_MICRO', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beethovenx.io/#/pool/0x2975035545008935152fdf48ca13406cc5d4e47500010000000000000000002a',
    createdAt: 1634922183,
  },
  {
    id: 'pearzap-fpear-ftm-eol',
    name: 'PEAR-FTM LP',
    token: 'PEAR-FTM LP',
    tokenDescription: 'SpiritSwap (PearZap)',
    tokenAddress: '0x30549e1139C57f994872679ae5Ac2dF2c1063D0A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPearZapPEAR-FTM',
    earnedTokenAddress: '0xe4817511e44481D6A4203E6711513259ad9d2622',
    earnContractAddress: '0xe4817511e44481D6A4203E6711513259ad9d2622',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pearzap-fpear-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['PEAR', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0x7C10108d4B7f4bd659ee57A53b30dF928244b354',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/0x7c10108d4b7f4bd659ee57a53b30df928244b354',
    createdAt: 1634884703,
  },
  {
    id: 'pearzap-fpear-usdc-eol',
    name: 'PEAR-USDC LP',
    token: 'PEAR-USDC LP',
    tokenDescription: 'SpiritSwap (PearZap)',
    tokenAddress: '0xeD58D2f291512eB773e2174D2A16946dC140C7f4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPearZapPEAR-USDC',
    earnedTokenAddress: '0x4F6F9173d12Bd1cB998Cf31CE5198f708dC92d7a',
    earnContractAddress: '0x4F6F9173d12Bd1cB998Cf31CE5198f708dC92d7a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pearzap-fpear-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['PEAR', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0x7C10108d4B7f4bd659ee57A53b30dF928244b354',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/0x7c10108d4b7f4bd659ee57a53b30df928244b354',
    createdAt: 1634885192,
  },
  {
    id: 'beets-ftm-sonata-eol',
    logo: 'fantom/Fantom Sonata.png',
    name: 'FTM Sonata',
    token: 'FTM Sonata',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0xf0e2c47d4C9FBBbc2F2E19ACdaA3c773A3ECD221',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetFTMSonata',
    earnedTokenAddress: '0xDFd0073bec17FAeE9fFA26bfeB2B84022D991a4B',
    earnContractAddress: '0xDFd0073bec17FAeE9fFA26bfeB2B84022D991a4B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-ftm-sonata',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Beethoven X',
    assets: ['USDC', 'CRV', 'FTM', 'SPIRIT', 'BOO', 'LINK', 'SCREAM'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_HIGH', 'MCAP_MICRO', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beethovenx.io/#/pool/0xf0e2c47d4c9fbbbc2f2e19acdaa3c773a3ecd22100010000000000000000000a',
    createdAt: 1634917292,
  },
  {
    id: 'beets-dance-of-degens-eol',
    logo: 'fantom/Dance of The Degens.png',
    name: 'Dance Of The Degens',
    token: 'Dance Of The Degens',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0x72C0eB973Dc95e2d185563f58fC26626CC2e8034',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetDanceOfDegens',
    earnedTokenAddress: '0x607D1Af2eFbcC0dDeD151f3A49d4Cc13BC528fAC',
    earnContractAddress: '0x607D1Af2eFbcC0dDeD151f3A49d4Cc13BC528fAC',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-dance-of-degens',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Beethoven X',
    assets: ['FTM', 'SPELL', 'SPIRIT', 'BOO', 'TAROT', 'ANY', 'SCREAM', 'ICE'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_HIGH', 'MCAP_MICRO', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    retireReason: 'upgrade',
    addLiquidityUrl:
      'https://app.beethovenx.io/#/pool/0x72c0eb973dc95e2d185563f58fc26626cc2e8034000100000000000000000011',
    createdAt: 1634920235,
  },
  {
    id: 'beets-magic-touch-daniele-eol',
    logo: 'fantom/The Magic Touch By Daniele.png',
    name: 'The Magic Touch By Daniele',
    token: 'The Magic Touch By Daniele',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0x6d12D3fE16dD83115254a32B273B05Ac5444C349',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetMagicTouchDaniele',
    earnedTokenAddress: '0x1e6552B7cC3A6C59C6E9d7896884939Ea09f8316',
    earnContractAddress: '0x1e6552B7cC3A6C59C6E9d7896884939Ea09f8316',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-magic-touch-daniele',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Beethoven X',
    assets: ['FTM', 'SPELL', 'MIM', 'wMEMO', 'ICE'],
    risks: [
      'COMPLEXITY_LOW',
      'NEW_STRAT',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beethovenx.io/#/pool/0x6d12d3fe16dd83115254a32b273b05ac5444c349000100000000000000000029',
    createdAt: 1634921587,
  },
  {
    id: 'beets-classic-trio-eol',
    logo: 'fantom/The Classic Trio.png',
    name: 'Classic Trio',
    token: 'Classic Trio',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0x6FDC8415B654B0F60475944A0b9421Dc36ee1363',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetClassicTrio',
    earnedTokenAddress: '0xcA88c38886ba5CE215921A829DF529D66B9B0992',
    earnContractAddress: '0xcA88c38886ba5CE215921A829DF529D66B9B0992',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-classic-trio',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Beethoven X',
    assets: ['USDC', 'WBTC', 'ETH'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_LOW', 'MCAP_LARGE', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beethovenx.io/#/pool/0x6fdc8415b654b0f60475944a0b9421dc36ee1363000100000000000000000000',
    createdAt: 1634908480,
  },
  {
    id: 'beets-e-major',
    name: 'E Major',
    token: 'E Major',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0xA07De66AeF84e2c01D88a48D57D1463377Ee602b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetEMajor',
    earnedTokenAddress: '0xF83073C105bc0f8bF16Cc6b84766ba91B76CF0E1',
    earnContractAddress: '0xF83073C105bc0f8bF16Cc6b84766ba91B76CF0E1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-e-major',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beethoven X',
    assets: ['USDC', 'ETH'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_LOW', 'MCAP_LARGE', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beethovenx.io/#/pool/0xa07de66aef84e2c01d88a48d57d1463377ee602b000200000000000000000002',
    createdAt: 1634911153,
  },
  {
    id: 'beets-b-major',
    name: 'B Major',
    token: 'B Major',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0x22B30B00e6796Daf710fBE5cAFBFc9Cdd1377f2A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetBMajor',
    earnedTokenAddress: '0x3b4e2ff44c601BD78F7db588496C6637A3Afa1f4',
    earnContractAddress: '0x3b4e2ff44c601BD78F7db588496C6637A3Afa1f4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-b-major',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beethoven X',
    assets: ['USDC', 'WBTC'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_LOW', 'MCAP_LARGE', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beethovenx.io/#/pool/0x22b30b00e6796daf710fbe5cafbfc9cdd1377f2a000200000000000000000001',
    createdAt: 1634914810,
  },
  {
    id: 'beets-ziggy-mim',
    logo: 'fantom/Ziggy Stardust & Magic Internet Money.png',
    name: 'Ziggy Stardust & Magic Internet Money',
    token: 'Ziggy Stardust & Magic Internet Money',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0xD163415BD34EF06f57C58D2AEd5A5478AfB464cC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetZiggyMIM',
    earnedTokenAddress: '0x4C25854f6DA3b5848F7B5C71dcb8EEe20b292d3E',
    earnContractAddress: '0x4C25854f6DA3b5848F7B5C71dcb8EEe20b292d3E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-ziggy-mim',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beethoven X',
    assets: ['USDC', 'fUSDT', 'MIM'],
    risks: [
      'COMPLEXITY_LOW',
      'NEW_STRAT',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beethovenx.io/#/pool/0xd163415bd34ef06f57c58d2aed5a5478afb464cc00000000000000000000000e',
    createdAt: 1634916171,
  },
  {
    id: 'beets-fidelio-duetto',
    name: 'Fidelio Duetto',
    token: 'Fidelio Duetto',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0xcdE5a11a4ACB4eE4c805352Cec57E236bdBC3837',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetFidelioDuetto',
    earnedTokenAddress: '0xAe0AB718971bb2BAd88AE6Bdc4D0eA63F3CD53Ee',
    earnContractAddress: '0xAe0AB718971bb2BAd88AE6Bdc4D0eA63F3CD53Ee',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-fidelio-duetto',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beethoven X',
    assets: ['FTM', 'BEETS'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_HIGH', 'MCAP_MICRO', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beethovenx.io/#/pool/0xcde5a11a4acb4ee4c805352cec57e236bdbc3837000200000000000000000019',
    createdAt: 1634809776,
  },
  {
    id: 'beets-symphony-nr-10-eol',
    name: 'Symphony Nr 10. Opus 138',
    token: 'Symphony Nr 10. Opus 138',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0x03c6B3f09D2504606936b1A4DeCeFaD204687890',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetSymphonyNr10',
    earnedTokenAddress: '0x3bfd79fF14a4B1BA024DEc0A778Edc3fe53B237a',
    earnContractAddress: '0x3bfd79fF14a4B1BA024DEc0A778Edc3fe53B237a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-symphony-nr-10',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Beethoven X',
    assets: ['USDC', 'BEETS'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_HIGH', 'MCAP_MICRO', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://app.beethovenx.io/#/pool/0x03c6b3f09d2504606936b1a4decefad204687890000200000000000000000015',
    createdAt: 1634812249,
  },
  {
    id: 'beets-grand-orchestra',
    logo: 'fantom/The Grand Orchestra.png',
    name: 'The Grand Orchestra',
    token: 'The Grand Orchestra',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0xd47D2791d3B46f9452709Fa41855a045304D6f9d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetGrandOrchestra',
    earnedTokenAddress: '0x0ab24Bfc2503bB536ad667c00685BBB70fA90433',
    earnContractAddress: '0x0ab24Bfc2503bB536ad667c00685BBB70fA90433',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-grand-orchestra',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beethoven X',
    assets: ['FTM', 'WBTC', 'ETH'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_LOW', 'MCAP_LARGE', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beethovenx.io/#/pool/0xd47d2791d3b46f9452709fa41855a045304d6f9d000100000000000000000004',
    createdAt: 1634740557,
  },
  {
    id: 'beets-fantom-of-opera',
    name: 'Fantom of the Opera',
    token: 'Fantom of the Opera',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0xcdF68a4d525Ba2E90Fe959c74330430A5a6b8226',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetFantomOfTheOpera',
    earnedTokenAddress: '0xB40c339e2b0a8513152F68082D3c87314E03776D',
    earnContractAddress: '0xB40c339e2b0a8513152F68082D3c87314E03776D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-fantom-of-opera',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beethoven X',
    assets: ['USDC', 'FTM'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_LOW', 'MCAP_LARGE', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beethovenx.io/#/pool/0xcdf68a4d525ba2e90fe959c74330430a5a6b8226000200000000000000000008',
    createdAt: 1634805280,
  },
  {
    id: 'beets-steady-beets-eol',
    name: 'Steady Beets',
    token: 'Steady Beets',
    tokenDescription: 'Beethoven X',
    tokenAddress: '0xd41bF724b6e31311Db582c5388Af6B316e812Fe4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeetSteadyBeets',
    earnedTokenAddress: '0x6BE9170485a3DA49DA422002cb02161a123A1422',
    earnContractAddress: '0x6BE9170485a3DA49DA422002cb02161a123A1422',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beets-steady-beets',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Beethoven X',
    assets: ['USDC', 'DAI'],
    risks: [
      'COMPLEXITY_LOW',
      'NEW_STRAT',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://app.beethovenx.io/#/pool/0xd41bf724b6e31311db582c5388af6b316e812fe4000200000000000000000003',
    createdAt: 1634807049,
  },
  {
    id: 'scream-frax',
    logo: 'single-assets/FRAX.svg',
    name: 'FRAX',
    token: 'FRAX',
    tokenDescription: 'Scream',
    tokenAddress: '0xdc301622e621166BD8E82f2cA0A26c13Ad0BE355',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooScreamFRAX',
    earnedTokenAddress: '0xb8EddAA94BB8AbF8A5BB90c217D53960242e104D',
    earnContractAddress: '0xb8EddAA94BB8AbF8A5BB90c217D53960242e104D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'FRAX',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Scream',
    assets: ['FRAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'ALGO_STABLE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0%',
    buyTokenUrl: 'https://app.frax.finance/crosschain',
    createdAt: 1634500757,
  },
  {
    id: 'scream-mim',
    logo: 'single-assets/MIM.png',
    name: 'MIM',
    token: 'MIM',
    tokenDescription: 'Scream',
    tokenAddress: '0x82f0B8B456c1A451378467398982d4834b6829c1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooScreamMIM',
    earnedTokenAddress: '0x074A2a6d0fdbf6860033E57eBD37Aeb88c3931b4',
    earnContractAddress: '0x074A2a6d0fdbf6860033E57eBD37Aeb88c3931b4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'MIM',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Scream',
    assets: ['MIM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'NO_AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'Lending',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x82f0B8B456c1A451378467398982d4834b6829c1',
    createdAt: 1634453638,
  },
  {
    id: 'spirit-wmemo-mim',
    name: 'wMEMO-MIM LP',
    token: 'wMEMO-MIM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0xC9B98e4A4e306DFc24bc5b5F66e271e19Fd74c5A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritMIM-wMEMO',
    earnedTokenAddress: '0xC84bA97eF76d45281C2DBBb486d266C45CFbf5B7',
    earnContractAddress: '0xC84bA97eF76d45281C2DBBb486d266C45CFbf5B7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-wmemo-mim',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['wMEMO', 'MIM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/0x82f0B8B456c1A451378467398982d4834b6829c1/0xDDc0385169797937066bBd8EF409b5B3c0dFEB52',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/0xDDc0385169797937066bBd8EF409b5B3c0dFEB52',
    createdAt: 1634312717,
  },
  {
    id: 'spell-ftm-mim-crv',
    logo: 'uncategorized/MIM2CRV.svg',
    name: 'MIM/USDC/fUSDT',
    token: 'MIM2CRV',
    tokenDescription: 'Curve (MIM)',
    tokenAddress: '0x2dd7C9371965472E5A5fD28fbE165007c61439E1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAbrcdbrMIM-2CRV',
    earnedTokenAddress: '0x837BEe8567297dd628fb82FFBA193A57A9F6B655',
    earnContractAddress: '0x837BEe8567297dd628fb82FFBA193A57A9F6B655',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-ftm-mim',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'MIM',
    assets: ['MIM', 'USDC', 'fUSDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0.01%',
    addLiquidityUrl: 'https://ftm.curve.fi/factory/1/deposit',
    createdAt: 1633879958,
  },
  {
    id: 'geist-geist-ftm',
    name: 'GEIST-FTM LP',
    token: 'GEIST-FTM LP',
    tokenDescription: 'SpookySwap (Geist)',
    tokenAddress: '0x668AE94D0870230AC007a01B471D02b2c94DDcB9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooGeistGEIST-WFTM',
    earnedTokenAddress: '0xfd30cEAD8E54568fe535747929775e95Fdb6d3f7',
    earnContractAddress: '0xfd30cEAD8E54568fe535747929775e95Fdb6d3f7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'geist-geist-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Geist',
    assets: ['GEIST', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0xd8321AA83Fb0a4ECd6348D4577431310A6E0814d',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0xd8321AA83Fb0a4ECd6348D4577431310A6E0814d',
    createdAt: 1633607188,
  },
  {
    id: 'boo-wftm-spell',
    name: 'SPELL-FTM LP',
    token: 'SPELL-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x78f82c16992932EfDd18d93f889141CcF326DBc2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooWFTM-SPELL',
    earnedTokenAddress: '0xB3aA62165C1Aa9472C9B16dFAB74681F61D28508',
    earnContractAddress: '0xB3aA62165C1Aa9472C9B16dFAB74681F61D28508',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-spell',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['SPELL', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x468003B688943977e6130F4F68F23aad939a1040',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x468003B688943977e6130F4F68F23aad939a1040',
    createdAt: 1634118092,
  },
  {
    id: 'spirit-link-ftm-eol',
    name: 'LINK-FTM LP',
    token: 'LINK-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0xd061c6586670792331E14a80f3b3Bb267189C681',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritLINK-WFTM',
    earnedTokenAddress: '0x8e4C1AD24d3e275d298b091DB11C8C2CBF9c9688',
    earnContractAddress: '0x8e4C1AD24d3e275d298b091DB11C8C2CBF9c9688',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-link-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'SpiritSwap',
    assets: ['LINK', 'FTM'],
    withdrawalFee: '0%',
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/0xb3654dc3D10Ea7645f8319668E8F54d2574FBdC8',
    createdAt: 1633336526,
  },
  {
    id: 'spirit-any-ftm-eol',
    name: 'ANY-FTM LP',
    token: 'ANY-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x26D583028989378Cc1b7CBC023f4Ae049d5e5899',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritANY-WFTM',
    earnedTokenAddress: '0xf1aE82B29Fe8813E326E760dfce60BbED1F93D1a',
    earnContractAddress: '0xf1aE82B29Fe8813E326E760dfce60BbED1F93D1a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-any-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'SpiritSwap',
    assets: ['ANY', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0xdDcb3fFD12750B45d32E084887fdf1aABAb34239',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/0xdDcb3fFD12750B45d32E084887fdf1aABAb34239',
    createdAt: 1633336796,
  },
  {
    id: 'curve-ftm-tricrypto',
    logo: 'uncategorized/ATRICRYPTO.png',
    name: 'TriCrypto',
    token: 'crv3crypto',
    tokenDescription: 'Curve',
    tokenAddress: '0x58e57cA18B7A47112b877E31929798Cd3D703b0f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCurveTriCrypto',
    earnedTokenAddress: '0xeeE5EA8949F622090CFc16d141305d5120DF8dA4',
    earnContractAddress: '0xeeE5EA8949F622090CFc16d141305d5120DF8dA4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-ftm-tricrypto',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['fUSDT', 'WBTC', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://ftm.curve.fi/tricrypto/deposit',
    createdAt: 1652662923,
  },
  {
    id: 'sing-ftm-usdc-sing-eol',
    name: 'SING-USDC LP',
    token: 'SING-USDC',
    tokenDescription: 'SpookySwap (Singular)',
    tokenAddress: '0x49710DFc04f11704003d0C9beeb953822F62fBaf',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSingUSDC-SING',
    earnedTokenAddress: '0x92fCB57f1751e45F6aEcC4b477Bb2255511db2b0',
    earnContractAddress: '0x92fCB57f1751e45F6aEcC4b477Bb2255511db2b0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sing-ftm-usdc-sing',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['SING', 'USDC'],
    risks: ['COMPLEXITY_LOW', 'NEW_STRAT', 'IL_HIGH', 'MCAP_MICRO', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x53D831e1db0947c74e8a52618f662209ec5dE0cE',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x53D831e1db0947c74e8a52618f662209ec5dE0cE',
    createdAt: 1634116608,
    retireReason: 'tvl',
  },
  {
    id: 'spirit-ftm-usdc',
    name: 'USDC-FTM LP',
    token: 'USDC-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0xe7E90f5a767406efF87Fdad7EB07ef407922EC1D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritUSDC-WFTM',
    earnedTokenAddress: '0xA4e2EE5a7fF51224c27C98098D8DB5C770bAAdbE',
    earnContractAddress: '0xA4e2EE5a7fF51224c27C98098D8DB5C770bAAdbE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['USDC', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    createdAt: 1633180384,
  },
  {
    id: 'spirit-fusdt-ftm-eol',
    name: 'fUSDT-FTM LP',
    token: 'fUSDT-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0xd14Dd3c56D9bc306322d4cEa0E1C49e9dDf045D4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritfUSDT-WFTM',
    earnedTokenAddress: '0xD8dd2EA228968F7f043474Db610A20aF887866c7',
    earnContractAddress: '0xD8dd2EA228968F7f043474Db610A20aF887866c7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-fusdt-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'eol',
    platform: 'SpiritSwap',
    assets: ['fUSDT', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    createdAt: 1633182001,
  },
  {
    id: 'spirit-ftm-mim-eol',
    name: 'MIM-FTM LP',
    token: 'MIM-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0xB32b31DfAfbD53E310390F641C7119b5B9Ea0488',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritWFTM-MIM',
    earnedTokenAddress: '0x10940BD183E39A21e9E19bF2d6551a84191B1582',
    earnContractAddress: '0x10940BD183E39A21e9E19bF2d6551a84191B1582',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-mim',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'SpiritSwap',
    assets: ['MIM', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0x82f0b8b456c1a451378467398982d4834b6829c1',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/0x82f0B8B456c1A451378467398982d4834b6829c1',
    createdAt: 1633184876,
  },
  {
    id: 'spirit-crv-ftm',
    name: 'CRV-FTM LP',
    token: 'CRV-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x374C8ACb146407Ef0AE8F82BaAFcF8f4EC1708CF',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritCRV-WFTM',
    earnedTokenAddress: '0xAbab1D681bCF375d1bE78e9F57207F6dd015B4BC',
    earnContractAddress: '0xAbab1D681bCF375d1bE78e9F57207F6dd015B4BC',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-crv-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['CRV', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0x1E4F97b9f9F913c46F1632781732927B9019C68b',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/0x1E4F97b9f9F913c46F1632781732927B9019C68b',
    createdAt: 1633199608,
  },
  {
    id: 'spirit-zoo-ftm-eol',
    name: 'ZOO-FTM LP',
    token: 'ZOO-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0xDF18DD2631f02D930071DF7d6FF89bbc3718C62F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritZOO-WFTM',
    earnedTokenAddress: '0x9F0d2c24BB2F4b6024F9A266Aa95536481D78ABe',
    earnContractAddress: '0x9F0d2c24BB2F4b6024F9A266Aa95536481D78ABe',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-zoo-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'SpiritSwap',
    assets: ['ZOO', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0x09e145A1D53c0045F41aEEf25D8ff982ae74dD56',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/0x09e145A1D53c0045F41aEEf25D8ff982ae74dD56',
    createdAt: 1633206054,
  },
  {
    id: 'spirit-ftm-ice-eol',
    name: 'ICE-FTM LP',
    token: 'ICE-FTM STLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x936D23C83c2469f6a14B9f5bEaec13879598A5aC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritWFTM-ICE',
    earnedTokenAddress: '0x7579F5105f73ddC5bb1e969d3CE275f1Ea3e5E08',
    earnContractAddress: '0x7579F5105f73ddC5bb1e969d3CE275f1Ea3e5E08',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-ice',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'SpiritSwap',
    assets: ['ICE', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0xf16e81dce15B08F326220742020379B855B87DF9',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/0xf16e81dce15B08F326220742020379B855B87DF9',
    createdAt: 1633205561,
  },
  {
    id: 'jetswap-fantom-eth-btc-eol',
    name: 'ETH-WBTC LP',
    token: 'ETH-WBTC LP',
    tokenDescription: 'JetSwap',
    tokenAddress: '0x04C25d06eD3984AAE14CA705d3CaF97Fb7238799',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetSwapETH-BTC',
    earnedTokenAddress: '0x3f812916E5C050305D8b4744f0254DE3e195d5E5',
    earnContractAddress: '0x3f812916E5C050305D8b4744f0254DE3e195d5E5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetswap-fantom-eth-btc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'JetSwap',
    assets: ['ETH', 'WBTC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://fantom-exchange.jetswap.finance/#/add/0x74b23882a30290451A17c44f4F05243b6b58C76d/0x321162Cd933E2Be498Cd2267a90534A804051b11',
    createdAt: 1632980637,
    retireReason: 'tvl',
  },
  {
    id: 'jetswap-fantom-eth-usdc-eol',
    name: 'ETH-USDC LP',
    token: 'ETH-USDC LP',
    tokenDescription: 'JetSwap',
    tokenAddress: '0xD21A2c9032D09B7174dB93736b834D2a7eaDab15',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetSwapETH-USDC',
    earnedTokenAddress: '0x34D94202E8B006A7FAA46ebED7844B3A738f4E09',
    earnContractAddress: '0x34D94202E8B006A7FAA46ebED7844B3A738f4E09',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetswap-fantom-eth-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'JetSwap',
    assets: ['ETH', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://fantom-exchange.jetswap.finance/#/add/0x04068da6c83afcfa0e13ba15a6696662335d5b75/0x74b23882a30290451A17c44f4F05243b6b58C76d',
    createdAt: 1632980829,
  },
  {
    id: 'jetswap-fantom-btc-usdc',
    name: 'WBTC-USDC LP',
    token: 'WBTC-USDC LP',
    tokenDescription: 'JetSwap',
    tokenAddress: '0x6B5340dFcd7D509Ea931cC4E69462797DbBc0197',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetSwapBTC-USDC',
    earnedTokenAddress: '0x8A5e335F6df7d73B7a6c33E0DA3FEE7a36cc0Da4',
    earnContractAddress: '0x8A5e335F6df7d73B7a6c33E0DA3FEE7a36cc0Da4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetswap-fantom-btc-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JetSwap',
    assets: ['WBTC', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://fantom-exchange.jetswap.finance/#/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0x321162Cd933E2Be498Cd2267a90534A804051b11',
    createdAt: 1632980917,
  },
  {
    id: 'jetswap-fantom-fwings-ftm-eol',
    name: 'fWINGS-FTM LP',
    token: 'fWINGS-FTM LP',
    tokenDescription: 'JetSwap',
    tokenAddress: '0x1047f356D7cB29D5939724c69E4eE61e820aFAC6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetSwapWINGS-FTM',
    earnedTokenAddress: '0xAe3F0C61F3Dc48767ccCeF3aD50b29437BE4b1a4',
    earnContractAddress: '0xAe3F0C61F3Dc48767ccCeF3aD50b29437BE4b1a4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetswap-fantom-fwings-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'JetSwap',
    assets: ['fWINGS', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://fantom-exchange.jetswap.finance/#/add/FANTOM/0x3D8f1ACCEe8e263F837138829B6C4517473d0688',
    buyTokenUrl:
      'https://fantom-exchange.jetswap.finance/#/swap?outputCurrency=0x3D8f1ACCEe8e263F837138829B6C4517473d0688',
    createdAt: 1632906330,
    retireReason: 'tvl',
  },
  {
    id: 'jetswap-fantom-fwings-usdc-eol',
    name: 'fWINGS-USDC LP',
    token: 'fWINGS-USDC LP',
    tokenDescription: 'JetSwap',
    tokenAddress: '0x89fF795017AE21A8696d371F685Cd02FC219F56F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetSwapWINGS-USDC',
    earnedTokenAddress: '0x9E75f8298e458B76382870982788988A0799195b',
    earnContractAddress: '0x9E75f8298e458B76382870982788988A0799195b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetswap-fantom-fwings-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'JetSwap',
    assets: ['fWINGS', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://fantom-exchange.jetswap.finance/#/add/0x04068da6c83afcfa0e13ba15a6696662335d5b75/0x3D8f1ACCEe8e263F837138829B6C4517473d0688',
    buyTokenUrl:
      'https://fantom-exchange.jetswap.finance/#/swap?outputCurrency=0x3D8f1ACCEe8e263F837138829B6C4517473d0688',
    createdAt: 1632906651,
    retireReason: 'tvl',
  },
  {
    id: 'jetswap-fantom-fwings-eol',
    logo: 'single-assets/fWINGS.svg',
    name: 'fWINGS',
    token: 'fWINGS',
    tokenDescription: 'JetSwap',
    tokenAddress: '0x3D8f1ACCEe8e263F837138829B6C4517473d0688',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetSwapWINGS',
    earnedTokenAddress: '0x15c0a37ee6fDA217f0f10dC88df02e7A52e377CA',
    earnContractAddress: '0x15c0a37ee6fDA217f0f10dC88df02e7A52e377CA',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'fWINGS',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'JetSwap',
    assets: ['fWINGS'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://fantom-exchange.jetswap.finance/#/swap?outputCurrency=0x3D8f1ACCEe8e263F837138829B6C4517473d0688',
    createdAt: 1632911389,
    retireReason: 'tvl',
  },
  {
    id: 'tomb-tshare-ftm-eol',
    logo: 'fantom/TSHARE-FTM.png',
    name: 'TSHARE-FTM LP',
    token: 'TSHARE-FTM LP',
    tokenDescription: 'SpookySwap (Tomb)',
    tokenAddress: '0x4733bc45eF91cF7CcEcaeeDb794727075fB209F2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombTSHARE-FTM',
    earnedTokenAddress: '0xae94e96bF81b3a43027918b138B71a771D381150',
    earnContractAddress: '0xae94e96bF81b3a43027918b138B71a771D381150',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-tshare-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Tomb',
    assets: ['TSHARE', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x4cdF39285D7Ca8eB3f090fDA0C069ba5F4145B37',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x4cdF39285D7Ca8eB3f090fDA0C069ba5F4145B37',
    createdAt: 1623662472,
  },
  {
    id: 'tomb-tomb-ftm',
    logo: 'fantom/TOMB-FTM.png',
    name: 'TOMB-FTM LP',
    token: 'TOMB-FTM LP',
    tokenDescription: 'SpookySwap (Tomb)',
    tokenAddress: '0x2A651563C9d3Af67aE0388a5c8F89b867038089e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTombTOMB-FTM',
    earnedTokenAddress: '0x27c77411074ba90cA35e6f92A79dAd577c05A746',
    earnContractAddress: '0x27c77411074ba90cA35e6f92A79dAd577c05A746',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-tomb-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Tomb',
    assets: ['TOMB', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
    createdAt: 1623659891,
  },
  {
    id: 'spirit-ftm-just-eol',
    name: 'JUST-FTM LP',
    token: 'JUST-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x0133660D0578Bf9D085033Ea753a27F5Aa2b9de1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritWFTM-JUST',
    earnedTokenAddress: '0xE6Db1E69b7A806ADe620b94F68da115bf25E13Ca',
    earnContractAddress: '0xE6Db1E69b7A806ADe620b94F68da115bf25E13Ca',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-just',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'SpiritSwap',
    assets: ['JUST', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    retireReason: 'tvl',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0x37C045bE4641328DFEB625f1Dde610D061613497',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/0x37C045bE4641328DFEB625f1Dde610D061613497',
    createdAt: 1633342210,
  },
  {
    id: 'spirit-ftm-sushi-eol',
    name: 'SUSHI-FTM LP',
    token: 'SUSHI-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x9Fe4c0CE5F533e96C2b72d852f190961AD5a7bB3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritWFTM-SUSHI',
    earnedTokenAddress: '0xb61a911DCc9490623b3d1F9f9246Ce9E45889018',
    earnContractAddress: '0xb61a911DCc9490623b3d1F9f9246Ce9E45889018',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-sushi',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'eol',
    platform: 'SpiritSwap',
    assets: ['SUSHI', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    retireReason: 'rewards',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0xae75A438b2E0cB8Bb01Ec1E1e376De11D44477CC',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/0xae75A438b2E0cB8Bb01Ec1E1e376De11D44477CC',
    createdAt: 1633336047,
  },
  {
    id: 'spirit-fusdt-spell-eol',
    name: 'SPELL-fUSDT LP',
    token: 'SPELL-fUSDT SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x31c0385DDE956f95D43Dac80Bd74FEE149961f4c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritfUSDT-SPELL',
    earnedTokenAddress: '0x01760EA1e63aA89b5E567ED616E104220B4E2e9B',
    earnContractAddress: '0x01760EA1e63aA89b5E567ED616E104220B4E2e9B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-fusdt-spell',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'SpiritSwap',
    assets: ['SPELL', 'fUSDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/0x468003B688943977e6130F4F68F23aad939a1040/0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/0x468003B688943977e6130F4F68F23aad939a1040',
    createdAt: 1633342959,
  },
  {
    id: 'boo-wftm-shade-eol',
    name: 'SHADE-FTM LP',
    token: 'SHADE-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x20aa395F3bcc4dc44a94215D129650533B3da0b3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooSHADE-FTM',
    earnedTokenAddress: '0xc178cDdDc277D5aFac90EEc3F833EF3a11Fbc9df',
    earnContractAddress: '0xc178cDdDc277D5aFac90EEc3F833EF3a11Fbc9df',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-shade',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'SpookySwap',
    assets: ['SHADE', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    retireReason: 'rewards',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x3A3841f5fa9f2c283EA567d5Aeea3Af022dD2262',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x3A3841f5fa9f2c283EA567d5Aeea3Af022dD2262',
    createdAt: 1631898051,
  },
  {
    id: 'boo-wftm-treeb',
    name: 'TREEB-FTM LP',
    token: 'TREEB-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xe8b72a866b8D59F5c13D2ADEF96E40A3EF5b3152',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooTREEB-FTM',
    earnedTokenAddress: '0x6C4593D4f91c790404cCEe5007E929d2b7bF45bD',
    earnContractAddress: '0x6C4593D4f91c790404cCEe5007E929d2b7bF45bD',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-treeb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['TREEB', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0xc60D7067dfBc6f2caf30523a064f416A5Af52963',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0xc60D7067dfBc6f2caf30523a064f416A5Af52963',
    createdAt: 1631897760,
  },
  {
    id: 'scream-crv',
    logo: 'single-assets/CRV.png',
    name: 'CRV',
    token: 'CRV',
    tokenDescription: 'Scream',
    tokenAddress: '0x1E4F97b9f9F913c46F1632781732927B9019C68b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooScreamCRV',
    earnedTokenAddress: '0xE8883Cd90A2D6F2877E130120965D61918d7CE44',
    earnContractAddress: '0xE8883Cd90A2D6F2877E130120965D61918d7CE44',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'CRV',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Scream',
    assets: ['CRV'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x1E4F97b9f9F913c46F1632781732927B9019C68b',
    createdAt: 1631727659,
  },
  {
    id: 'scream-link',
    logo: 'single-assets/LINK.png',
    name: 'LINK',
    token: 'LINK',
    tokenDescription: 'Scream',
    tokenAddress: '0xb3654dc3D10Ea7645f8319668E8F54d2574FBdC8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooScreamLINK',
    earnedTokenAddress: '0x6DfE2AAEA9dAadADf0865B661b53040E842640f8',
    earnContractAddress: '0x6DfE2AAEA9dAadADf0865B661b53040E842640f8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'LINK',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Scream',
    assets: ['LINK'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0xb3654dc3D10Ea7645f8319668E8F54d2574FBdC8',
    createdAt: 1631726729,
  },
  {
    id: 'boo-boo',
    logo: 'single-assets/BOO.png',
    name: 'BOO',
    token: 'BOO',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooBOO',
    earnedTokenAddress: '0x15DD4398721733D8273FD4Ed9ac5eadC6c018866',
    earnContractAddress: '0x15DD4398721733D8273FD4Ed9ac5eadC6c018866',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BOO',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['BOO'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE',
    createdAt: 1620998574,
  },
  {
    id: 'stakesteak-fusd-usdc-eol',
    name: 'fUSD-USDC sAMM LP',
    token: 'fUSD-USDC sAMM LP',
    tokenDescription: 'StakeSteak',
    tokenAddress: '0xa0828eE559110b041DEdbf10Ae0cf42274251de1',
    tokenDecimals: 6,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStakesteakFUSD-USDC',
    earnedTokenAddress: '0x94A654bA3b4052a94EB795E9ff0e9209B0A54dB3',
    earnContractAddress: '0x94A654bA3b4052a94EB795E9ff0e9209B0A54dB3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stakesteak-fusd-usdc',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['fUSD', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    depositFee: '0.5%',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://stakesteak.app/',
    retireReason: 'tvl',
    buyTokenUrl:
      'https://spooky.fi/#/swap?inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&outputCurrency=0xAd84341756Bf337f5a0164515b1f6F993D194E1f',
    createdAt: 1631357355,
  },
  {
    id: 'boo-steak-wftm-eol',
    name: 'STEAK-FTM LP',
    token: 'STEAK-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xD10CC1b46D806D6ac803AF367Da489dEA5EE76FA',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooSTEAK-WFTM',
    earnedTokenAddress: '0x3497a17b4680Ef47Ff5B41B00DD4068CAa356A91',
    earnContractAddress: '0x3497a17b4680Ef47Ff5B41B00DD4068CAa356A91',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-steak-wftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'SpookySwap',
    assets: ['STEAK', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    retireReason: 'rewards',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x05848B832E872d9eDd84AC5718D58f21fD9c9649',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x05848B832E872d9eDd84AC5718D58f21fD9c9649',
    createdAt: 1631055499,
  },
  {
    id: 'boo-wftm-foo-eol',
    name: 'FOO-FTM LP',
    token: 'FOO-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x157f913Ed0961a8D0d06a258019840919111D9B9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooWFTM-FOO',
    earnedTokenAddress: '0x4F4e8c1055d0b58F17593A0086608fc6949e5684',
    earnContractAddress: '0x4F4e8c1055d0b58F17593A0086608fc6949e5684',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-foo',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'SpookySwap',
    assets: ['FOO', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0xFbc3c04845162F067A0B6F8934383E63899c3524',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0xFbc3c04845162F067A0B6F8934383E63899c3524',
    createdAt: 1631054248,
  },
  {
    id: 'boo-ftm-scream-eol',
    name: 'SCREAM-FTM LP',
    token: 'SCREAM-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x30872e4fc4edbFD7a352bFC2463eb4fAe9C09086',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooFTM-SCREAM',
    earnedTokenAddress: '0xcA68685C99dB20ffFa999Af52178077d6f0A3E73',
    earnContractAddress: '0xcA68685C99dB20ffFa999Af52178077d6f0A3E73',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-ftm-scream',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'SpookySwap',
    assets: ['SCREAM', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0xe0654C8e6fd4D733349ac7E09f6f23DA256bF475',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0xe0654C8e6fd4D733349ac7E09f6f23DA256bF475',
    createdAt: 1630352068,
    retireReason: 'tvl',
  },
  {
    id: 'boo-zoo-ftm-eol',
    name: 'ZOO-FTM LP',
    token: 'ZOO-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x3Cceb477Fcb6cDE90180983642486E68148D7b27',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooZOO-FTM',
    earnedTokenAddress: '0xe87d98ce222435455706529c8Ff8E12973792acb',
    earnContractAddress: '0xe87d98ce222435455706529c8Ff8E12973792acb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-zoo-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'SpookySwap',
    assets: ['ZOO', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    retireReason: 'rewards',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x09e145A1D53c0045F41aEEf25D8ff982ae74dD56',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x09e145A1D53c0045F41aEEf25D8ff982ae74dD56',
    createdAt: 1630243343,
  },
  {
    id: 'steakhouse-scream-ftm',
    name: 'SCREAM-FTM LP',
    token: 'SCREAM-FTM LP',
    tokenDescription: 'SteakHouse',
    tokenAddress: '0x30872e4fc4edbFD7a352bFC2463eb4fAe9C09086',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSteakHouseSCREAM-FTM',
    earnedTokenAddress: '0xF782E675B93d22FD810Dca4149D29Ab32e5B2972',
    earnContractAddress: '0xF782E675B93d22FD810Dca4149D29Ab32e5B2972',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'steakhouse-scream-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    assets: ['SCREAM', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    depositFee: '0.5%',
    withdrawalFee: '0%',
    platform: 'Other',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0xe0654C8e6fd4D733349ac7E09f6f23DA256bF475',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0xe0654C8e6fd4D733349ac7E09f6f23DA256bF475',
    createdAt: 1628881379,
  },
  {
    id: 'scream-usdc',
    logo: 'single-assets/USDC.svg',
    name: 'USDC',
    token: 'USDC',
    tokenDescription: 'Scream',
    tokenAddress: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    tokenDecimals: 6,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooScreamUSDC',
    earnedTokenAddress: '0x2438009ba14A93e82ab43c66838e57bE27A55Aa1',
    earnContractAddress: '0x2438009ba14A93e82ab43c66838e57bE27A55Aa1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDC',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Scream',
    assets: ['USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    createdAt: 1628627757,
  },
  {
    id: 'scream-wbtc',
    logo: 'single-assets/WBTC.svg',
    name: 'WBTC',
    token: 'WBTC',
    tokenDescription: 'Scream',
    tokenAddress: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
    tokenDecimals: 8,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooScreamWBTC',
    earnedTokenAddress: '0x97927aBfE1aBBE5429cBe79260B290222fC9fbba',
    earnContractAddress: '0x97927aBfE1aBBE5429cBe79260B290222fC9fbba',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WBTC',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Scream',
    assets: ['WBTC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0.01%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x321162Cd933E2Be498Cd2267a90534A804051b11',
    createdAt: 1628739572,
  },
  {
    id: 'scream-dai',
    logo: 'single-assets/DAI.svg',
    name: 'DAI',
    token: 'DAI',
    tokenDescription: 'Scream',
    tokenAddress: '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooScreamDAI',
    earnedTokenAddress: '0x920786cff2A6f601975874Bb24C63f0115Df7dc8',
    earnContractAddress: '0x920786cff2A6f601975874Bb24C63f0115Df7dc8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'DAI',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Scream',
    assets: ['DAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'Lending',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    createdAt: 1628739318,
  },
  {
    id: 'scream-eth',
    logo: 'single-assets/ETH.svg',
    name: 'ETH',
    token: 'ETH',
    tokenDescription: 'Scream',
    tokenAddress: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooScreamETH',
    earnedTokenAddress: '0x0a03D2C1cFcA48075992d810cc69Bd9FE026384a',
    earnContractAddress: '0x0a03D2C1cFcA48075992d810cc69Bd9FE026384a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'ETH',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Scream',
    assets: ['ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x74b23882a30290451A17c44f4F05243b6b58C76d',
    createdAt: 1628739417,
  },
  {
    id: 'scream-fusdt',
    logo: 'single-assets/USDT.svg',
    name: 'fUSDT',
    token: 'fUSDT',
    tokenDescription: 'Scream',
    tokenAddress: '0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    tokenDecimals: 6,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooScreamfUSDT',
    earnedTokenAddress: '0xb09cf345294aDD1066543B22FD7384185F7C6fCA',
    earnContractAddress: '0xb09cf345294aDD1066543B22FD7384185F7C6fCA',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDT',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Scream',
    assets: ['fUSDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    createdAt: 1628739191,
  },
  {
    id: 'boo-yfi-eth',
    name: 'YFI-ETH LP',
    token: 'YFI-ETH LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x0845c0bFe75691B1e21b24351aAc581a7FB6b7Df',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooYFI-ETH',
    earnedTokenAddress: '0x93EA5929f636f71F343122Ce396340f8E0BC3A70',
    earnContractAddress: '0x93EA5929f636f71F343122Ce396340f8E0BC3A70',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-yfi-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['YFI', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://spooky.fi/#/add/0x29b0Da86e484E1C0029B56e817912d778aC0EC69/0x74b23882a30290451A17c44f4F05243b6b58C76d',
    buyTokenUrl:
      'https://spooky.fi/#/swap?inputCurrency=0x29b0Da86e484E1C0029B56e817912d778aC0EC69&outputCurrency=0x74b23882a30290451A17c44f4F05243b6b58C76d',
    createdAt: 1628168514,
  },
  {
    id: 'curve-ftm-2pool',
    logo: 'uncategorized/crv2pool.png',
    name: 'DAI/USDC',
    token: '2poolCRV',
    tokenDescription: 'Curve',
    tokenAddress: '0x27E611FD27b276ACbd5Ffd632E5eAEBEC9761E40',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCurve2Pool',
    earnedTokenAddress: '0x7715d9458683288024B9e20D1319DC162361B06c',
    earnContractAddress: '0x7715d9458683288024B9e20D1319DC162361B06c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-ftm-2pool',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['USDC', 'DAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0.01%',
    addLiquidityUrl: 'https://ftm.curve.fi/2pool/deposit',
    createdAt: 1626957477,
  },
  {
    id: 'curve-ftm-fusdt-eol',
    logo: 'single-assets/USDT.svg',
    name: 'fUSDT/DAI/USDC',
    token: 'fusdtCRV',
    tokenDescription: 'Curve',
    tokenAddress: '0x92D5ebF3593a92888C25C0AbEF126583d4b5312E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCurveFUSDT',
    earnedTokenAddress: '0xBdA8bC79705BC60226adCA2766e94Eb5512949a3',
    earnContractAddress: '0xBdA8bC79705BC60226adCA2766e94Eb5512949a3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-ftm-fusdt',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Curve',
    assets: ['fUSDT', 'USDC', 'DAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0.01%',
    retireReason: 'rewards',
    addLiquidityUrl: 'https://ftm.curve.fi/fusdt/deposit',
    createdAt: 1626963081,
  },
  {
    id: 'curve-ftm-ren',
    logo: 'single-assets/renBTC.png',
    name: 'WBTC/renBTC',
    token: 'btcCRV',
    tokenDescription: 'Curve',
    tokenAddress: '0x5B5CFE992AdAC0C9D48E05854B2d91C73a003858',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCurveRenBTC',
    earnedTokenAddress: '0xda3c57A81aCe16b2cC34e8872e886575f8ccf672',
    earnContractAddress: '0xda3c57A81aCe16b2cC34e8872e886575f8ccf672',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-ftm-ren',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['WBTC', 'renBTC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://ftm.curve.fi/ren/deposit',
    createdAt: 1652662923,
  },
  {
    id: 'boo-mim-ftm',
    name: 'MIM-FTM LP',
    token: 'MIM-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x6f86e65b255c9111109d2D2325ca2dFc82456efc',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooMIM-FTM',
    earnedTokenAddress: '0x6676C93eb0F2daEfD3b9d03De2E3b18F888cF942',
    earnContractAddress: '0x6676C93eb0F2daEfD3b9d03De2E3b18F888cF942',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-mim-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['MIM', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x82f0B8B456c1A451378467398982d4834b6829c1',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x82f0B8B456c1A451378467398982d4834b6829c1',
    createdAt: 1625679284,
  },
  {
    id: 'boo-boo-ftm',
    name: 'BOO-FTM LP',
    token: 'BOO-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xEc7178F4C41f346b2721907F5cF7628E388A7a58',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooBoo-FTM',
    earnedTokenAddress: '0xEe3a7c885Fd3cc5358FF583F2DAB3b8bC473316f',
    earnContractAddress: '0xEe3a7c885Fd3cc5358FF583F2DAB3b8bC473316f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-boo-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['BOO', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE',
    createdAt: 1620386574,
  },
  {
    id: 'boo-bifi-ftm',
    name: 'BIFI-FTM LP',
    token: 'BIFI-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x1656728af3a14e1319F030Dc147fAbf6f627059e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooBIFI-FTM',
    earnedTokenAddress: '0xe8188B9701E6DB1Fe24c75783474D22e5957BBEF',
    earnContractAddress: '0xe8188B9701E6DB1Fe24c75783474D22e5957BBEF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-bifi-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['BIFI', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0xd6070ae98b8069de6B494332d1A1a81B6179D960',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0xd6070ae98b8069de6B494332d1A1a81B6179D960',
    createdAt: 1622569266,
  },
  {
    id: 'boo-ftm-ice',
    name: 'ICE-FTM LP',
    token: 'ICE-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x623EE4a7F290d11C11315994dB70FB148b13021d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooFTM-ICE',
    earnedTokenAddress: '0x33E0Feb374C9B7fC3Ac392140BdE3036263D8e5e',
    earnContractAddress: '0x33E0Feb374C9B7fC3Ac392140BdE3036263D8e5e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-ftm-ice',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['ICE', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0xf16e81dce15B08F326220742020379B855B87DF9',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0xf16e81dce15B08F326220742020379B855B87DF9',
    createdAt: 1622445591,
  },
  {
    id: 'boo-ftm-sushi',
    name: 'SUSHI-FTM LP',
    token: 'SUSHI-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xf84E313B36E86315af7a06ff26C8b20e9EB443C3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooFTM-SUSHI',
    earnedTokenAddress: '0xb8c90D7C60Cf88915BCc4F388F207D5F7fDaA9b4',
    earnContractAddress: '0xb8c90D7C60Cf88915BCc4F388F207D5F7fDaA9b4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-ftm-sushi',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['SUSHI', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0xae75A438b2E0cB8Bb01Ec1E1e376De11D44477CC',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0xae75A438b2E0cB8Bb01Ec1E1e376De11D44477CC',
    createdAt: 1622446524,
  },
  {
    id: 'boo-ftm-usdc',
    name: 'USDC-FTM LP',
    token: 'USDC-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x2b4C76d0dc16BE1C31D4C1DC53bF9B45987Fc75c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooFTM-USDC',
    earnedTokenAddress: '0x41D44B276904561Ac51855159516FD4cB2c90968',
    earnContractAddress: '0x41D44B276904561Ac51855159516FD4cB2c90968',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-ftm-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['USDC', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    createdAt: 1620386549,
  },
  {
    id: 'boo-any-ftm',
    name: 'ANY-FTM LP',
    token: 'ANY-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x5c021D9cfaD40aaFC57786b409A9ce571de375b4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooANY-FTM',
    earnedTokenAddress: '0xEE40C6Decc15dCeCE7a836e5CD5Ff94523D32265',
    earnContractAddress: '0xEE40C6Decc15dCeCE7a836e5CD5Ff94523D32265',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-any-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['ANY', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0xdDcb3fFD12750B45d32E084887fdf1aABAb34239',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0xdDcb3fFD12750B45d32E084887fdf1aABAb34239',
    createdAt: 1622123539,
  },
  {
    id: 'boo-dai-ftm',
    name: 'DAI-FTM LP',
    token: 'DAI-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xe120ffBDA0d14f3Bb6d6053E90E63c572A66a428',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooDAI-FTM',
    earnedTokenAddress: '0x8316b990De26eB530B7B1bb0d87f5b0a304637cd',
    earnContractAddress: '0x8316b990De26eB530B7B1bb0d87f5b0a304637cd',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-dai-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['DAI', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    createdAt: 1622123567,
  },
  {
    id: 'ester-est-ftm-eol',
    name: 'EST-FTM LP',
    token: 'EST-FTM LP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x0c9043cb1B994C8e4a8024e2F037Ea50b7025a82',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooEsterEST-FTM',
    earnedTokenAddress: '0x59247cfe6FC768A8C3F95601008B75A94F4939Bd',
    earnContractAddress: '0x59247cfe6FC768A8C3F95601008B75A94F4939Bd',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ester-est-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'tvl',
    platform: 'Other',
    assets: ['EST', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0x181F3F22C9a751E2ce673498A03E1FDFC0ebBFB6',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/0x181F3F22C9a751E2ce673498A03E1FDFC0ebBFB6',
    createdAt: 1621505384,
  },
  {
    id: 'ester-est-eol',
    logo: 'single-assets/EST.png',
    name: 'EST',
    token: 'EST',
    tokenDescription: 'Ester',
    tokenAddress: '0x181F3F22C9a751E2ce673498A03E1FDFC0ebBFB6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooEsterEST',
    earnedTokenAddress: '0x7076a33b6525132fF77F0FeE2daB2a1e79688DA0',
    earnContractAddress: '0x7076a33b6525132fF77F0FeE2daB2a1e79688DA0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'EST',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['EST'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/0x181F3F22C9a751E2ce673498A03E1FDFC0ebBFB6',
    createdAt: 1621508945,
    retireReason: 'tvl',
  },
  {
    id: 'boo-woofy-ftm-eol',
    name: 'WOOFY-FTM LP',
    token: 'WOOFY-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xfD0aB56B83130ce8f2b7A4f4d4532dEe495c0794',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooWOOFY-FTM',
    earnedTokenAddress: '0x7CE2332fAF6328986C75e3A8fCc1CB79621aeB1F',
    earnContractAddress: '0x7CE2332fAF6328986C75e3A8fCc1CB79621aeB1F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-woofy-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'SpookySwap',
    assets: ['WOOFY', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    retireReason: 'rewards',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0xD0660cD418a64a1d44E9214ad8e459324D8157f1',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0xD0660cD418a64a1d44E9214ad8e459324D8157f1',
    createdAt: 1621266087,
  },
  {
    id: 'boo-bnb-ftm',
    name: 'BNB-FTM LP',
    token: 'BNB-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x956DE13EA0FA5b577E4097Be837BF4aC80005820',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooBNB-FTM',
    earnedTokenAddress: '0xC5b2a6aB801E74F098aCC8Bb62B786b47319c4D9',
    earnContractAddress: '0xC5b2a6aB801E74F098aCC8Bb62B786b47319c4D9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-bnb-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['BNB', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454',
    createdAt: 1620919260,
  },
  {
    id: 'froyo-3pool-eol',
    logo: 'fantom/FROYO-3Pool.png',
    name: 'USDT/DAI/USDC',
    token: 'FROYO 3Pool',
    tokenDescription: 'Froyo',
    tokenAddress: '0x4f85Bbf3B0265DCEd4Ec72ebD0358ccCf190F1B3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooFroyo3Pool',
    earnedTokenAddress: '0xb18398ad9735fea37C97dcba50C0Bb947Dc0eeBD',
    earnContractAddress: '0xb18398ad9735fea37C97dcba50C0Bb947Dc0eeBD',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'froyo-3pool',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['USDT', 'DAI', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratMultiLP',
    addLiquidityUrl: 'https://frozenyogurt.finance/pool',
    createdAt: 1620633860,
    retireReason: 'tvl',
  },
  {
    id: 'froyo-froyo-ftm-eol',
    name: 'FROYO-FTM LP',
    token: 'FROYO-FTM LP',
    tokenDescription: 'SushiSwap',
    tokenAddress: '0xBE0AF661BC9794E05fAb18C08C2C99811A0F45D2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooFroyoFROYO-FTM',
    earnedTokenAddress: '0xeD4ea30c755676C07d3e0e0f74Ff84C0193B4551',
    earnContractAddress: '0xeD4ea30c755676C07d3e0e0f74Ff84C0193B4551',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'froyo-froyo-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Other',
    assets: ['FROYO', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://app.sushi.com/add/ETH/0xA92d41Ab8eFeE617d80a829CD9F5683c5F793ADA',
    buyTokenUrl:
      'https://app.sushi.com/swap?outputCurrency=0xA92d41Ab8eFeE617d80a829CD9F5683c5F793ADA',
    createdAt: 1620626525,
    retireReason: 'tvl',
  },
  {
    id: 'boo-link-ftm',
    name: 'LINK-FTM LP',
    token: 'LINK-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x89d9bC2F2d091CfBFc31e333D6Dc555dDBc2fd29',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooLINK-FTM',
    earnedTokenAddress: '0x711969A90C9EDD815A5C2b441FC80d067EC5E969',
    earnContractAddress: '0x711969A90C9EDD815A5C2b441FC80d067EC5E969',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-link-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['LINK', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0xb3654dc3D10Ea7645f8319668E8F54d2574FBdC8',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0xb3654dc3D10Ea7645f8319668E8F54d2574FBdC8',
    createdAt: 1620500758,
  },
  {
    id: 'boo-cover-ftm-eol',
    name: 'COVER-FTM LP',
    token: 'COVER-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x5DC7848bF215F1D99F2AF3d2Bf78fcdf238EE34b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooCOVER-FTM',
    earnedTokenAddress: '0xF5Bf6b3624eb10Ee855FEC1C5a4af81D90996b6c',
    earnContractAddress: '0xF5Bf6b3624eb10Ee855FEC1C5a4af81D90996b6c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-cover-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'SpookySwap',
    assets: ['COVER', 'FTM'],
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0xB01E8419d842beebf1b70A7b5f7142abbaf7159D',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0xB01E8419d842beebf1b70A7b5f7142abbaf7159D',
    createdAt: 1620500733,
  },
  {
    id: 'boo-snx-ftm-eol',
    name: 'SNX-FTM LP',
    token: 'SNX-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x06d173628bE105fE81F1C82c9979bA79eBCAfCB7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooSNX-FTM',
    earnedTokenAddress: '0x74907ad4E79b1Ce415caB26FEf526ae017598cEe',
    earnContractAddress: '0x74907ad4E79b1Ce415caB26FEf526ae017598cEe',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-snx-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'SpookySwap',
    assets: ['SNX', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    retireReason: 'rewards',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x56ee926bD8c72B2d5fa1aF4d9E4Cbb515a1E3Adc',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x56ee926bD8c72B2d5fa1aF4d9E4Cbb515a1E3Adc',
    createdAt: 1620500694,
  },
  {
    id: 'boo-usdt-ftm',
    name: 'fUSDT-FTM LP',
    token: 'fUSDT-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x5965E53aa80a0bcF1CD6dbDd72e6A9b2AA047410',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooUSDT-FTM',
    earnedTokenAddress: '0x5d89017d2465115007AbA00da1E6446dF2C19f34',
    earnContractAddress: '0x5d89017d2465115007AbA00da1E6446dF2C19f34',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-usdt-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['fUSDT', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    createdAt: 1620500483,
  },
  {
    id: 'boo-btc-ftm',
    name: 'WBTC-FTM LP',
    token: 'WBTC-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xFdb9Ab8B9513Ad9E419Cf19530feE49d412C3Ee3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooBTC-FTM',
    earnedTokenAddress: '0xA3e3Af161943CfB3941B631676134bb048739727',
    earnContractAddress: '0xA3e3Af161943CfB3941B631676134bb048739727',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-btc-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['WBTC', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x321162Cd933E2Be498Cd2267a90534A804051b11',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x321162Cd933E2Be498Cd2267a90534A804051b11',
    createdAt: 1620500455,
  },
  {
    id: 'boo-eth-ftm',
    name: 'ETH-FTM LP',
    token: 'ETH-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xf0702249F4D3A25cD3DED7859a165693685Ab577',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooETH-FTM',
    earnedTokenAddress: '0x2a30C5e0d577108F694d2A96179cd73611Ee069b',
    earnContractAddress: '0x2a30C5e0d577108F694d2A96179cd73611Ee069b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-eth-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['ETH', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x74b23882a30290451A17c44f4F05243b6b58C76d',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x74b23882a30290451A17c44f4F05243b6b58C76d',
    createdAt: 1620386666,
  },
  {
    id: 'boo-aave-ftm-eol',
    name: 'AAVE-FTM LP',
    token: 'AAVE-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xeBF374bB21D83Cf010cC7363918776aDF6FF2BF6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooAAVE-FTM',
    earnedTokenAddress: '0xDa4bb93Bac7CC00F6c6e2193d115Cf45099b31a0',
    earnContractAddress: '0xDa4bb93Bac7CC00F6c6e2193d115Cf45099b31a0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-aave-ftm',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'SpookySwap',
    assets: ['AAVE', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x6a07A792ab2965C72a5B8088d3a069A7aC3a993B',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x6a07A792ab2965C72a5B8088d3a069A7aC3a993B',
    createdAt: 1620386633,
  },
  {
    id: 'boo-crv-ftm',
    name: 'CRV-FTM LP',
    token: 'CRV-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xB471Ac6eF617e952b84C6a9fF5de65A9da96C93B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooCRV-FTM',
    earnedTokenAddress: '0xdf68Bf80D427A5827Ff2c06A9c70D407e17DC041',
    earnContractAddress: '0xdf68Bf80D427A5827Ff2c06A9c70D407e17DC041',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-crv-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['CRV', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://spooky.fi/#/add/FTM/0x1E4F97b9f9F913c46F1632781732927B9019C68b',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0x1E4F97b9f9F913c46F1632781732927B9019C68b',
    createdAt: 1620386607,
  },
];
