export const celoPools = [
  {
    id: 'celo-bifi-maxi',
    logo: 'single-assets/BIFI.png',
    name: 'BIFI Maxi',
    token: 'BIFI',
    tokenDescription: 'Beefy',
    tokenAddress: '0x639A647fbe20b6c8ac19E48E2de44ea792c62c5C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCeloBIFI',
    earnedTokenAddress: '0xf68C61E3c2f9C48E53391E1FCd2db1f19998151b',
    earnContractAddress: '0xf68C61E3c2f9C48E53391E1FCd2db1f19998151b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BIFI',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beefy',
    assets: ['BIFI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Maxi',
    withdrawalFee: '0.05%',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0x639A647fbe20b6c8ac19E48E2de44ea792c62c5C',
    createdAt: 1636753207,
  },
  {
    id: 'sushi-celo-celo-mobi-eol',
    name: 'CELO-MOBI LP',
    token: 'CELO-MOBI LPv2',
    tokenDescription: 'Sushi',
    tokenAddress: '0x8ecDed81A2AbF3b7e724978060739edBeb01b24F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiCELO-MOBI',
    earnedTokenAddress: '0xecCb80E860561Ca9Ffb183A557F32CC90B05d3c3',
    earnContractAddress: '0xecCb80E860561Ca9Ffb183A557F32CC90B05d3c3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-celo-celo-mobi',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'SushiSwap',
    withdrawalFee: '0%',
    assets: ['CELO', 'MOBI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x471EcE3750Da237f93B8E339c536989b8978a438/0x73a210637f6F6B7005512677Ba6B3C96bb4AA44B',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x471EcE3750Da237f93B8E339c536989b8978a438&outputCurrency=0x73a210637f6F6B7005512677Ba6B3C96bb4AA44B',
    createdAt: 1641132389,
  },
  {
    id: 'sushi-celo-weth-usdt',
    name: 'USDT-WETH LP',
    token: 'USDT-WETH LPv2',
    tokenDescription: 'Sushi',
    tokenAddress: '0xC77398cfb7B0F7ab42baFC02ABc20A69CE8cEf7f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiWETH-USDT',
    earnedTokenAddress: '0x37A8b016EF27fBCF73F73Fb9Dc1C09C47A5d7E48',
    earnContractAddress: '0x37A8b016EF27fBCF73F73Fb9Dc1C09C47A5d7E48',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-celo-weth-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    withdrawalFee: '0%',
    assets: ['USDT', 'WETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x122013fd7dF1C6F636a5bb8f03108E876548b455/0x88eeC49252c8cbc039DCdB394c0c2BA2f1637EA0',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x122013fd7dF1C6F636a5bb8f03108E876548b455a&outputCurrency=0x88eeC49252c8cbc039DCdB394c0c2BA2f1637EA0',
    createdAt: 1641132354,
  },
  {
    id: 'sushi-celo-weth-daiv2',
    name: 'DAI-WETH LP',
    token: 'DAI-WETH LPv2',
    tokenDescription: 'Sushi',
    tokenAddress: '0xCCd9d850eF40f19566cd8df950765E9A1a0B9Ef2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiWETH-DAIv2',
    earnedTokenAddress: '0xbA79aC22fA300961A89547955d1363d7896B497d',
    earnContractAddress: '0xbA79aC22fA300961A89547955d1363d7896B497d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-celo-weth-daiv2',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    withdrawalFee: '0%',
    assets: ['DAI', 'WETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x122013fd7dF1C6F636a5bb8f03108E876548b455/0xE4fE50cdD716522A56204352f00AA110F731932d',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x122013fd7dF1C6F636a5bb8f03108E876548b455a&outputCurrency=0xE4fE50cdD716522A56204352f00AA110F731932d',
    createdAt: 1641132429,
  },
  {
    id: 'sushi-celo-cusd-usdc',
    name: 'cUSD-USDC LP',
    token: 'cUSD-USDC LPv2',
    tokenDescription: 'Sushi',
    tokenAddress: '0xA364EdE5590B46E618527d7535506743D978bBF2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushicUSD-USDC',
    earnedTokenAddress: '0xc8Acba0068B0F80F5176B6e14b9c7D1aF9B0F9A2',
    earnContractAddress: '0xc8Acba0068B0F80F5176B6e14b9c7D1aF9B0F9A2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-celo-cusd-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    withdrawalFee: '0%',
    assets: ['cUSD', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x765DE816845861e75A25fCA122bb6898B8B1282a/0xef4229c8c3250C675F21BCefa42f58EfbfF6002a',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x765DE816845861e75A25fCA122bb6898B8B1282a&outputCurrency=0xef4229c8c3250C675F21BCefa42f58EfbfF6002a',
    createdAt: 1641129424,
  },
  {
    id: 'sushi-celo-wethv2-celo',
    name: 'CELO-WETH LP',
    token: 'CELO-WETH LPv2',
    tokenDescription: 'Sushi',
    tokenAddress: '0x517f717f0AE11efc2AF8943071B66d77DfaFc9fC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiCELO-WETHv2',
    earnedTokenAddress: '0x5261780fc6e0AE1FfCe37682044371932c2e8E97',
    earnContractAddress: '0x5261780fc6e0AE1FfCe37682044371932c2e8E97',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-celo-wethv2-celo',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    withdrawalFee: '0%',
    assets: ['CELO', 'WETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x471EcE3750Da237f93B8E339c536989b8978a438/0x122013fd7dF1C6F636a5bb8f03108E876548b455',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x471EcE3750Da237f93B8E339c536989b8978a438&outputCurrency=0x122013fd7dF1C6F636a5bb8f03108E876548b455',
    createdAt: 1641132564,
  },
  {
    id: 'sushi-celo-wethv2-cusd',
    name: 'cUSD-WETH LP',
    token: 'cUSD-WETH LPv2',
    tokenDescription: 'Sushi',
    tokenAddress: '0x93887e0fA9F6C375b2765a6fE885593F16f077f9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushicUSD-WETHv2',
    earnedTokenAddress: '0x8F2894459026CaE358C4613eD6779e515f1F514e',
    earnContractAddress: '0x8F2894459026CaE358C4613eD6779e515f1F514e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-celo-wethv2-cusd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    withdrawalFee: '0%',
    assets: ['cUSD', 'WETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x765DE816845861e75A25fCA122bb6898B8B1282a/0x122013fd7dF1C6F636a5bb8f03108E876548b455',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x765DE816845861e75A25fCA122bb6898B8B1282a&outputCurrency=0x122013fd7dF1C6F636a5bb8f03108E876548b455',
    createdAt: 1641132539,
  },
  {
    id: 'sushi-celo-cusd-ceurv2',
    name: 'cUSD-cEUR LP',
    token: 'cUSD-cEUR LPv2',
    tokenDescription: 'Sushi',
    tokenAddress: '0x0b655E7D966CB27998af94AA5719ab7BFe07D3b3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushicUSD-cEURv2',
    earnedTokenAddress: '0x6ad6c1f5c2Fb80e1240D7B8242496B3A4e021c68',
    earnContractAddress: '0x6ad6c1f5c2Fb80e1240D7B8242496B3A4e021c68',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-celo-cusd-ceurv2',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    withdrawalFee: '0%',
    assets: ['cUSD', 'cEUR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x765DE816845861e75A25fCA122bb6898B8B1282a/0xD8763CBa276a3738E6DE85b4b3bF5FDed6D6cA73',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x765DE816845861e75A25fCA122bb6898B8B1282a&outputCurrency=0xD8763CBa276a3738E6DE85b4b3bF5FDed6D6cA73',
    createdAt: 1641132464,
  },
  {
    id: 'sushi-celo-celo-weth-eol',
    name: 'CELO-WETH LP',
    token: 'CELO-WETH LP',
    tokenDescription: 'Sushi',
    tokenAddress: '0x38F3c44552a3F6E4D21C8c328cE4904DA766D12f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiCELO-WETH',
    earnedTokenAddress: '0x8a198BCbF313A5565c64A7Ed61FaA413eB4E0931',
    earnContractAddress: '0x8a198BCbF313A5565c64A7Ed61FaA413eB4E0931',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-celo-celo-weth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'SushiSwap',
    withdrawalFee: '0%',
    assets: ['CELO', 'WETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x471EcE3750Da237f93B8E339c536989b8978a438/0xE919F65739c26a42616b7b8eedC6b5524d1e3aC4',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x471EcE3750Da237f93B8E339c536989b8978a438&outputCurrency=0xE919F65739c26a42616b7b8eedC6b5524d1e3aC4',
    createdAt: 1635168273,
  },
  {
    id: 'sushi-celo-cusd-dai-eol',
    name: 'cUSD-DAI LP',
    token: 'cUSD-DAI LP',
    tokenDescription: 'Sushi',
    tokenAddress: '0x850197dd5A4865324291d7B5745622A8cd732BCb',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushicUSD-DAI',
    earnedTokenAddress: '0x7f6fE34C51d5352A0CF375C0Fbe03bD19eCD8460',
    earnContractAddress: '0x7f6fE34C51d5352A0CF375C0Fbe03bD19eCD8460',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-celo-cusd-dai',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'SushiSwap',
    withdrawalFee: '0%',
    assets: ['cUSD', 'DAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x765DE816845861e75A25fCA122bb6898B8B1282a/0xE4fE50cdD716522A56204352f00AA110F731932d',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x471EcE3750Da237f93B8E339c536989b8978a438&outputCurrency=0xE4fE50cdD716522A56204352f00AA110F731932d',
    createdAt: 1635168343,
  },
  {
    id: 'sushi-celo-ceur-weth-eol',
    name: 'cEUR-WETH LP',
    token: 'cEUR-WETH LP',
    tokenDescription: 'Sushi',
    tokenAddress: '0xfED4f77af916B62CB073aD7AD2b2C1794c939023',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushicEUR-WETH',
    earnedTokenAddress: '0xa5aaE3a55cA356C62b5425AA4bFC212542B17777',
    earnContractAddress: '0xa5aaE3a55cA356C62b5425AA4bFC212542B17777',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-celo-ceur-weth',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'SushiSwap',
    withdrawalFee: '0%',
    assets: ['cEUR', 'WETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0xD8763CBa276a3738E6DE85b4b3bF5FDed6D6cA73/0xE919F65739c26a42616b7b8eedC6b5524d1e3aC4',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0xD8763CBa276a3738E6DE85b4b3bF5FDed6D6cA73&outputCurrency=0xE919F65739c26a42616b7b8eedC6b5524d1e3aC4',
    createdAt: 1635168398,
  },
  {
    id: 'sushi-celo-cusd-ceur-eol',
    name: 'cUSD-cEUR LP',
    token: 'cUSD-cEUR LP',
    tokenDescription: 'Sushi',
    tokenAddress: '0x0b655E7D966CB27998af94AA5719ab7BFe07D3b3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushicUSD-cEUR',
    earnedTokenAddress: '0xA338D34c5de06B88197609956a2dEAAfF7Af46c8',
    earnContractAddress: '0xA338D34c5de06B88197609956a2dEAAfF7Af46c8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-celo-cusd-ceur',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'SushiSwap',
    withdrawalFee: '0%',
    assets: ['cUSD', 'cEUR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x765DE816845861e75A25fCA122bb6898B8B1282a/0xD8763CBa276a3738E6DE85b4b3bF5FDed6D6cA73',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x765DE816845861e75A25fCA122bb6898B8B1282a&outputCurrency=0xD8763CBa276a3738E6DE85b4b3bF5FDed6D6cA73',
    createdAt: 1635168488,
  },
];
