import { govPoolABI } from '../abi';

export const emeraldStakePools = [
  {
    id: 'moo_valley-usdt-vs-valleyswap',
    name: 'ValleySwap',
    assets: ['VS', 'USDT'],
    token: 'mooValleyUSDT-VS',
    tokenDecimals: 18,
    tokenAddress: '0x92Ac3C4D9DACb5D0C1b580FaC5402a237A170f7b',
    tokenOracle: 'lps',
    tokenOracleId: 'valley-usdt-vs',
    earnedToken: 'VS',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xBC033203796CC2C8C543a5aAe93a9a643320433D',
    earnContractAddress: '0x405EE7F4f067604b787346bC22ACb66b06b15A4B',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'VS',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    periodFinish: 1656227547,
    partners: [
      {
        text: 'ValleySwap is a decentralized exchange on the Oasis Emerald paratime that includes features like liquidity farming, swap and ICO. Valleyswap follows a non-custodial, peer-to-peer, automated-market-maker model. We aim to provide fast, safe and cheap service to swap tokens within the Oasis emerald paratime. We aim to become the best launchpad on Oasis emerald.',
        website: 'https://valleyswap.com/',
        social: {
          telegram: 'https://t.me/valleyswap_chat',
          twitter: 'https://twitter.com/ValleySwap',
        },
      },
    ],
  },
  {
    id: 'moo_valley-usdt-usdc-valleyswap',
    name: 'ValleySwap',
    assets: ['USDC', 'USDT'],
    token: 'mooValleyUSDT-USDC',
    tokenDecimals: 18,
    tokenAddress: '0xa7D151e2bCd83750e2596e794248cc400F018a23',
    tokenOracle: 'lps',
    tokenOracleId: 'valley-usdt-usdc',
    earnedToken: 'VS',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xBC033203796CC2C8C543a5aAe93a9a643320433D',
    earnContractAddress: '0xc3a4fdcba79DB04b4C3e352b1C467B3Ba909D84A',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'VS',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    periodFinish: 1656227640,
    partners: [
      {
        text: 'ValleySwap is a decentralized exchange on the Oasis Emerald paratime that includes features like liquidity farming, swap and ICO. Valleyswap follows a non-custodial, peer-to-peer, automated-market-maker model. We aim to provide fast, safe and cheap service to swap tokens within the Oasis emerald paratime. We aim to become the best launchpad on Oasis emerald.',
        website: 'https://valleyswap.com/',
        social: {
          telegram: 'https://t.me/valleyswap_chat',
          twitter: 'https://twitter.com/ValleySwap',
        },
      },
    ],
  },
  {
    id: 'moo_valley-btc-usdt-valleyswap',
    name: 'ValleySwap',
    assets: ['BTC', 'USDT'],
    token: 'mooValleyBTC-USDT',
    tokenDecimals: 18,
    tokenAddress: '0x74d6b1D419556d8A3E3038A9c8096DA0cA4beF24',
    tokenOracle: 'lps',
    tokenOracleId: 'valley-btc-usdt',
    earnedToken: 'VS',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xBC033203796CC2C8C543a5aAe93a9a643320433D',
    earnContractAddress: '0x3C9C884eFAB85c44D675039de227b3Dd275c360e',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'VS',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    periodFinish: 1656227663,
    partners: [
      {
        text: 'ValleySwap is a decentralized exchange on the Oasis Emerald paratime that includes features like liquidity farming, swap and ICO. Valleyswap follows a non-custodial, peer-to-peer, automated-market-maker model. We aim to provide fast, safe and cheap service to swap tokens within the Oasis emerald paratime. We aim to become the best launchpad on Oasis emerald.',
        website: 'https://valleyswap.com/',
        social: {
          telegram: 'https://t.me/valleyswap_chat',
          twitter: 'https://twitter.com/ValleySwap',
        },
      },
    ],
  },
  {
    id: 'moo_valley-usdt-eth-valleyswap',
    name: 'ValleySwap',
    assets: ['ETH', 'USDT'],
    token: 'mooValleyUSDT-ETH',
    tokenDecimals: 18,
    tokenAddress: '0x3cf4fe3f469b0710E8a42e44B64c85DFc300f22A',
    tokenOracle: 'lps',
    tokenOracleId: 'valley-usdt-eth',
    earnedToken: 'VS',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xBC033203796CC2C8C543a5aAe93a9a643320433D',
    earnContractAddress: '0x3f385082Ee3dFf58ca0a6a7fe44Ea0B5d6b4168E',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'VS',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    periodFinish: 1656227680,
    partners: [
      {
        text: 'ValleySwap is a decentralized exchange on the Oasis Emerald paratime that includes features like liquidity farming, swap and ICO. Valleyswap follows a non-custodial, peer-to-peer, automated-market-maker model. We aim to provide fast, safe and cheap service to swap tokens within the Oasis emerald paratime. We aim to become the best launchpad on Oasis emerald.',
        website: 'https://valleyswap.com/',
        social: {
          telegram: 'https://t.me/valleyswap_chat',
          twitter: 'https://twitter.com/ValleySwap',
        },
      },
    ],
  },
];
