export const moonbeamPools = [
  {
    id: 'moonbeam-bifi-maxi',
    logo: 'single-assets/BIFI.png',
    name: 'BIFI Maxi',
    token: 'BIFI',
    tokenDescription: 'Beefy',
    tokenAddress: '0x595c8481c48894771CE8FaDE54ac6Bf59093F9E8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMoonbeamBIFI',
    earnedTokenAddress: '0xd1bAb603eee03fA99A378d90d5d83186fEB81aA9',
    earnContractAddress: '0xd1bAb603eee03fA99A378d90d5d83186fEB81aA9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BIFI',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beefy',
    assets: ['BIFI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Maxi',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.beamswap.io/exchange/swap?inputCurrency=0xacc15dc74880c9944775448304b263d191c6077f&outputCurrency=0x595c8481c48894771CE8FaDE54ac6Bf59093F9E8',
    createdAt: 1644700692,
  },
  {
    id: 'stellaswap-dualETH',
    name: 'ETH.mad-ETH.any LP',
    token: 'ETH.mad-ETH.any LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0xa3eE3a0a36Dc915fDc93062e4B386DF37d00217E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStellaswapDualETH',
    earnedTokenAddress: '0xdd856F58E8B01579ef5DbE78f597615D66c320EE',
    earnContractAddress: '0xdd856F58E8B01579ef5DbE78f597615D66c320EE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-dualETH',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stellaswap',
    assets: ['ETHs', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/pool/stable/add/0xb86271571c90ad4E0C9776228437340b42623402',
    createdAt: 1653747480,
  },

  {
    id: 'stellaswap-weth-wglmr',
    name: 'ETH.mad-GLMR LP',
    token: 'ETH.mad-GLMR LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0x9d19EDBFd29D2e01537624B25806493dA0d73bBE',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStellaswapETHmad-GLMR',
    earnedTokenAddress: '0xA2809e408875B892803d62Ed07c9fa79364be555',
    earnContractAddress: '0xA2809e408875B892803d62Ed07c9fa79364be555',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-weth-wglmr',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stellaswap',
    assets: ['GLMR', 'ETHs'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/0xAcc15dC74880C9944775448304B263D191c6077F/0x30D2a9F5FDf90ACe8c17952cbb4eE48a55D916A7',
    createdAt: 1653485280,
  },

  {
    id: 'stellaswap-usdcmad-wglmr',
    name: 'USDC.mad-GLMR LP',
    token: 'USDC.mad-GLMR LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0x9bFcf685e641206115dadc0C9ab17181e1d4975c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStellaswapUSDCmad-GLMR',
    earnedTokenAddress: '0xD03855Caad39992Eb04D730eafb7b0D8b789C4cB',
    earnContractAddress: '0xD03855Caad39992Eb04D730eafb7b0D8b789C4cB',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-usdcmad-wglmr',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stellaswap',
    assets: ['GLMR', 'USDCs'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/0xAcc15dC74880C9944775448304B263D191c6077F/0x8f552a71EFE5eeFc207Bf75485b356A0b3f01eC9',
    createdAt: 1653477432,
  },

  {
    id: 'stellaswap-stella-usdc',
    name: 'STELLA-USDC LP',
    token: 'STELLA-USDC LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0x81e11a9374033d11Cc7e7485A7192AE37D0795D6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStellaswapSTELLA-USDC',
    earnedTokenAddress: '0xfb47EC2f215D579cc4Cb0B19ec21AD58749518ad',
    earnContractAddress: '0xfb47EC2f215D579cc4Cb0B19ec21AD58749518ad',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-stella-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stellaswap',
    assets: ['STELLA', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/0x0E358838ce72d5e61E0018a2ffaC4bEC5F4c88d2/0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
    buyTokenUrl: 'https://app.stellaswap.com/exchange/swap',
    createdAt: 1647359160,
  },
  {
    id: 'stellaswap-stella-wglmr',
    name: 'STELLA-GLMR LP',
    token: 'STELLA-GLMR LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0x7F5Ac0FC127bcf1eAf54E3cd01b00300a0861a62',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStellaswapSTELLA-GLMR',
    earnedTokenAddress: '0x515989A9Add6Bb3Ab78b59a2AE30E398E740886F',
    earnContractAddress: '0x515989A9Add6Bb3Ab78b59a2AE30E398E740886F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-stella-wglmr',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stellaswap',
    assets: ['STELLA', 'GLMR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/0x0E358838ce72d5e61E0018a2ffaC4bEC5F4c88d2/ETH',
    buyTokenUrl: 'https://app.stellaswap.com/exchange/swap',
    createdAt: 1647351540,
  },
  {
    id: 'stella-xstella',
    logo: 'single-assets/STELLA.png',
    name: 'STELLA',
    token: 'STELLA',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0x0E358838ce72d5e61E0018a2ffaC4bEC5F4c88d2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStella',
    earnedTokenAddress: '0x981505ca00b78f15d2c22D6B41f1c0ea96a56f3a',
    earnContractAddress: '0x981505ca00b78f15d2c22D6B41f1c0ea96a56f3a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'STELLA',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stellaswap',
    assets: ['STELLA'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.beamswap.io/exchange/swap?inputCurrency=0xacc15dc74880c9944775448304b263d191c6077f&outputCurrency=0x0E358838ce72d5e61E0018a2ffaC4bEC5F4c88d2',
    createdAt: 1651693314,
  },
  {
    id: 'stellaswap-wglmr-xcdot',
    name: 'xcDOT-GLMR LP',
    token: 'xcDOT-GLMR LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0xa927E1e1E044CA1D9fe1854585003477331fE2Af',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStellaxcDOT-GLMR',
    earnedTokenAddress: '0xAECd33b7b048960A78CacF59F8da658E1FF0eD64',
    earnContractAddress: '0xAECd33b7b048960A78CacF59F8da658E1FF0eD64',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-wglmr-xcdot',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stellaswap',
    assets: ['xcDOT', 'GLMR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/ETH/0xFfFFfFff1FcaCBd218EDc0EbA20Fc2308C778080',
    buyTokenUrl: 'https://app.stellaswap.com/exchange/swap',
    createdAt: 1651689624,
  },
  {
    id: 'stellaswap-atom-wglmr',
    name: 'ATOM-GLMR LP',
    token: 'ATOM-GLMR LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0xf4C10263f2A4B1f75b8a5FD5328fb61605321639',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStellaswapATOM-GLMR',
    earnedTokenAddress: '0x3f08725D67046Ac3d736272127B2F3adff396B1e',
    earnContractAddress: '0x3f08725D67046Ac3d736272127B2F3adff396B1e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-atom-wglmr',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stellaswap',
    assets: ['ATOM', 'GLMR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/ETH/0x27292cf0016E5dF1d8b37306B2A98588aCbD6fCA',
    buyTokenUrl: 'https://app.stellaswap.com/exchange/swap',
    createdAt: 1649857662,
  },
  {
    id: 'stellaswap-atom-usdc',
    name: 'ATOM-USDC LP',
    token: 'ATOM-USDC LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0x051FcF8986B30860a1341e0031e5622Bd18d8A85',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStellaswapATOM-USDC',
    earnedTokenAddress: '0x0F944e797fd83a1533dD44031C858ee388655cd6',
    earnContractAddress: '0x0F944e797fd83a1533dD44031C858ee388655cd6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-atom-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stellaswap',
    assets: ['ATOM', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b/0x27292cf0016E5dF1d8b37306B2A98588aCbD6fCA',
    buyTokenUrl: 'https://app.stellaswap.com/exchange/swap',
    createdAt: 1649857842,
  },
  {
    id: 'stellaswap-usdc-ftm',
    name: 'USDC-FTM LP',
    token: 'USDC-FTM LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0x077Fc7B4455050249D7F5511ED588a665E03e6C5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStellaswapUSDC-FTM',
    earnedTokenAddress: '0x622d8A33d67464596b6612BB2d396a8d7870B536',
    earnContractAddress: '0x622d8A33d67464596b6612BB2d396a8d7870B536',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-usdc-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stellaswap',
    assets: ['USDC', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b/0xC19281F22A075E0F10351cd5D6Ea9f0AC63d4327',
    buyTokenUrl: 'https://app.stellaswap.com/exchange/swap',
    createdAt: 1648745802,
  },
  {
    id: 'stellaswap-wglmr-ftm',
    name: 'GLMR-FTM LP',
    token: 'GLMR-FTM LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0x2Afab635EA2DC4B498EF1C00E63b7a7dBa9c93C6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStellaswapGLMR-FTM',
    earnedTokenAddress: '0xbCF6b67A5Cd2642503b92c6367F9F0C592ACA744',
    earnContractAddress: '0xbCF6b67A5Cd2642503b92c6367F9F0C592ACA744',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-wglmr-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stellaswap',
    assets: ['GLMR', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/0xC19281F22A075E0F10351cd5D6Ea9f0AC63d4327/ETH',
    buyTokenUrl: 'https://app.stellaswap.com/exchange/swap',
    createdAt: 1648745970,
  },
  {
    id: 'stellaswap-avax-wglmr',
    name: 'AVAX-GLMR LP',
    token: 'AVAX-GLMR LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0x2F412C140080039eaF9dE01c95e84cFb1c8D98aD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStellaswapAVAX-GLMR',
    earnedTokenAddress: '0x016A2b0AA20F595f3d692529d74bF107E394B69f',
    earnContractAddress: '0x016A2b0AA20F595f3d692529d74bF107E394B69f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-avax-wglmr',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stellaswap',
    assets: ['AVAX', 'GLMR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/0x4792C1EcB969B036eb51330c63bD27899A13D84e/ETH',
    buyTokenUrl: 'https://app.stellaswap.com/exchange/swap',
    createdAt: 1648746066,
  },
  {
    id: 'stellaswap-matic-wglmr',
    name: 'MATIC-GLMR LP',
    token: 'MATIC-GLMR LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0x6775ff3828a19EB5926C0C4D572183cA15Aa4C08',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStellaswapMATIC-GLMR',
    earnedTokenAddress: '0xcA010C39E70Dc625Ec4f10a9f5d7d28d3EAD2703',
    earnContractAddress: '0xcA010C39E70Dc625Ec4f10a9f5d7d28d3EAD2703',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-matic-wglmr',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stellaswap',
    assets: ['MATIC', 'GLMR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/0x3405A1bd46B85c5C029483FbECf2F3E611026e45/ETH',
    buyTokenUrl: 'https://app.stellaswap.com/exchange/swap',
    createdAt: 1648746150,
  },
  {
    id: 'stellaswap-luna-wglmr-eol',
    name: 'LUNA-GLMR LP',
    token: 'LUNA-GLMR LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0xE2BFE2F1437f249B15B7111011219995ecc43155',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStellaswapLUNA-GLMR',
    earnedTokenAddress: '0x253f0C0b847d516B49F8481df318d3DEcB048FC5',
    earnContractAddress: '0x253f0C0b847d516B49F8481df318d3DEcB048FC5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-luna-wglmr',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Stellaswap',
    assets: ['LUNA', 'GLMR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/0x31DAB3430f3081dfF3Ccd80F17AD98583437B213/ETH',
    buyTokenUrl: 'https://app.stellaswap.com/exchange/swap',
    createdAt: 1648746234,
  },
  {
    id: 'stellaswap-axlust-wglmr-eol',
    name: 'UST-GLMR LP',
    token: 'UST-GLMR LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0xb6835036C9A2A96D3BfB8DBE1722971fEb456a83',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStellaswapUST-GLMR',
    earnedTokenAddress: '0xb0b90AD6588F3B072Bc4B3E7237189E6AC6808A7',
    earnContractAddress: '0xb0b90AD6588F3B072Bc4B3E7237189E6AC6808A7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-axlust-wglmr',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Stellaswap',
    assets: ['UST', 'GLMR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'ALGO_STABLE',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/0x085416975fe14C2A731a97eC38B9bF8135231F62/ETH',
    buyTokenUrl: 'https://app.stellaswap.com/exchange/swap',
    createdAt: 1647958278,
  },
  {
    id: 'stellaswap-dai-usdc',
    name: 'DAI-USDC LP',
    token: 'DAI-USDC LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0x5Ced2f8DD70dc25cbA10ad18c7543Ad9ad5AEeDD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStellaswapDAI-USDC',
    earnedTokenAddress: '0x0b68f9585E24c8D271eFCf60ebd35c1a94A5F91f',
    earnContractAddress: '0x0b68f9585E24c8D271eFCf60ebd35c1a94A5F91f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-dai-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stellaswap',
    assets: ['DAI', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/0x765277EebeCA2e31912C9946eAe1021199B39C61/0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
    buyTokenUrl: 'https://app.stellaswap.com/exchange/swap',
    createdAt: 1647958428,
  },
  {
    id: 'stellaswap-usdc-eth',
    name: 'USDC-ETH LP',
    token: 'USDC-ETH LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0x0Aa48bF937ee8F41f1a52D225EF5A6F6961e39FA',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStellaswapUSDC-ETH',
    earnedTokenAddress: '0x44f04CA4B29c5F5471345dcaBAB6e40320374bf4',
    earnContractAddress: '0x44f04CA4B29c5F5471345dcaBAB6e40320374bf4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-usdc-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stellaswap',
    assets: ['USDC', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b/0xfA9343C3897324496A05fC75abeD6bAC29f8A40f',
    buyTokenUrl: 'https://app.stellaswap.com/exchange/swap',
    createdAt: 1647958674,
  },
  {
    id: 'stellaswap-wglmr-eth',
    name: 'GLMR-ETH LP',
    token: 'GLMR-ETH LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0x49a1cC58dCf28D0139dAEa9c18A3ca23108E78B3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStellaswapGLMR-ETH',
    earnedTokenAddress: '0x3871E20e08ae59bDa4b42A1a6C27403bEedDa042',
    earnContractAddress: '0x3871E20e08ae59bDa4b42A1a6C27403bEedDa042',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-wglmr-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stellaswap',
    assets: ['GLMR', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/ETH/0xfA9343C3897324496A05fC75abeD6bAC29f8A40f',
    buyTokenUrl: 'https://app.stellaswap.com/exchange/swap',
    createdAt: 1647958770,
  },
  {
    id: 'stellaswap-usdc-wglmr',
    name: 'USDC-GLMR LP',
    token: 'USDC-GLMR LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0x555B74dAFC4Ef3A5A1640041e3244460Dc7610d1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStellaswapUSDC-GLMR',
    earnedTokenAddress: '0x28C88367CC1E52A397dE5e1Ec3c4F163083ee607',
    earnContractAddress: '0x28C88367CC1E52A397dE5e1Ec3c4F163083ee607',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-usdc-wglmr',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stellaswap',
    assets: ['USDC', 'GLMR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b/ETH',
    buyTokenUrl: 'https://app.stellaswap.com/exchange/swap',
    createdAt: 1647355452,
  },
  {
    id: 'stellaswap-usdc-usdt',
    name: 'USDC-USDT LP',
    token: 'USDC-USDT LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0x8BC3CceeF43392B315dDD92ba30b435F79b66b9e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStellaswapUSDC-USDT',
    earnedTokenAddress: '0x1a93dB99B91c944CBC01093DE7F13779ca2c6ED6',
    earnContractAddress: '0x1a93dB99B91c944CBC01093DE7F13779ca2c6ED6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-usdc-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stellaswap',
    assets: ['USDC', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b/0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73',
    buyTokenUrl: 'https://app.stellaswap.com/exchange/swap',
    createdAt: 1647522606,
  },
  {
    id: 'stellaswap-usdc-bnb',
    name: 'USDC-BNB LP',
    token: 'USDC-BNB LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0xAc2657ba28768FE5F09052f07A9B7ea867A4608f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStellaswapUSDC-BNB',
    earnedTokenAddress: '0xA2e36EB5FecF7A567996214D6f1C9973dF59d93d',
    earnContractAddress: '0xA2e36EB5FecF7A567996214D6f1C9973dF59d93d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-usdc-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stellaswap',
    assets: ['USDC', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b/0xc9BAA8cfdDe8E328787E29b4B078abf2DaDc2055',
    buyTokenUrl: 'https://app.stellaswap.com/exchange/swap',
    createdAt: 1647524406,
  },
  {
    id: 'stellaswap-busd-wglmr',
    name: 'BUSD-GLMR LP',
    token: 'BUSD-GLMR LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0x367c36dAE9ba198A4FEe295c22bC98cB72f77Fe1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStellaswapBUSD-GLMR',
    earnedTokenAddress: '0x3543c002c913Bc90e69C689b945f8D2A042f1dA0',
    earnContractAddress: '0x3543c002c913Bc90e69C689b945f8D2A042f1dA0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-busd-wglmr',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stellaswap',
    assets: ['BUSD', 'GLMR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F/0xAcc15dC74880C9944775448304B263D191c6077F',
    buyTokenUrl: 'https://app.stellaswap.com/exchange/swap',
    createdAt: 1647525054,
  },
  {
    id: 'stellaswap-ape-wglmr-eol',
    name: 'APE-GLMR LP',
    token: 'APE-GLMR LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0x55Db71E6bEaB323290f6571C428C171e15CDBAD2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStellaswapAPE-GLMR',
    earnedTokenAddress: '0x77089478c41b6a8B29dDD6E3cb2F475475A228A5',
    earnContractAddress: '0x77089478c41b6a8B29dDD6E3cb2F475475A228A5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-ape-wglmr',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Stellaswap',
    assets: ['APE', 'GLMR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/0x3D632d9e1a60a0880Dd45E61f279D919b5748377/ETH',
    buyTokenUrl: 'https://app.stellaswap.com/exchange/swap',
    createdAt: 1648601028,
  },
  {
    id: 'solarflare-ust-wglmr-eol',
    name: 'UST-GLMR LP',
    token: 'UST-GLMR SLP',
    tokenDescription: 'Solarflare',
    tokenAddress: '0xa2844004Ce6336007f09D6Bf959B5602ABbf19fb',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarflareUST-GLMR',
    earnedTokenAddress: '0x9D55cAEE108aBdd4C47E42088C97ecA43510E969',
    earnContractAddress: '0x9D55cAEE108aBdd4C47E42088C97ecA43510E969',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarflare-ust-wglmr',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Solarflare',
    assets: ['UST', 'GLMR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'ALGO_STABLE',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.solarflare.io/exchange/add/0x085416975fe14C2A731a97eC38B9bF8135231F62/0xAcc15dC74880C9944775448304B263D191c6077F',
    buyTokenUrl:
      'https://app.solarflare.io/exchange/swap?inputCurrency=0x085416975fe14C2A731a97eC38B9bF8135231F62&outputCurrency=0xAcc15dC74880C9944775448304B263D191c6077F',
    createdAt: 1647958278,
  },
  {
    id: 'solarflare-luna-flare-eol',
    name: 'LUNA-FLARE LP',
    token: 'LUNA-FLARE SLP',
    tokenDescription: 'Solarflare',
    tokenAddress: '0xAc69fa960b5fcc90559BF39284C00946e7d8Eb78',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarflareLUNA-FLARE',
    earnedTokenAddress: '0x9584e368355D4634f1bdD7b0e7B08fFEa38ED0b0',
    earnContractAddress: '0x9584e368355D4634f1bdD7b0e7B08fFEa38ED0b0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarflare-luna-flare',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Solarflare',
    assets: ['LUNA', 'FLARE'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.solarflare.io/exchange/add/0xE3e43888fa7803cDC7BEA478aB327cF1A0dc11a7/0x31DAB3430f3081dfF3Ccd80F17AD98583437B213',
    buyTokenUrl:
      'https://app.solarflare.io/exchange/swap?inputCurrency=0x31DAB3430f3081dfF3Ccd80F17AD98583437B213&outputCurrency=0xE3e43888fa7803cDC7BEA478aB327cF1A0dc11a7',
    createdAt: 1646426436,
  },
  {
    id: 'solarflare-wglmr-flare',
    name: 'FLARE-GLMR LP',
    token: 'FLARE-GLMR SLP',
    tokenDescription: 'Solarflare',
    tokenAddress: '0x26A2abD79583155EA5d34443b62399879D42748A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarflareFLARE-GLMR',
    earnedTokenAddress: '0x37A8b016EF27fBCF73F73Fb9Dc1C09C47A5d7E48',
    earnContractAddress: '0x37A8b016EF27fBCF73F73Fb9Dc1C09C47A5d7E48',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarflare-wglmr-flare',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Solarflare',
    assets: ['FLARE', 'GLMR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.solarflare.io/exchange/add/0xE3e43888fa7803cDC7BEA478aB327cF1A0dc11a7/0xAcc15dC74880C9944775448304B263D191c6077F',
    buyTokenUrl:
      'https://app.solarflare.io/exchange/swap?inputCurrency=0xAcc15dC74880C9944775448304B263D191c6077F&outputCurrency=0xE3e43888fa7803cDC7BEA478aB327cF1A0dc11a7',
    createdAt: 1646421834,
  },
  {
    id: 'solarflare-usdc-wglmr',
    name: 'USDC-GLMR LP',
    token: 'USDC-GLMR SLP',
    tokenDescription: 'Solarflare',
    tokenAddress: '0xAb89eD43D10c7CE0f4D6F21616556AeCb71b9c5f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarflareUSDC-GLMR',
    earnedTokenAddress: '0xf26607237355D7c6183ea66EC908729E9c6eEB6b',
    earnContractAddress: '0xf26607237355D7c6183ea66EC908729E9c6eEB6b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarflare-usdc-wglmr',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Solarflare',
    assets: ['USDCs', 'GLMR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.solarflare.io/exchange/add/0x595c8481c48894771CE8FaDE54ac6Bf59093F9E8/0xAcc15dC74880C9944775448304B263D191c6077F',
    buyTokenUrl:
      'https://app.solarflare.io/exchange/swap?inputCurrency=0xAcc15dC74880C9944775448304B263D191c6077F&outputCurrency=0x595c8481c48894771CE8FaDE54ac6Bf59093F9E8',
    createdAt: 1646424684,
  },
  {
    id: 'beamswap-bifi-wglmr',
    name: 'BIFI-GLMR LP',
    token: 'BIFI-GLMR TLP',
    tokenDescription: 'Beamswap',
    tokenAddress: '0x321e45B7134b5Ed52129027F1743c8E71DA0A339',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeamBIFI-GLMR',
    earnedTokenAddress: '0x2Ba77eE40995d8FedD5212E4776aE5eBfFde7a47',
    earnContractAddress: '0x2Ba77eE40995d8FedD5212E4776aE5eBfFde7a47',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beamswap-bifi-wglmr',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beamswap',
    assets: ['BIFI', 'GLMR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beamswap.io/exchange/add/0x595c8481c48894771CE8FaDE54ac6Bf59093F9E8/0xacc15dc74880c9944775448304b263d191c6077f3',
    buyTokenUrl:
      'https://app.beamswap.io/exchange/swap?inputCurrency=0x595c8481c48894771CE8FaDE54ac6Bf59093F9E8&outputCurrency=0xacc15dc74880c9944775448304b263d191c6077f',
    createdAt: 1644704004,
  },
  {
    id: 'beamswap-usdc-ftm',
    name: 'FTM-USDC LP',
    token: 'FTM-USDC TLP',
    tokenDescription: 'Beamswap',
    tokenAddress: '0xA135de8E019447DA28f15eb480AEa0a242af5335',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeamFTM-USDC',
    earnedTokenAddress: '0x05CC7F3AaD0Cecbf926D2F4A534F55C19035718e',
    earnContractAddress: '0x05CC7F3AaD0Cecbf926D2F4A534F55C19035718e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beamswap-usdc-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beamswap',
    assets: ['FTM', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beamswap.io/exchange/add/0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b/0xC19281F22A075E0F10351cd5D6Ea9f0AC63d4327',
    buyTokenUrl:
      'https://app.beamswap.io/exchange/swap?inputCurrency=0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b&outputCurrency=0xC19281F22A075E0F10351cd5D6Ea9f0AC63d4327',
    createdAt: 1644703824,
  },
  {
    id: 'beamswap-usdc-wglmr',
    name: 'USDC-GLMR LP',
    token: 'USDC-GLMR TLP',
    tokenDescription: 'Beamswap',
    tokenAddress: '0xb929914B89584b4081C7966AC6287636F7EfD053',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeamUSDC-GLMR',
    earnedTokenAddress: '0xC96b496F78d1a45D9cec6d628a75614ed230D6D4',
    earnContractAddress: '0xC96b496F78d1a45D9cec6d628a75614ed230D6D4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beamswap-usdc-wglmr',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beamswap',
    assets: ['USDC', 'GLMR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beamswap.io/exchange/add/0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b/0xacc15dc74880c9944775448304b263d191c6077f3',
    buyTokenUrl:
      'https://app.beamswap.io/exchange/swap?inputCurrency=0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b&outputCurrency=0xacc15dc74880c9944775448304b263d191c6077f',
    createdAt: 1644598122,
  },
  {
    id: 'beamswap-dai-usdc',
    name: 'USDC-DAI LP',
    token: 'USDC-DAI TLP',
    tokenDescription: 'Beamswap',
    tokenAddress: '0x7EF9491774a81f6dB7Bb759Fe2F645c334dCf5b1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeamUSDC-DAI',
    earnedTokenAddress: '0x149f3dDeB5FF9bE7342D07C35D6dA19Df3F790af',
    earnContractAddress: '0x149f3dDeB5FF9bE7342D07C35D6dA19Df3F790af',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beamswap-dai-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beamswap',
    assets: ['USDC', 'DAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'OVER_COLLAT_ALGO_STABLECOIN',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beamswap.io/exchange/add/0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b/0x765277EebeCA2e31912C9946eAe1021199B39C61',
    buyTokenUrl:
      'https://app.beamswap.io/exchange/swap?inputCurrency=0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b&outputCurrency=0x765277EebeCA2e31912C9946eAe1021199B39C61',
    createdAt: 1644598824,
  },
  {
    id: 'beamswap-wglmr-glint',
    name: 'GLINT-GLMR LP',
    token: 'GLINT-GLMR TLP',
    tokenDescription: 'Beamswap',
    tokenAddress: '0x99588867e817023162F4d4829995299054a5fC57',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeamGLINT-GLMR',
    earnedTokenAddress: '0x011A0aDc7663334f73Aa6da3db9c9951d0CbB8CF',
    earnContractAddress: '0x011A0aDc7663334f73Aa6da3db9c9951d0CbB8CF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beamswap-wglmr-glint',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beamswap',
    assets: ['GLINT', 'GLMR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beamswap.io/exchange/add/0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b/0x765277EebeCA2e31912C9946eAe1021199B39C61',
    buyTokenUrl:
      'https://app.beamswap.io/exchange/swap?inputCurrency=0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b&outputCurrency=0x765277EebeCA2e31912C9946eAe1021199B39C61',
    createdAt: 1644599100,
  },
  {
    id: 'beamswap-busd-bnb',
    name: 'BUSD-BNB LP',
    token: 'BUSD-BNB TLP',
    tokenDescription: 'Beamswap',
    tokenAddress: '0x34A1F4AB3548A92C6B32cd778Eed310FcD9A340D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeamBUSD-BNB',
    earnedTokenAddress: '0x452bE05439d83D3A6A510F11a4Ba1F1909d1cA6d',
    earnContractAddress: '0x452bE05439d83D3A6A510F11a4Ba1F1909d1cA6d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beamswap-busd-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beamswap',
    assets: ['BUSD', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beamswap.io/exchange/add/0xc9BAA8cfdDe8E328787E29b4B078abf2DaDc2055/0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F',
    buyTokenUrl:
      'https://app.beamswap.io/exchange/swap?inputCurrency=0xc9BAA8cfdDe8E328787E29b4B078abf2DaDc2055&outputCurrency=0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F',
    createdAt: 1644599646,
  },
  {
    id: 'beamswap-usdc-busd',
    name: 'USDC-BUSD LP',
    token: 'USDC-BUSD TLP',
    tokenDescription: 'Beamswap',
    tokenAddress: '0xa0799832FB2b9F18Acf44B92FbbEDCfD6442DD5e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeamUSDC-BUSD',
    earnedTokenAddress: '0x3DD36E64784FDAEe7BD202c78322a0d8EB1BB32b',
    earnContractAddress: '0x3DD36E64784FDAEe7BD202c78322a0d8EB1BB32b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beamswap-usdc-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beamswap',
    assets: ['USDC', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beamswap.io/exchange/add/0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F/0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
    buyTokenUrl:
      'https://app.beamswap.io/exchange/swap?inputCurrency=0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F&outputCurrency=0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
    createdAt: 1644599298,
  },
  {
    id: 'beamswap-usdc-eth',
    name: 'USDC-ETH LP',
    token: 'USDC-ETH TLP',
    tokenDescription: 'Beamswap',
    tokenAddress: '0x6BA3071760d46040FB4dc7B627C9f68efAca3000',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeamUSDC-ETH',
    earnedTokenAddress: '0x93E0573029Bd4452d2D4BAF7725ecf8e5B0b5Dd7',
    earnContractAddress: '0x93E0573029Bd4452d2D4BAF7725ecf8e5B0b5Dd7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beamswap-usdc-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beamswap',
    assets: ['USDC', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beamswap.io/exchange/add/0xfA9343C3897324496A05fC75abeD6bAC29f8A40f/0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
    buyTokenUrl:
      'https://app.beamswap.io/exchange/swap?inputCurrency=0xfA9343C3897324496A05fC75abeD6bAC29f8A40f&outputCurrency=0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
    createdAt: 1644599832,
  },
  {
    id: 'beamswap-usdc-usdt',
    name: 'USDC-USDT LP',
    token: 'USDC-USDT TLP',
    tokenDescription: 'Beamswap',
    tokenAddress: '0xA35B2c07Cb123EA5E1B9c7530d0812e7e03eC3c1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeamUSDC-USDT',
    earnedTokenAddress: '0x830806F4d629a2aC60a93F594D86dD9684D0c65D',
    earnContractAddress: '0x830806F4d629a2aC60a93F594D86dD9684D0c65D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beamswap-usdc-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beamswap',
    assets: ['USDC', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beamswap.io/exchange/add/0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73/0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
    buyTokenUrl:
      'https://app.beamswap.io/exchange/swap?inputCurrency=0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73&outputCurrency=0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
    createdAt: 1644600000,
  },
];
